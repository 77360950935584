import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { AmplifyUser } from "types/amplify/AmplifyUser";

import NewPassword from "./screens/NewPassword";
import ResetPassword from "./screens/ResetPassword";
import SignIn from "./screens/SignIn";
import TwoFactorAuthentication from "./screens/TwoFactorAuthentication";

export default function SignUpComponent() {
  const [user, setUser] = React.useState<undefined | AmplifyUser>();
  return (
    <Routes>
      <Route path="" element={<SignIn onComplete={setUser} />} />
      <Route
        path="/sms-authentication"
        element={<TwoFactorAuthentication onComplete={setUser} user={user as AmplifyUser} />}
      />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/new-password"
        element={<NewPassword onComplete={setUser} user={user as AmplifyUser | undefined} />}
      />
    </Routes>
  );
}
