import { useQuery } from "@tanstack/react-query";
import api from "api";
import getErrorMessage from "functions/get-error-message";
import { useToast } from "hooks/use-toast";

useDownloadPayment.queryKey = "download-payment";

export default function useDownloadPayment(paymentId: string) {
  const { errorToast, successToast } = useToast();

  return useQuery([useDownloadPayment.queryKey, paymentId], async () => api.Payment.getPaymentFile(paymentId), {
    enabled: false,
    retry: false,
    onSuccess: () => {
      successToast({ message: "Successfully downloaded the file", title: "Payment Detail Download" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error), title: "Payment Download Failed" });
    },
  });
}
