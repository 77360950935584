import Button from "components/atoms/Button/Button";
import Modal from "components/molecules/Modal";
import { PERMISSION_ENUM } from "constants/permissions";
import usePermission from "hooks/context/use-permission";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import color from "styles/color";

const withPermission = (requiredPermission: PERMISSION_ENUM) => (WrappedComponent: FC<any>) => {
  const EnhancedComponent = (props: any) => {
    const hasPermission = usePermission();
    const navigate = useNavigate();

    if (!hasPermission(requiredPermission)) {
      return (
        <Modal
          title="Permission Required"
          modalBody={<p>You do not have permission to perform this action</p>}
          open={true}
          modalFooter={
            <Button varient="primary" backgroundColor={color.black} onClick={() => navigate("/dashboard")}>
              Go Back to Dashboard
            </Button>
          }
        />
      );
    }
    return <WrappedComponent {...props} />;
  };

  return EnhancedComponent;
};

export default withPermission;
