import { ReactComponent as VisibilityOffSVG } from "assets/icons/visibility-off.svg";
import { ReactComponent as VisibilityOnSVG } from "assets/icons/visibility-on.svg";
import Input from "components/atoms/Form/Input/Input";
import InputButtonIcon from "components/atoms/Form/InputButtonIcon/InputButtonIcon";
import InputNote from "components/atoms/Form/InputNote/InputNote";
import Label from "components/atoms/Form/Label/Label";
import PasswordIndicator from "components/atoms/Form/PasswordIndicator/PasswordIndicator";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";
import input from "styles/input";

interface TextInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testID?: string;
  icon?: React.ReactElement;
  note?: string;
  hasIndicator?: boolean;
  appendElement?: React.ReactElement;
}

const TextInputStyled = styled.div`
  max-width: ${input.maxWidth};
`;

PasswordInputComponent.testID = "textInput";

export default function PasswordInputComponent({
  input: { ...inputProps },
  label,
  note,
  hasIndicator,
  appendElement,
  ...props
}: TextInputProps) {
  const [isShown, setIsShown] = React.useState(false);

  const showIndicator = typeof inputProps.value === "string" && inputProps.value.length > 0 && hasIndicator === true;
  const errorMessage = props.meta.touched && props.meta.error;

  return (
    <TextInputStyled data-testid={composeTestID(PasswordInputComponent.testID, props.testID)}>
      <Label htmlFor={inputProps.name}>{label}</Label>
      <Input
        {...inputProps}
        {...props}
        appendElement={
          <InputButtonIcon onClick={() => setIsShown(!isShown)} color={color.black}>
            {isShown ? <VisibilityOnSVG /> : <VisibilityOffSVG />}
          </InputButtonIcon>
        }
        type={isShown ? "text" : "password"}
      ></Input>
      {showIndicator && !errorMessage ? (
        <PasswordIndicator value={inputProps.value} />
      ) : (
        <InputNote htmlFor={inputProps.name} color={!errorMessage ? undefined : color.red}>
          {!errorMessage ? note : errorMessage}
          {appendElement}
        </InputNote>
      )}
    </TextInputStyled>
  );
}
