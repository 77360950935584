import * as React from "react";
import styled from "styled-components";
import spacing from "styles/spacing";

interface MenuDropDownListProps {
  children: React.ReactElement | React.ReactElement[];
}

const MenuDropDownListStyled = styled.div`
  margin: ${spacing.m} 0;
`;

export default function MenuDropDownListComponent({ children }: MenuDropDownListProps) {
  return <MenuDropDownListStyled>{children}</MenuDropDownListStyled>;
}
