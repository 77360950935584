import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "hooks/use-toast";
import { AppCreateUser } from "types/App/User";

import useUsers from "./use-users";

export function useCreateUser() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation({
    mutationFn: async (user: AppCreateUser) => {
      user.DefaultCurrency = "EUR";
      return api.User.createUser(user);
    },
    onSuccess: async (message: any) => {
      await queryClient?.invalidateQueries({ queryKey: [useUsers.queryKey] });
      successToast({ message });
    },
  });
}
