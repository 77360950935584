import { Disclosure } from "@headlessui/react";
import { ReactComponent as ChevronDownSVG } from "assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUpSVG } from "assets/icons/chevron-up.svg";
import BadgeComponent, { BadgeVarients } from "components/atoms/Badge";
import Heading from "components/atoms/Heading/Heading";
import Icon from "components/atoms/Icon/Icon";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";

interface AccordionProps {
  title: string;
  children?: React.ReactElement;
  defaultOpen?: boolean;
  status?: { text: string; varient?: BadgeVarients; description?: string };
  actions?: React.ReactElement;
}

const AccordionContainer = styled.div`
  box-shadow: 0 4px 4px ${color.borderGradient};
  margin: ${spacing.s} 0;
  border-radius: 12px;
`;

const AccordionButtonStyled = styled.button<{ varient: string }>`
  position: relative;
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: ${spacing.m} 0;
  width: 100%;
  background-color: transparent;
  border: none;

  & > h1 {
    margin-left: ${spacing.m};
    flex: 1;
    text-align: left;
  }

  @media (${media.desktop}) {
    padding: ${spacing.m} ${spacing.s};

    & > h1 {
      flex-direction: row;
      display: flex;
      align-items: center;
    }
  }
`;

const AccordionHeaderStyled = styled.div`
  flex: 1;
  margin-left: ${spacing.m};
  text-align: left;
  display: flex;
  align-items: center;
  flex-flow: wrap;

  @media ${media.smallMobile} {
    & > h1 {
      margin-right: ${spacing.xs};
    }

    & > span {
      margin: 0;
    }
  }
`;

const AccordionChildrenStyled = styled(Disclosure.Panel)`
  padding-left: ${spacing.s};
  padding-right: ${spacing.s};
  padding-bottom: ${spacing.m};
  transition: all 500ms ease-in-out;

  @media ${media.desktop} {
    padding: ${spacing.s} ${spacing.l} ${spacing.m};
  }
`;

const IconStyled = styled.div`
  margin: 0 ${spacing.m};
`;

const MessageAlert = styled.p`
  font-size: 12px;
  color: ${color.orange};
`;

AccordionComponent.testID = "Accordion";

export default function AccordionComponent({ title, children, defaultOpen = false, status, actions }: AccordionProps) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <AccordionContainer>
          <Disclosure.Button as={AccordionButtonStyled}>
            <AccordionHeaderStyled>
              <Heading as="h1" varient="h4">
                {title}
              </Heading>
              {status?.text && <BadgeComponent text={status.text} varient={status.varient} />}
              {status?.description && <MessageAlert>{status.description}</MessageAlert>}
            </AccordionHeaderStyled>
            {open && actions}
            {!!children && <IconStyled as={Icon}>{!open ? <ChevronDownSVG /> : <ChevronUpSVG />}</IconStyled>}
          </Disclosure.Button>
          <AccordionChildrenStyled>{children}</AccordionChildrenStyled>
        </AccordionContainer>
      )}
    </Disclosure>
  );
}
