import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import composeTestID from "functions/compose-test-id";
import React, { ReactElement } from "react";
import styled from "styled-components";
import color from "styles/color";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

import IconStyled from "../Icon/Icon";

interface AlertProps {
  message: string;
  iconElement?: ReactElement;
  testID?: string;
  iconSize?: number;
  iconColor?: string;
}

const AlertStyled = styled.div`
  padding: ${spacing.s} ${spacing.m};
  display: flex;
  align-items: center;
  max-width: 500px;
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.default};
  line-height: 21px;
  border-radius: 3px;
  box-shadow: 5px 2px 10px ${color.grey3};

  & > div > svg {
    margin-right: ${spacing.m};
  }
`;

AlertComponent.testID = "Alert";

export default function AlertComponent({ message, iconElement, testID, iconSize, iconColor }: AlertProps) {
  return (
    <AlertStyled data-testid={composeTestID(AlertComponent.testID, testID)}>
      <IconStyled color={iconColor} size={iconSize ?? 18}>
        {iconElement ? iconElement : <WarningIcon />}
      </IconStyled>
      {message}
    </AlertStyled>
  );
}
