import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { BadgeVarients } from "components/atoms/Badge";
import Button from "components/atoms/Button/Button";
import RenderWithPermissions from "components/atoms/Permission";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import AccordionComponent from "components/organisms/Accordion";
import { PERMISSION_ENUM } from "constants/permissions";
import capitalize from "functions/capitalize";
import usePermission from "hooks/context/use-permission";
import { useIsDesktop } from "hooks/use-is-desktop";
import React, { useMemo } from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";
import { AppBankAccountListItem } from "types/App/BankAccount";
import { AppWalletListItem } from "types/App/Wallet";

import ApproveBankAccount from "../common/ApproveBankAccount";
import DeleteAccount from "../common/DeleteAccount";
import RejectBankAccount from "../common/RejectBankAccount";
import { BANK_ACCOUNT_SCHEMA } from "../common/schemaValidation";

const STATUSES: { [x: string]: string } = {
  verified: "success",
  pending_add: "info",
  pending_delete: "info",
};

const STATUSES_TEXT: { [x: string]: string } = {
  pending_add: "Pending to Add",
  pending_delete: "Pending to Delete",
};

interface AccountDetailProps extends AppBankAccountListItem {
  varient?: string;
  onRefresh: () => void;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing.m} 0;

  @media (${media.desktop}) {
    justify-content: flex-start;

    & button:first-child {
      margin-right: ${spacing.xs};
      margin-bottom: 0;
    }
  }
`;

interface AccountDetail {
  name: string;
  label: string;
  placeholder: string;
}

const AccountFields: AccountDetail[] = [
  {
    name: BANK_ACCOUNT_SCHEMA.IBAN,
    label: "IBAN",
    placeholder: "NL 00 RABO 0123 4567 89",
  },
  {
    name: BANK_ACCOUNT_SCHEMA.ACCOUNT_NUMBER,
    label: "Account Number",
    placeholder: "12345678",
  },
  {
    name: BANK_ACCOUNT_SCHEMA.BANK_CODE,
    label: "Bank Code",
    placeholder: "AAAA",
  },
  {
    name: BANK_ACCOUNT_SCHEMA.SWIFT,
    label: "SWIFT/BIC",
    placeholder: "XXX X XXX XX",
  },
  {
    name: BANK_ACCOUNT_SCHEMA.ROUTING_NUMBER,
    label: "Routing Number",
    placeholder: "0023456789",
  },
  {
    name: BANK_ACCOUNT_SCHEMA.CURRENCY,
    label: "Currency",
    placeholder: "EUR",
  },
];

export default function BankAccountDetail({ onRefresh, Id, Name, Status, ...accountDetails }: AccountDetailProps) {
  const navigate = useNavigate();
  const { statusVarient, statusText } = useMemo(
    () => ({
      statusVarient: STATUSES[`${Status?.toLowerCase()}`],
      statusText: capitalize(STATUSES_TEXT[`${Status?.toLowerCase()}`] ?? Status),
    }),
    [Status]
  );
  const isDesktop = useIsDesktop();
  const hasPermission = usePermission();

  const handleSubmit = (data: AppWalletListItem) => data;

  const navigateToEdit = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    navigate(`./${Id}/edit`);
  };

  return (
    <AccordionComponent
      title={Name}
      status={{ text: statusText, varient: statusVarient as BadgeVarients }}
      actions={
        <>
          {statusVarient === "success" && isDesktop && (
            <>
              <RenderWithPermissions permission={PERMISSION_ENUM.DELETE_BANK_ACCOUNTS}>
                <Button
                  icon={<EditIcon />}
                  iconSize={20}
                  color={color.blue}
                  varient="secondary"
                  marginHorizontalSize="small"
                  onClick={navigateToEdit}
                >
                  Edit
                </Button>
              </RenderWithPermissions>
              <DeleteAccount title={Name} id={Id} status={Status} />
            </>
          )}
        </>
      }
    >
      <>
        <Form onSubmit={handleSubmit} initialValues={{ ...accountDetails, Id, Name }}>
          {() => {
            return (
              <>
                {AccountFields.map(({ name, label, placeholder }) => (
                  <Field
                    key={name}
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    component={TextInput}
                    readOnly
                    compact
                  />
                ))}
              </>
            );
          }}
        </Form>
        <ButtonContainer>
          {hasPermission(PERMISSION_ENUM.DELETE_BANK_ACCOUNTS) &&
            ((!isDesktop && statusVarient === "verified") || Status === "pending_delete") && (
              <DeleteAccount title={Name} id={Id} status={Status} />
            )}
          {Status === "pending_add" && <ApproveBankAccount accountId={Id} title={Name} />}
          {Status.toLowerCase() !== "verified" && <RejectBankAccount accountId={Id} />}
        </ButtonContainer>
      </>
    </AccordionComponent>
  );
}
