import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import HeaderBackNav from "components/molecules/HeaderBackNav";
import BottomNavigation from "components/organisms/BottomBar/BottomBar";
import { PERMISSION_ENUM } from "constants/permissions";
import { FORM_ERROR } from "final-form";
import getErrorMessage from "functions/get-error-message";
import { validateFormValues } from "functions/validate-schema";
import withPermission from "hocs/permission";
import { useCreateAccount } from "hooks/query/bank-account/use-create-bank-account";
import { useIsMobile } from "hooks/use-is-mobile";
import { useToast } from "hooks/use-toast";
import React from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import spacing from "styles/spacing";
import { AppCreateBankAccount } from "types/App/BankAccount";

import AccountFields from "../common/AccountFields";
import { BankAccountSchemaValidation } from "../common/schemaValidation";
import SubmitButton from "../common/SubmitButton";

function AddBankAccount() {
  const navigation = useNavigate();
  const isMobile = useIsMobile();
  const { successToast } = useToast();

  const { mutateAsync: mutateAsyncCreateAccount } = useCreateAccount();

  const handleOnSubmit = async (data: AppCreateBankAccount) => {
    try {
      await mutateAsyncCreateAccount(data);
      navigation("..");
      successToast({ message: "Bank account has been created successfully" });
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  return (
    <>
      <HeaderBackNav title="New Bank Account" onBack={() => navigation("..")} />

      <Form
        onSubmit={handleOnSubmit}
        validate={validateFormValues(BankAccountSchemaValidation)}
        keepDirtyOnReinitialize
      >
        {({ submitting, submitError, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <AccountFields />
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
              <BottomNavigation
                paddingHorizontal={isMobile ? "0" : spacing.l}
                rightElement={
                  !isMobile ? <SubmitButton buttonText="Add Bank Account" loading={submitting} /> : undefined
                }
                leftElement={isMobile ? <SubmitButton buttonText="Add Bank Account" loading={submitting} /> : undefined}
              />
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default withPermission(PERMISSION_ENUM.ADD_BANK_ACCOUNTS)(AddBankAccount);
