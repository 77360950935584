import Spacer from "components/atoms/Spacer/Spacer";
import Switch from "components/atoms/Switch";
import { useIsMobile } from "hooks/use-is-mobile";
import DashboardContainer from "pages/components/DashboardContainer";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

import BuyWizard from "./components/BuyWizard";
import SellWizard from "./components/SellWizard";

const BuySellContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const NavigationContainerStyled = styled.div<{ justifyContent: "flex-end" | "space-between" }>`
  display: flex;
  width: 100%;
  justify-content: ${({ justifyContent }) => justifyContent};

  @media ${({ theme: { media } }) => media.mobile} {
    padding: 0;
  }
  @media ${({ theme: { media } }) => media.desktop} {
    padding: ${spacing.m} ${spacing.l};
  }
`;

const NavigationStepContainerStyled = styled.div`
  position: relative;
  flex: 1;
  max-width: 700px;

  @media ${media.desktop} {
    margin-right: ${spacing.l};
  }
`;
export default function BuySellComponent({ children }: { children: React.ReactElement }) {
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const normalisedPath = pathname.toLowerCase();
  const location = normalisedPath.startsWith("/buy") ? "buy" : "sell";
  const hasId = /\/(buy|sell)\/.{1,}/.test(normalisedPath);

  const handleOnLocationChange = (newValue: string) => {
    navigate(`/${newValue}`);
  };
  return (
    <BuySellContainerStyled as={DashboardContainer}>
      <NavigationContainerStyled justifyContent={hasId ? "flex-end" : "space-between"}>
        {isMobile || hasId ? (
          <></>
        ) : (
          <>
            <Switch
              options={[
                { id: "buy", label: "Buy" },
                { id: "sell", label: "Sell" },
              ]}
              value={location}
              onChange={handleOnLocationChange}
            ></Switch>
            <Spacer width={spacing.xl} />
          </>
        )}
        <NavigationStepContainerStyled>
          {location === "buy" ? <BuyWizard /> : <SellWizard />}
        </NavigationStepContainerStyled>
      </NavigationContainerStyled>
      {children}
    </BuySellContainerStyled>
  );
}
