import { ReactComponent as EmailIcon } from "assets/icons/alternate-email.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/people.svg";
import { ReactComponent as UserIcon } from "assets/icons/person.svg";
import PhoneNumberInput from "components/molecules/Form/PhoneNumberInput/PhoneNumberInput";
import RoleMultiSelect from "components/molecules/Form/RoleMultiSelect";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import React from "react";
import { Field } from "react-final-form";

import { USER_SCHEMA } from "./schemaValidation";

interface UserFormProps {
  disabled?: boolean;
}

export default function UserInfoForm({ disabled = false }: UserFormProps) {
  return (
    <>
      <Field
        name={USER_SCHEMA.NAME}
        label="Name"
        placeholder="Name"
        component={TextInput}
        disabled={disabled}
        icon={<UserIcon />}
        compact
      />
      <Field name={USER_SCHEMA.PHONE} disabled={disabled} label="Mobile Number" component={PhoneNumberInput} compact />
      <Field
        name={USER_SCHEMA.COMPANY_EMAIL}
        label="Company Email Address"
        disabled
        placeholder="email@stablr.com"
        component={TextInput}
        icon={<EmailIcon />}
        compact
      />
      <Field
        placeholder="Role"
        label="Role"
        iconElement={<PeopleIcon />}
        name={USER_SCHEMA.ROLE}
        component={RoleMultiSelect}
        disabled={disabled}
      />
    </>
  );
}
