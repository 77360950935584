import { Menu } from "@headlessui/react";
import Button from "components/atoms/Button/Button";
import { PERMISSION_ENUM } from "constants/permissions";
import usePermission from "hooks/context/use-permission";
import * as React from "react";
import { NavLink } from "react-router-dom";

interface MenuDropDownFeatureLinkProps {
  children: string;
  to: string;
  icon: React.ReactElement;
  permission?: PERMISSION_ENUM;
}

export default function MenuDropDownFeatureLinkComponent({
  children,
  to,
  icon,
  permission,
}: MenuDropDownFeatureLinkProps) {
  const hasPermission = usePermission();

  if (permission && !hasPermission(permission)) return <></>;

  return (
    <li>
      <Menu.Button as={React.Fragment}>
        <Button as={NavLink} to={to} size="small" marginHorizontalSize="large" rounded icon={icon} varient="gradient">
          {children}
        </Button>
      </Menu.Button>
    </li>
  );
}
