import CardError from "components/molecules/Card/CardError";
import CardLoader from "components/molecules/Card/CardLoader";
import useTransaction from "hooks/query/transaction/use-transaction";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import PaymentInstruction from "./PaymentInstruction";
import SuccessScreen from "./SuccessScreen";

export default function TransactionScreenComponent() {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return <></>;

  const { data: transaction, isLoading, error, refetch } = useTransaction(id ?? "");

  const handleOnBack = async () => {
    navigate("/buy");
  };

  return isLoading ? (
    <CardLoader />
  ) : error || (transaction === undefined && !isLoading) ? (
    <CardError title="Error" body="Error fetching Buy order" onRetry={refetch} />
  ) : transaction.Status === "PENDING" ? (
    <PaymentInstruction
      title="2. Transfer of Payment"
      body="Please follow these steps to buy your StablR assets"
      transaction={transaction}
      onBack={handleOnBack}
    />
  ) : transaction.Status === "CONFIRMED" ? (
    <SuccessScreen
      transaction={transaction}
      title="3. Transaction Successful"
      body="Your transaction has successfully been completed"
    />
  ) : (
    <CardError title="Error" body={`Transaction status "${transaction.Status}" unkown`} onRetry={refetch} />
  );
}
