import BankAccount from "./BankAccount";
import Company from "./Company";
import Dashboard from "./Dashboard";
import Mock from "./Mock";
import Payment from "./Payment";
import Role from "./Roles";
import Synaps from "./Synaps";
import Transaction from "./Transaction";
import User from "./User";
import Wallet from "./Wallet";

export default {
  User,
  Role,
  Company,
  Wallet,
  BankAccount,
  Synaps,
  Payment,
  Mock,
  Dashboard,
  Transaction,
};
