import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import spacing from "styles/spacing";

interface InputTypeProps {
  children: string;
}

const BORDER_MARGIN = spacing.xs;

const InputTypeStyled = styled.div`
  min-width: 100px;
  border: solid ${color.greyDark1} 1px;
  border-radius: 3px;
  height: 100%;
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${color.black};
  padding: ${spacing.m} ${spacing.m} ${spacing.m} ${spacing.m};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  position: relative;

  &::after {
    content: "";
    height: calc(100% - ${BORDER_MARGIN} * 2);
    box-sizing: border-box;
    margin: ${BORDER_MARGIN} 0;
    width: 0;
    border-left: solid ${color.greyDark1} 1px;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export default function InputTypeComponent({ children }: InputTypeProps) {
  return <InputTypeStyled>{children}</InputTypeStyled>;
}
