import Badge from "components/atoms/Badge";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Spacer from "components/atoms/Spacer/Spacer";
import TableGroup from "components/molecules/Table/TableGroup";
import * as React from "react";
import styled from "styled-components";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";
import { AppTransaction } from "types/App/Transaction";

import PaymentDetailsTable from "./components/PaymentDetailsTable";
import YourBankAccountTable from "./components/YourBankAccountTable";

interface PayProcessingScreenProps {
  title: string;
  body: string;
  transaction: AppTransaction;
}

const PayProcessingScreenStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  & > h2 {
    font-weight: ${fontWeight.medium};
    margin-bottom: ${spacing.xs};
  }

  & > h3 {
    font-weight: ${fontWeight.regular};
    margin-bottom: ${spacing.xs};
  }

  & > p {
    max-width: 700px;
  }
`;

export default function PayProcessingScreenComponent({ title, body, transaction }: PayProcessingScreenProps) {
  return (
    <PayProcessingScreenStyled>
      <Heading as="h2" varient="h3">
        <>
          {title}
          <Badge varient="action" text="Processing"></Badge>
        </>
      </Heading>
      <Paragraph>{body}</Paragraph>
      <Spacer height={spacing.s} />
      <TableGroup label="Your Bank Account">
        <YourBankAccountTable transaction={transaction} />
      </TableGroup>
      <Spacer height={spacing.m} />
      <TableGroup label="Payment Details">
        <PaymentDetailsTable transaction={transaction} />
      </TableGroup>
      <Spacer height={spacing.l} />
      <Heading as="h3" varient="h4">
        When do you receive your funds?
      </Heading>
      <Paragraph>
        After receiving your EURR, we will be able to start the redemption process and wire the funds to your bank
        account.
      </Paragraph>
    </PayProcessingScreenStyled>
  );
}
