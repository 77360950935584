import CardError from "components/molecules/Card/CardError";
import CardLoader from "components/molecules/Card/CardLoader";
import getErrorMessage from "functions/get-error-message";
import useTransactions from "hooks/query/transaction/use-transactions";
import DashboardContainer from "pages/components/DashboardContainer";
import React from "react";

import TransactionEmpty from "./TransactionEmpty";
import TransactionTable from "./TransactionTable";

export default function TransactionOverview() {
  const { data: transactions = [], error, isLoading, refetch } = useTransactions();

  return (
    <DashboardContainer>
      {error ? (
        <CardError body={getErrorMessage(error)} title="Can not load Transaction(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !transactions?.length ? (
        <TransactionEmpty />
      ) : (
        <TransactionTable data={transactions} />
      )}
    </DashboardContainer>
  );
}
