import capitalize from "functions/capitalize";

export default function parseTransactionBadgeText(text?: string): string {
  if (text === undefined) return "";
  if (text === "REJECTED") return "FAILED";
  switch (text.toUpperCase()) {
    case "INITIALIZED": {
      return "INITIALIZED";
    }
    case "SUBMITTED": {
      return "SUBMITTED";
    }
    case "VALIDATED": {
      return "VALIDATED";
    }
    default: {
      return capitalize(text ?? "");
    }
  }
}
