import { ReactComponent as CopyIcon } from "assets/icons/content-copy.svg";
import Input from "components/atoms/Form/Input/Input";
import InputButtonIcon from "components/atoms/Form/InputButtonIcon/InputButtonIcon";
import InputNote from "components/atoms/Form/InputNote/InputNote";
import Label from "components/atoms/Form/Label/Label";
import copy from "copy-to-clipboard";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";
import input from "styles/input";

interface CopyInputTextProps extends FieldRenderProps<string, HTMLInputElement> {
  testID?: string;
  icon?: React.ReactElement;
  note?: string;
}

const TextInputStyled = styled.div`
  width: 100%;
  max-width: ${input.maxWidth};
`;

CopyInputTextComponent.testID = "CopyInputText";

export default function CopyInputTextComponent({
  input: { ...inputProps },
  label,
  note,
  appendElement,
  meta: { error, touched },
  ...props
}: CopyInputTextProps) {
  const copyText = () => copy(inputProps.value);

  return (
    <TextInputStyled data-testid={composeTestID(CopyInputTextComponent.testID, props.testID)}>
      <Label htmlFor={inputProps.name}>{label}</Label>
      <Input
        {...inputProps}
        {...props}
        appendElement={
          <InputButtonIcon onClick={copyText}>
            <CopyIcon />
          </InputButtonIcon>
        }
      ></Input>
      {touched && error && (
        <InputNote htmlFor={inputProps.name} color={error ? color.red : undefined}>
          {error}
        </InputNote>
      )}
    </TextInputStyled>
  );
}
