import { ReactComponent as ClearAllIcon } from "assets/icons/clear-all.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as LogoIconSVG } from "assets/icons/logo-stablr-half.svg";
import Logo from "assets/svg/logo-mark-purple.svg";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import CardEmpty from "components/molecules/Card/CardEmpty";
import { PERMISSION_ENUM } from "constants/permissions";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import usePermission from "hooks/context/use-permission";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";

const BannerStyled = styled.div`
  background-color: ${color.lightPurple1};
  padding: ${spacing.m};
  margin: ${spacing.s};
  border-radius: 15px;

  & > button:first-child {
    float: right;
  }

  & > h3 {
    line-height: 1.5;
  }

  @media ${media.desktop} {
    padding: ${spacing.l};
    margin: ${spacing.m};
    background-image: url(${Logo});
    background-repeat: no-repeat;
    background-position-x: 85%;
    background-position-y: center;
  }
`;

export default function TransactionEmptyComponent() {
  const hasPermission = usePermission();
  const [showBanner, setShowBanner] = useState(hasPermission(PERMISSION_ENUM.BUY_EURR));
  const navigation = useNavigate();

  return (
    <>
      {showBanner ? (
        <BannerStyled>
          <Button
            iconSize={24}
            icon={<CloseIcon />}
            layout="icon-only"
            varient="secondary"
            backgroundColor={color.transparent}
            onClick={() => setShowBanner(false)}
          />
          <Heading as="h3">Start with your first transaction</Heading>
          <Paragraph>
            There are no inbounding transactions found at the moment, let’s start with your first one!
          </Paragraph>
          <Button
            icon={<LogoIconSVG />}
            iconSize={20}
            backgroundColor={color.purple}
            color={color.white}
            size="small"
            rounded
            onClick={() => {
              logEvent(EVENT_TYPE_ENUM.CLICK, "BuyCTA", { location: "TransactionOverview" });
              navigation("/buy");
            }}
          >
            Buy
          </Button>
        </BannerStyled>
      ) : (
        <CardEmpty
          iconElement={<ClearAllIcon />}
          title="No transactions found"
          body="There are no transactions found at the moment"
        />
      )}
    </>
  );
}
