export enum PERMISSION_ENUM {
  MENU_ACTION_CTA = "MENU_ACTION_CTA",
  BUY_EURR = "BUY_EURR",
  SELL_EURR = "SELL_EURR",
  VIEW_DASHBOARD = "VIEW_DASHBOARD",
  VIEW_TRANSACTIONS = "VIEW_TRANSACTIONS",
  VIEW_ACCOUNT = "VIEW_ACCOUNT",
  VIEW_USERS = "VIEW_USERS",
  ADD_USERS = "ADD_USERS",
  UPDATE_USERS = "UPDATE_USERS",
  DELETE_USERS = "DELETE_USERS",
  APPROVE_USERS = "APPROVE_USERS",
  REJECT_USERS = "REJECT_USERS",
  VIEW_WALLETS = "VIEW_WALLETS",
  ADD_WALLETS = "ADD_WALLETS",
  UPDATE_WALLETS = "UPDATE_WALLETS",
  DELETE_WALLETS = "DELETE_WALLETS",
  APPROVE_WALLETS = "APPROVE_WALLETS",
  REJECT_WALLETS = "REJECT_WALLETS",
  VIEW_BANK_ACCOUNTS = "VIEW_BANK_ACCOUNTS",
  ADD_BANK_ACCOUNTS = "ADD_BANK_ACCOUNTS",
  UPDATE_BANK_ACCOUNTS = "UPDATE_BANK_ACCOUNTS",
  DELETE_BANK_ACCOUNTS = "DELETE_BANK_ACCOUNTS",
  APPROVE_BANK_ACCOUNTS = "APPROVE_BANK_ACCOUNTS",
  REJECT_BANK_ACCOUNTS = "REJECT_BANK_ACCOUNTS",
  VIEW_PAYMENT_INSTRUCTIONS = "VIEW_PAYMENT_INSTRUCTIONS",
  DOWNLOAD_PAYMENT_INSTRUCTIONS = "DOWNLOAD_PAYMENT_INSTRUCTIONS",
}
