import { Combobox as HeadlessComboBox } from "@headlessui/react";
import { ReactComponent as CheckSVG } from "assets/icons/check.svg";
import { ReactComponent as KeyboardArrowDownSVG } from "assets/icons/keyboard-arrow-down.svg";
import Icon from "components/atoms/Icon/Icon";
import Loader from "components/atoms/Loader/Loader";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import spacing from "styles/spacing";

export interface ComboBoxComplexOption {
  id: string;
  value: string;
  label: string;
}

interface ComboBoxProps extends Omit<React.InputHTMLAttributes<HTMLSelectElement>, "onChange"> {
  value: string;
  options: (string | ComboBoxComplexOption)[];
  onChange?: (value: string) => void;
  placeholder?: string;
  icon?: React.ReactElement;
  name?: string;
  loading?: boolean;
}

const INPUT_ICON_SIZE = 20;

const HeadlessComboBoxStyled = styled.div`
  border: solid ${color.greyDark1} 1px;
  border-radius: 3px;
  width: 100%;
  display: flex;
  position: relative;

  &.disabled {
    background-color: ${color.grey1};

    & button {
      background-color: ${color.grey1};
      cursor: default;
    }
  }
`;

const HeadlessComboBoxInputStyled = styled.input`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${color.black};
  padding: ${spacing.m} 0 ${spacing.m} ${spacing.s};
  width: 100%;
  border: none;

  ::placeholder {
    color: ${color.greyDark1};
  }
`;

const HeadlessComboBoxButtonStyled = styled.button`
  border: none;
  background-color: ${color.white};
  padding: 0 ${spacing.m} 0 ${spacing.xs};
  cursor: pointer;
`;

const HeadlessComboBoxOptionsStyled = styled.ul`
  width: 100%;
  top: calc(100% + ${spacing.xs});
  left: 0;
  position: absolute;
  min-height: 10px;
  border-radius: 3px;
  background-color: ${color.white};
  border: solid ${color.greyDark1} 1px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 300px;
  z-index: 100;
`;

const HeadlessComboBoxOptionStyled = styled.li(
  ({ $active }: { $active: boolean }) => `
  width: 100%;
  background-color: ${color.white};
  padding: ${spacing.s} ${spacing.m} ${spacing.s} ${spacing.l};
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${color.black};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${$active === true ? `background-color: ${color.primary}; color:${color.white};` : ""}
  & > *:first-child {
    position: absolute;
    left: ${spacing.s};
  }
`
);

const IconStyled = styled.div`
  padding: 0 0 0 ${spacing.m};
  display: flex;
  align-items: center;
`;

ComboBoxComponent.testID = "ComboBox";

export default function ComboBoxComponent({
  icon,
  value,
  options,
  onChange,
  placeholder,
  name,
  disabled,
  loading = false,
}: ComboBoxProps) {
  const [query, setQuery] = React.useState("");

  const filteredOptions = React.useMemo(
    () =>
      query === ""
        ? options
        : options.filter((option: string | ComboBoxComplexOption) => {
            return typeof option === "string"
              ? option.toLowerCase().includes(query.toLowerCase())
              : option.label.toLowerCase().includes(query.toLowerCase());
          }),
    [query, options]
  );

  const handleOnChange = (value: string | ComboBoxComplexOption) => {
    onChange && onChange(typeof value === "string" ? value : value?.id);
  };

  React.useEffect(() => {
    if (!query?.trim()) handleOnChange("");
    else {
      const selectedOption = options.find((option: string | ComboBoxComplexOption) => {
        return typeof option === "string"
          ? option.toLowerCase() == query.toLowerCase()
          : option.label.toLowerCase() === query.toLowerCase();
      });
      if (selectedOption) handleOnChange(selectedOption);
    }
  }, [query]);

  React.useEffect(() => {
    if (value) handleOnChange(value);
  }, [value]);

  return (
    <HeadlessComboBoxStyled className={disabled === true ? "disabled" : ""}>
      <HeadlessComboBox disabled={disabled} value={value} onChange={handleOnChange} name={name}>
        {icon && (
          <IconStyled size={INPUT_ICON_SIZE} as={Icon}>
            {icon}
          </IconStyled>
        )}
        <HeadlessComboBox.Input
          as={HeadlessComboBoxInputStyled}
          autoComplete="off"
          placeholder={placeholder}
          disabled={disabled}
          onChange={event => setQuery(event.target.value)}
        />

        {!disabled && !loading ? (
          <HeadlessComboBox.Button as={HeadlessComboBoxButtonStyled}>
            <Icon>
              <KeyboardArrowDownSVG />
            </Icon>
          </HeadlessComboBox.Button>
        ) : loading === true ? (
          <HeadlessComboBoxButtonStyled disabled>
            <Loader size={"27px"} varient="default" />
          </HeadlessComboBoxButtonStyled>
        ) : (
          <></>
        )}

        <HeadlessComboBox.Options as={HeadlessComboBoxOptionsStyled}>
          {filteredOptions.map((option: string | ComboBoxComplexOption) => {
            const key = typeof option === "string" ? option : option.id;
            const label = typeof option === "string" ? option : option.label;
            return (
              <HeadlessComboBox.Option as={React.Fragment} key={key} value={option}>
                {({ selected, active }) => (
                  <HeadlessComboBoxOptionStyled $active={active}>
                    <Icon color={active ? color.white : color.black}>
                      {(selected || value === label) && <CheckSVG />}
                    </Icon>

                    {label}
                  </HeadlessComboBoxOptionStyled>
                )}
              </HeadlessComboBox.Option>
            );
          })}
        </HeadlessComboBox.Options>
      </HeadlessComboBox>
    </HeadlessComboBoxStyled>
  );
}
