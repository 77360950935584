import Alert from "components/atoms/Alert";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import HeaderBackNav from "components/molecules/HeaderBackNav";
import BottomNavigation from "components/organisms/BottomBar/BottomBar";
import { FORM_ERROR } from "final-form";
import getErrorMessage from "functions/get-error-message";
import { validateFormValues } from "functions/validate-schema";
import { useCreateWallet } from "hooks/query/wallet/use-create-wallet";
import { useIsMobile } from "hooks/use-is-mobile";
import React from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import color from "styles/color";
import { AppWallet } from "types/App/Wallet";

import { WalletSchemaValidation } from "../common/schemaValidation";
import SubmitButton from "../common/SubmitButton";
import WalletFields from "../common/WalletFields";

export default function AddWallet() {
  const navigation = useNavigate();
  const isMobile = useIsMobile();

  const { mutateAsync: mutateAyncPostWallet, isLoading } = useCreateWallet();

  const handleOnSubmit = async (data: AppWallet) => {
    try {
      await mutateAyncPostWallet(data);
      navigation("..");
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  const AlertBox = (
    <Alert
      iconColor={color.yellow}
      message="Only add Wallet Addresses that support ERC-20 tokens, otherwise tokens will be lost."
    />
  );

  return (
    <>
      <HeaderBackNav title="Add Wallet" onBack={() => navigation("..")} />

      <Form onSubmit={handleOnSubmit} validate={validateFormValues(WalletSchemaValidation)} keepDirtyOnReinitialize>
        {({ values, valid, submitError, handleSubmit, submitting }) => {
          const isDisabled = submitting || isLoading;
          return (
            <form onSubmit={handleSubmit}>
              <>{isMobile && AlertBox}</>
              <WalletFields walletType={values.WalletType} disabled={isDisabled} />
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
              <BottomNavigation
                paddingHorizontal="0"
                rightElement={
                  !isMobile ? <SubmitButton buttonText="Add Wallet" disabled={!valid || isLoading} /> : undefined
                }
                leftElement={
                  isMobile ? <SubmitButton buttonText="Add Wallet" disabled={!valid || isLoading} /> : AlertBox
                }
              />
            </form>
          );
        }}
      </Form>
    </>
  );
}
