import { ReactComponent as EmailIcon } from "assets/icons/alternate-email.svg";
import { ReactComponent as LockIconSVG } from "assets/icons/lock.svg";
import { Auth } from "aws-amplify";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import FeatureIcon from "components/molecules/FeatureIcon/FeatureIcon";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import { FORM_ERROR } from "final-form";
import composeTestID from "functions/compose-test-id";
import getErrorMessage from "functions/get-error-message";
import * as React from "react";
import { Field, Form } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";
import HalfHalfResponsive from "templates/HalfHalfResponsive/HalfHalfResponsive";

interface ResetPasswordProps {
  testID?: string;
}

interface ResetPasswordFormValues {
  email: string;
  password: string;
}

const ResetPasswordStyled = styled.main`
  width: 100%;
  min-height: 100%;
`;

ResetPasswordComponent.testID = "ResetPassword";

export default function ResetPasswordComponent({ ...props }: ResetPasswordProps) {
  const [sendSuccess, setSendSuccess] = React.useState(false);

  const handleOnSubmit = async (values: ResetPasswordFormValues) => {
    setSendSuccess(false);
    try {
      await Auth.forgotPassword(values.email);
      setSendSuccess(true);
    } catch (err) {
      return { [FORM_ERROR]: getErrorMessage(err) };
    }
  };

  return (
    <ResetPasswordStyled data-testid={composeTestID(ResetPasswordComponent.testID, props.testID)}>
      <HalfHalfResponsive>
        <Form onSubmit={handleOnSubmit}>
          {({ handleSubmit, submitError, values }) => (
            <>
              <FeatureIcon icon={<LockIconSVG />} />
              <Heading as="h1" varient="h2">
                Reset Password
              </Heading>
              <Paragraph>
                Enter the email associated with your account and we’ll send an email with instructions to reset your
                password.
              </Paragraph>
              <Field
                name="email"
                label="Email Address"
                icon={<EmailIcon />}
                component={TextInput}
                placeholder="email@stablr.com"
              ></Field>
              <Button width="100%" onClick={handleSubmit}>
                Send Instructions
              </Button>
              {submitError ? <ErrorMessage>{submitError}</ErrorMessage> : <></>}
              {sendSuccess ? (
                <Paragraph color={color.primary}>
                  Reset link sent to <b>{values.email}</b>.
                </Paragraph>
              ) : (
                <></>
              )}
            </>
          )}
        </Form>
      </HalfHalfResponsive>
    </ResetPasswordStyled>
  );
}
