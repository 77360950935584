import React from "react";
import { Route, Routes } from "react-router-dom";

import PaymentsView from "./PaymentsView";

export default function PaymentInstructions() {
  return (
    <Routes>
      <Route path="*" element={<PaymentsView />} />
    </Routes>
  );
}
