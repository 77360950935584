import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import spacing from "styles/spacing";

interface InputAppendTypeProps {
  children?: string;
  color?: string;
}

const InputAppendTypeStyled = styled.div`
  min-width: 70px;
  text-align: left;
  border-radius: 3px;
  height: 100%;
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${({ $color }: { $color?: string }) => $color ?? color.grey4};
  padding: ${spacing.m} ${spacing.m} ${spacing.m} 0;
`;

export default function InputAppendTypeComponent({ children, color }: InputAppendTypeProps) {
  return <InputAppendTypeStyled $color={color}>{children}</InputAppendTypeStyled>;
}
