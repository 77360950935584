import { ReactComponent as AddIcon } from "assets/icons/add-circle-outline.svg";
import Button from "components/atoms/Button/Button";
import Modal from "components/organisms/Modal";
import { useApproveWallet } from "hooks/query/wallet/use-approve-wallet";
import React, { memo, useEffect, useState } from "react";
import color from "styles/color";

function ApproveWallet({ title, id }: { id: string; title: string }) {
  const [open, setOpen] = useState<boolean>(false);

  const { mutate: mutateDeleteWallet, isLoading, isSuccess } = useApproveWallet();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const onApprove = () => mutateDeleteWallet({ walletId: id, title });

  useEffect(() => {
    isSuccess && onCloseModal();
  }, [isSuccess]);

  return (
    <>
      <Button icon={<AddIcon />} iconSize={20} onClick={openModal} color={color.white}>
        Add
      </Button>
      <Modal
        open={open}
        onClose={onCloseModal}
        title={`Add Wallet ${title}`}
        btnText="Confirm"
        description={`Are you sure you want to add ${title} from Whitelist?`}
        onConfirm={onApprove}
        btnColor={color.white}
        loading={isLoading}
      />
    </>
  );
}

export default memo(ApproveWallet);
