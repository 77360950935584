import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface ErrorMessageProps {
  testID?: string;
  children?: string;
}

const ErrorMessageStyled = styled.span`
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.regular};
  color: ${color.red};
  margin: ${spacing.s} 0;
  display: block;
  text-align: center;
`;

ErrorMessageComponent.testID = "ErrorMessage";

export default function ErrorMessageComponent({ children, ...props }: ErrorMessageProps) {
  return (
    <ErrorMessageStyled data-testid={composeTestID(ErrorMessageComponent.testID, props.testID)}>
      {children}
    </ErrorMessageStyled>
  );
}
