import { PERMISSION_ENUM } from "constants/permissions";
import usePermission from "hooks/context/use-permission";
import React from "react";

export default function RenderWithPermissions({
  permission,
  children,
}: {
  permission?: PERMISSION_ENUM;
  children: any;
}) {
  const hasPermission = usePermission();

  return !permission?.trim() || hasPermission(permission) ? children : <></>;
}
