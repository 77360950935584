import Constants from "constants/index";
import { PERMISSION_ENUM } from "constants/permissions";
import { useCallback } from "react";
import CognitoGroup from "types/amplify/CognitoGroup";

import useUserData from "./use-user-data";

const ADMIN = "Admin";
const TM = "TM";
const TRADER = "Trader";

const DEFAULT_PERMISSIONS: { [x: string]: CognitoGroup[] } = {
  [PERMISSION_ENUM.BUY_EURR]: [TM, TRADER],
  [PERMISSION_ENUM.SELL_EURR]: [TM, TRADER],
  [PERMISSION_ENUM.VIEW_DASHBOARD]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.VIEW_TRANSACTIONS]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.VIEW_ACCOUNT]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.VIEW_USERS]: [ADMIN],
  [PERMISSION_ENUM.UPDATE_USERS]: [ADMIN],
  [PERMISSION_ENUM.DELETE_USERS]: [ADMIN],
  [PERMISSION_ENUM.ADD_USERS]: [ADMIN],
  [PERMISSION_ENUM.VIEW_WALLETS]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.ADD_WALLETS]: [TM],
  [PERMISSION_ENUM.DELETE_WALLETS]: [TM],
  [PERMISSION_ENUM.UPDATE_WALLETS]: [TM],
  [PERMISSION_ENUM.VIEW_BANK_ACCOUNTS]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.ADD_BANK_ACCOUNTS]: [TM],
  [PERMISSION_ENUM.DELETE_BANK_ACCOUNTS]: [TM],
  [PERMISSION_ENUM.UPDATE_BANK_ACCOUNTS]: [TM],
  [PERMISSION_ENUM.VIEW_PAYMENT_INSTRUCTIONS]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.DOWNLOAD_PAYMENT_INSTRUCTIONS]: [TM, TRADER],
};

const DISABLE_BUY_SELL_PERMISSIONS: { [x: string]: CognitoGroup[] } = {
  [PERMISSION_ENUM.BUY_EURR]: [],
  [PERMISSION_ENUM.SELL_EURR]: [],
  [PERMISSION_ENUM.VIEW_PAYMENT_INSTRUCTIONS]: [],
  [PERMISSION_ENUM.DOWNLOAD_PAYMENT_INSTRUCTIONS]: [],
};

const PERMISSIONS = Constants.disableBuySell
  ? { ...DEFAULT_PERMISSIONS, ...DISABLE_BUY_SELL_PERMISSIONS }
  : DEFAULT_PERMISSIONS;

export default function usePermission() {
  const user = useUserData();

  const hasPermission = useCallback(
    (permission: PERMISSION_ENUM): boolean => {
      const roles = user?.["cognito:groups"];

      if (PERMISSIONS[permission]?.find(perm => roles.find(role => role === perm))) return true;

      return false;
    },
    [user?.["cognito:groups"]]
  );

  return hasPermission;
}
