import React from "react";
import { Route, Routes } from "react-router-dom";

import CompanyDetails from "./CompanyDetail";

export default function CompanyInformation() {
  return (
    <Routes>
      <Route path="*" element={<CompanyDetails />} />
    </Routes>
  );
}
