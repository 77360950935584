import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";

export type SupportedImageType = "webp" | "png" | "jpg";

interface ImageProps {
  testID?: string;
  src: string;
  supportedTypes: SupportedImageType[];
  alt: string;
  className?: string;
  children?: React.ReactElement;
}

const PictureStyled = styled.picture``;

ImageComponent.testID = "picture";

export default function ImageComponent({ testID, src, supportedTypes, alt, className, children }: ImageProps) {
  return (
    <PictureStyled className={className} data-testid={composeTestID(ImageComponent.testID, testID)}>
      {supportedTypes.includes("webp") && <source srcSet={`${src}.webp`} type="image/webp" />}
      {supportedTypes.includes("jpg") && <source srcSet={`${src}.jpg`} type="image/jpg" />}
      {supportedTypes.includes("png") && <source srcSet={`${src}.png`} type="image/png" />}
      <img src={`${src}.${supportedTypes[0]}`} alt={alt} />
      {children}
    </PictureStyled>
  );
}
