import api from "api";
import { APIBankAccount, APIBankAccountListItem, APICreateBankAccount } from "types/API/BankAccount";

import { MOCK_ACCOUNT_LIST } from "./mocks/mock-bank-account";

// import Connection from "./Connection";

// Commented out original request ready for completed endpoint
// const getAccountList = (): Promise<Array<APIBankAccountListItem>> => Connection.get("/bank-accounts");

const getAccountList = (): Promise<Array<APIBankAccountListItem>> =>
  api.Mock.mockRequest("/bank-accounts", MOCK_ACCOUNT_LIST);

// const getAccountById = (Id: string): Promise<APIBankAccountListItem> =>
//   Connection.get(`/bank-accounts/${accountId}`);

const getAccountById = (accountId: string): Promise<APIBankAccountListItem> =>
  api.Mock.mockRequest(
    `/bank-accounts/${accountId}`,
    MOCK_ACCOUNT_LIST.find(({ Id }) => Id === accountId)
  );

// const createAccount = (data: APICreateBankAccount) => Connection.post("/bank-accounts", data);

const createAccount = (data: APICreateBankAccount) => {
  const account: APIBankAccount = {
    ...data,
    Status: "pending_add",
    Id: `${MOCK_ACCOUNT_LIST.length + 1}`,
  };
  MOCK_ACCOUNT_LIST.push(account);
  return api.Mock.mockRequest("/bank-accounts", account);
};

// const updateAccount = (accountId: string, data: Partial<APIBankAccount>) =>
//   Connection.put(`/bank-accounts/${accountId}`, data);

const updateAccount = (accountId: string, data: Partial<APIBankAccount>) => {
  const index = MOCK_ACCOUNT_LIST.findIndex(({ Id }) => accountId === Id);
  MOCK_ACCOUNT_LIST[index] = { ...MOCK_ACCOUNT_LIST[index], ...data };
  return api.Mock.mockRequest(`/bank-accounts/${accountId}`, data);
};
// const deleteAccount = (accountId: string): Promise<boolean> => Connection.delete(`/bank-accounts/${accountId}`);

const deleteAccount = (accountId: string): Promise<boolean> => {
  const index = MOCK_ACCOUNT_LIST.findIndex(({ Id }) => accountId === Id);
  MOCK_ACCOUNT_LIST.splice(index, 1);
  return api.Mock.mockRequest(`/bank-accounts/${accountId}`, true);
};

// const approveAccount = (accountId: string, data: object): Promise<boolean> =>
//   Connection.post(`/bank-accounts/${accountId}/approve`, data);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const approveAccount = (accountId: string, data: object): Promise<boolean> => {
  const index = MOCK_ACCOUNT_LIST.findIndex(({ Id }) => accountId === Id);
  MOCK_ACCOUNT_LIST[index].Status = "verified";
  return api.Mock.mockRequest(`/bank-accounts/${accountId}/approve`, true);
};

// const rejectAccount = (accountId: string, data: object): Promise<boolean> =>
//   Connection.post(`/bank-accounts/${accountId}/reject`, data);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rejectAccount = (accountId: string, data: object): Promise<boolean> => {
  const index = MOCK_ACCOUNT_LIST.findIndex(({ Id }) => accountId === Id);
  MOCK_ACCOUNT_LIST[index].Status = "failed";
  return api.Mock.mockRequest(`/bank-accounts/${accountId}/reject`, true);
};

export default {
  getAccountList,
  getAccountById,
  createAccount,
  updateAccount,
  deleteAccount,
  approveAccount,
  rejectAccount,
};
