import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";

export type HeadingElementTypes = "h1" | "h2" | "h3" | "h4";

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  testID?: string;
  as: HeadingElementTypes;
  varient?: HeadingElementTypes;
  color?: string;
}

const HeadingStyled = styled.h1(({ varient, as = "h1", color }: Pick<HeadingProps, "varient" | "as" | "color">) => {
  const styleVarient = varient ?? as;

  return `
  font-family: ${fontFamily.secondary};
  ${color ? `color: ${color};` : ""}
  font-size: ${fontSize[styleVarient]};
`;
});

HeadingComponent.testID = "heading";

export default function HeadingComponent({ ...props }: HeadingProps) {
  return <HeadingStyled data-testid={composeTestID(HeadingComponent.testID, props.testID)} {...props} />;
}
