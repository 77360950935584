import { ReactComponent as PeopleIcon } from "assets/icons/people.svg";
import InputNote from "components/atoms/Form/InputNote/InputNote";
import Label from "components/atoms/Form/Label/Label";
import MultiSelect from "components/atoms/Form/MultiSelect";
import useRoles from "hooks/query/role/use-role";
import React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";
import input from "styles/input";

interface MultiSelectProps extends FieldRenderProps<string[], any> {
  iconElement?: React.ReactElement;
  label?: string;
  compact?: boolean;
}

const HeadlessMultiSelectContainer = styled.div`
  width: 100%;
  max-width: ${input.maxWidth};
`;

MultiSelectInput.testID = "MultiSelectInput";

export default function MultiSelectInput({
  input: { ...inputProps },
  iconElement,
  name,
  label,
  meta,
  compact = false,
  ...props
}: MultiSelectProps) {
  const { data: roles, isLoading } = useRoles();

  return (
    <HeadlessMultiSelectContainer data-testid={MultiSelectInput.testID}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <MultiSelect {...inputProps} {...props} options={roles} icon={<PeopleIcon />} loading={isLoading} />
      {(!compact || (meta?.touched && meta.error)) && (
        <InputNote htmlFor={name} color={meta?.error ? color.red : undefined}>
          {meta?.error}
        </InputNote>
      )}
    </HeadlessMultiSelectContainer>
  );
}
