import Heading from "components/atoms/Heading/Heading";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled from "styled-components";
import spacing from "styles/spacing";

interface CardTitleProps {
  title: string;
  buttonElement?: React.ReactElement;
}

const CardTitleStyled = styled.div`
  padding: ${spacing.m} ${spacing.l};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export default function CardTitleComponent({ title, buttonElement }: CardTitleProps) {
  const isMobile = useIsMobile();
  return (
    <CardTitleStyled>
      <Heading as="h2" varient={isMobile ? "h4" : "h3"}>
        {title}
      </Heading>
      {buttonElement}
    </CardTitleStyled>
  );
}
