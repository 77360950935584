import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import BottomBar from "components/organisms/BottomBar/BottomBar";
import { PERMISSION_ENUM } from "constants/permissions";
import { FORM_ERROR } from "final-form";
import createDecorator from "final-form-calculate";
import getErrorMessage from "functions/get-error-message";
import { validateFormValues } from "functions/validate-schema";
import withPermission from "hocs/permission";
import { usePostBuyOrder } from "hooks/query/buy/use-post-buy-order";
import * as React from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";
import { AppWalletListItem } from "types/App/Wallet";

import { SellOrderSchemaValidation } from "../common/schemaValidation";
import SellOrderForm from "../common/SellOrderForm";

interface BuyScreenProps {
  title: string;
  body: string;
}

interface BuyFormValues {
  wallet?: AppWalletListItem;
  currentBalance?: number;
  sell?: number;
}

const BuyScreenStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  & > h2 {
    font-weight: ${fontWeight.medium};
    margin-bottom: ${spacing.xs};
  }
`;

const FormContainer = styled.form`
  flex: 1;
`;

// Create Decorator
const decorator = createDecorator(
  {
    field: "wallet",
    updates: {
      currentBalance: wallet => wallet.Balance.Amount,
      newBalance: (wallet, values: any) =>
        +wallet?.Balance?.Amount - (values?.sell ? 0 : parseInt(`${values.sell ? values.buy : 0}`)),
    },
  },
  {
    field: "sell",
    updates: {
      newBalance: (sell, values: any) => (values?.wallet?.Balance?.Amount ?? 0) - parseInt(`${sell ?? 0}`),
      recieve: sell => (sell ? parseInt(sell) : undefined)?.toFixed(2),
    },
  }
);

function BuyScreenComponent({ title, body }: BuyScreenProps) {
  const { mutateAsync: mutateAsyncPostBuyOrder } = usePostBuyOrder();
  const navigate = useNavigate();

  const handleOnSubmit = async (values: BuyFormValues) => {
    if (values?.wallet?.WalletAddress === undefined || values?.sell === undefined) return;
    try {
      const response = await mutateAsyncPostBuyOrder({
        WalletAddress: values?.wallet?.WalletAddress,
        FiatAmount: values?.sell?.toString(),
      });
      const eventId = response?.Entries?.[0]?.EventId;
      navigate(`/sell/${eventId}`);
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  return (
    <BuyScreenStyled>
      <Heading as="h2" varient="h3">
        {title}
      </Heading>
      <Paragraph>{body}</Paragraph>
      <Form decorators={[decorator]} onSubmit={handleOnSubmit} validate={validateFormValues(SellOrderSchemaValidation)}>
        {({ handleSubmit, submitting, submitError }) => (
          <>
            <FormContainer>
              <SellOrderForm />
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
            </FormContainer>
            <BottomBar
              paddingHorizontal="0"
              rightElement={
                <Button onClick={handleSubmit} loading={submitting}>
                  Continue
                </Button>
              }
            ></BottomBar>
          </>
        )}
      </Form>
    </BuyScreenStyled>
  );
}

export default withPermission(PERMISSION_ENUM.SELL_EURR)(BuyScreenComponent);
