import { ReactComponent as IconForwardIOS } from "assets/icons/icon-forward-ios.svg";
import * as animationData from "assets/lottie/confetti.json";
import { ReactComponent as CheckSVG } from "assets/svg/check-circle.svg";
import Button from "components/atoms/Button/Button";
import TextWithButton from "components/molecules/TextWithButton/TextWithButton";
import BottomBar from "components/organisms/BottomBar/BottomBar";
import SVGFeature from "components/organisms/SVGFeature/SVGFeature";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import Lottie from "react-lottie";
import StepTemplate from "templates/StepTemplate/StepTemplate";

interface SuccessProps {
  onContinue: () => void;
}

const DEFAULT_OPTIONS = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function SuccessComponent({ onContinue }: SuccessProps) {
  const isMobile = useIsMobile();

  return (
    <StepTemplate
      backgroundElement={<Lottie options={DEFAULT_OPTIONS} style={{ opacity: 0.3 }} height={"100%"} width={"100%"} />}
      bottomBarElement={
        isMobile ? (
          <BottomBar
            leftElement={
              <Button varient="primary" onClick={onContinue} layout="icon-right" icon={<IconForwardIOS />}>
                Go to Dashboard
              </Button>
            }
          />
        ) : undefined
      }
    >
      <SVGFeature
        svg={<CheckSVG width={"150px"} />}
        contentElement={
          <TextWithButton
            title="Thank you for signing up with StablR!"
            body="Sign up process is completed. You can now continue."
            buttonElement={
              !isMobile ? (
                <Button varient="primary" onClick={onContinue} layout="icon-right" icon={<IconForwardIOS />}>
                  Go to Dashboard
                </Button>
              ) : undefined
            }
          />
        }
      ></SVGFeature>
    </StepTemplate>
  );
}
