import CardError from "components/molecules/Card/CardError";
import CardLoader from "components/molecules/Card/CardLoader";
import useTransaction from "hooks/query/transaction/use-transaction";
import * as React from "react";
import { useParams } from "react-router-dom";

import PayPendingScreen from "./PayPendingScreen";
import PayProcessingScreen from "./PayProcessingScreen";
import SuccessScreen from "./SuccessScreen";

// import PaymentInstruction from "./PaymentInstruction";
// import SuccessScreen from "./SuccessScreen";

export default function TransactionScreenComponent() {
  //   const navigate = useNavigate();
  const { id } = useParams();

  const { data: transaction, isLoading, error, refetch } = useTransaction(id ?? "");

  //   const handleOnBack = async () => {
  //     navigate("/sell");
  //   };

  return isLoading ? (
    <CardLoader />
  ) : error || (transaction === undefined && !isLoading) ? (
    <CardError title="Error" body="Error fetching Buy order" onRetry={refetch} />
  ) : transaction.Status === "AWAITING_CONFIRMATION" ? (
    <PayPendingScreen
      transaction={transaction}
      title="2. Pay Out "
      body="Please follow these steps to sell your StablR assets."
    />
  ) : transaction.Status === "PENDING" ? (
    <PayProcessingScreen
      transaction={transaction}
      title="2. Pay Out "
      body="Please follow these steps to sell your StablR assets."
    />
  ) : transaction.Status === "CONFIRMED" ? (
    <SuccessScreen
      transaction={transaction}
      title="3. Transaction Successful"
      body="Your transaction has successfully been completed"
    />
  ) : (
    <CardError title="Error" body={`Transaction status "${transaction.Status}" unkown`} onRetry={refetch} />
  );
}
