import { ReactComponent as BackIcon } from "assets/icons/arrow-back.svg";
import Button from "components/atoms/Button/Button";
import HeadingComponent from "components/atoms/Heading/Heading";
import { useIsMobile } from "hooks/use-is-mobile";
import React, { ReactElement } from "react";
import styled from "styled-components";
import color from "styles/color";

interface HeaderBackNavProps {
  title: string;
  size?: "large" | "small";
  iconSize?: number;
  backIcon?: ReactElement;
  onBack?: () => void;
  children?: ReactElement | ReactElement[];
}
const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

HeaderBackNav.testID = "headerBackNav";

export default function HeaderBackNav({
  title,
  onBack,
  backIcon,
  size,
  iconSize,
  children,
  ...props
}: HeaderBackNavProps) {
  const isMobile = useIsMobile();

  return (
    <HeadingContainer data-testid={HeaderBackNav.testID} {...props}>
      {!!onBack && (
        <Button
          icon={backIcon ? backIcon : <BackIcon />}
          color={color.purple}
          varient="secondary"
          rounded
          iconSize={iconSize ?? 20}
          size={size}
          layout="icon-only"
          onClick={onBack}
        />
      )}
      <HeadingComponent as="h3" varient={isMobile ? "h4" : "h3"}>
        {title}
      </HeadingComponent>
      {children}
    </HeadingContainer>
  );
}
