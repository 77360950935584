import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

interface FormLayoutProps {
  testID?: string;
  children: React.ReactElement;
  columns?: 1 | 2;
}

const DESKTOP_SPACING = spacing.xl;

const FormLayoutStyled = styled.div(
  ({ $columns }: { $columns?: 1 | 2 }) => `
  & > * {
    margin: ${spacing.xs} 0;
  }
  @media ${media.mobile} {
  }
  @media ${media.desktop} {
    ${
      $columns === 2
        ? `
    & > * {
      width: calc(50% - ${DESKTOP_SPACING} / 2);
      display: inline-block;
      vertical-align: top;
      &:nth-child(1) {
        margin-right: ${DESKTOP_SPACING};
      }
    }`
        : ""
    }
  }
`
);

FormLayoutComponent.testID = "formLayout";

export default function FormLayoutComponent({ columns, ...props }: FormLayoutProps) {
  return (
    <FormLayoutStyled data-testid={composeTestID(FormLayoutComponent.testID, props.testID)} $columns={columns}>
      {props.children}
    </FormLayoutStyled>
  );
}
