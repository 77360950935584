import React from "react";
import { Route, Routes } from "react-router-dom";

import Accounts from "./AccountList";
import AddBankAccount from "./AddBankAccount";
import EditBankAccount from "./EditBankAccount";

export default function BankAccounts() {
  return (
    <Routes>
      <Route path="*" element={<Accounts />} />
      <Route path="/add" element={<AddBankAccount />} />
      <Route path="/:accountId/edit" element={<EditBankAccount />} />
    </Routes>
  );
}
