import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

const MARGIN = spacing.l;

const UserDropDownDividerStyled = styled.div`
  width: calc(100% - ${MARGIN} * 2);
  border-top: 1px solid ${color.grey2};
  margin: 0 ${MARGIN};
  box-sizing: border-box;
`;

export default function UserDropDownDividerComponent() {
  return <UserDropDownDividerStyled />;
}
