import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";

import useTransaction from "./use-transaction";

export default function useConfirmTransaction() {
  const queryCache = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, transactionHash }: { id: string; transactionHash: string }) => {
      return api.Transaction.confirmTransaction(id, transactionHash);
    },
    onSuccess: (_, { id }: { id: string; transactionHash: string }) => {
      queryCache.invalidateQueries({ queryKey: [useTransaction.queryKey, id] });
    },
  });
}
