import { useQuery } from "@tanstack/react-query";
import api from "api";
import { AppDashboard } from "types/App/Dashboard";

useDashboard.queryKey = "dashboard";
export default function useDashboard() {
  return useQuery([useDashboard.queryKey], async () => api.Dashboard.getDashboard() as Promise<AppDashboard>, {
    notifyOnChangeProps: "all",
  });
}
