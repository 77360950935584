import { ReactComponent as BuyIconSVG } from "assets/icons/buy.svg";
import Button from "components/atoms/Button/Button";
import RenderWithPermissions from "components/atoms/Permission";
import CardData from "components/molecules/Card/CardData";
import CardWrapper from "components/molecules/Card/CardWrapper";
import { PERMISSION_ENUM } from "constants/permissions";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

interface BalanceCardProps {
  currentBalance: string;
  onBuyClick: () => void;
}

const BalanceCardStyled = styled(CardWrapper)`
  background: ${color.gradient};
  padding: ${spacing.m} ${spacing.l};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function BalanceCardComponent({ currentBalance, onBuyClick }: BalanceCardProps) {
  const isMobile = useIsMobile();
  return (
    <BalanceCardStyled>
      <CardData color={color.white} label="My Balance" data={`${currentBalance} EURR`} />
      <RenderWithPermissions permission={PERMISSION_ENUM.BUY_EURR}>
        {!isMobile && (
          <Button
            rounded
            size="small"
            color={color.secondary}
            icon={<BuyIconSVG />}
            onClick={onBuyClick}
            varient="secondary"
          >
            Buy
          </Button>
        )}
      </RenderWithPermissions>
    </BalanceCardStyled>
  );
}
