import { ReactComponent as WizardStepComplete } from "assets/icons/step-complete.svg";
import { ReactComponent as WizardStepIncomplete } from "assets/icons/step-incomplete.svg";
import Icon from "components/atoms/Icon/Icon";
import * as React from "react";
import styled, { useTheme } from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface WizardHorizontalNavigationStepProps {
  title?: string;
  type?: "incomplete" | "complete";
}

export const WIZARD_NAVIGATION_STEP_SVG_SIZE = "38px";
export const WIZARD_NAVIGATION_STEP_OVERFLOW_HEIGHT = spacing.xs;
const HEIGHT = "80px";

const WizardHorizontalNavigationStepStyled = styled.li`
  position: relative;
  list-style: none;
  align-items: center;
  height: ${HEIGHT};

  & svg {
    width: ${WIZARD_NAVIGATION_STEP_SVG_SIZE};
    height: ${WIZARD_NAVIGATION_STEP_SVG_SIZE};
  }

  &:not(:first-child, :last-child) {
    .text-wrapper {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:first-child {
    .text-wrapper {
      left: 0;
    }
  }

  &:last-child {
    .text-wrapper {
      right: 0;
    }
  }
`;

const WizardStepTextWrapper = styled.div`
  position: absolute;
  top: ${WIZARD_NAVIGATION_STEP_SVG_SIZE};
  white-space: nowrap;
`;

const SpanStyled = styled.span`
  color: ${color.greyDark1};
  font-family: ${fontFamily.primary};
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.button};
  padding: ${spacing.s} 0;
  display: block;

  &[data-active="true"] {
    color: ${color.black};
  }
`;

WizardHorizontalNavigationStepComponent.testID = "WizardHorizontalNavigationStep";

export default function WizardHorizontalNavigationStepComponent({
  title,
  type = "complete",
}: WizardHorizontalNavigationStepProps) {
  const { color } = useTheme();

  return (
    <WizardHorizontalNavigationStepStyled data-active={type === "complete"}>
      <Icon color={color.primary}>{type === "complete" ? <WizardStepComplete /> : <WizardStepIncomplete />}</Icon>
      {title !== undefined && (
        <WizardStepTextWrapper className="text-wrapper">
          <SpanStyled data-active={type === "complete"}>{title}</SpanStyled>
        </WizardStepTextWrapper>
      )}
    </WizardHorizontalNavigationStepStyled>
  );
}
