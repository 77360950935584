import * as yup from "yup";

export enum BUY_ORDER_SCHEMA {
  WALLET = "wallet",
  CURRENT_BALANCE = "currentBalance",
  NEW_BALANCE = "newBalance",
  BUY = "buy",
  PAY = "pay",
  REFERENCE = "reference",
}

export const BuyOrderSchemaValidation = yup.object({
  [BUY_ORDER_SCHEMA.WALLET]: yup.object().required("Wallet address is required"),
  [BUY_ORDER_SCHEMA.BUY]: yup
    .number()
    .moreThan(10)
    .lessThan(1000000, "You can not buy more than 1.000.000 EURR")
    .required("Buy amount is required"),
});
