import { useQuery } from "@tanstack/react-query";
import api from "api";

useRoles.queryKey = "roles";

export default function useRoles() {
  return useQuery([useRoles.queryKey], async () => api.Role.getRolesList(), {
    notifyOnChangeProps: "all",
  });
}
