import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import TableBody from "components/atoms/Table/TableBody";
import TableData from "components/atoms/Table/TableData/TableData";
import TableHead from "components/atoms/Table/TableHead";
import TableHeader from "components/atoms/Table/TableHeader/TableHeader";
import TableRow from "components/atoms/Table/TableRow";
import { PERMISSION_ENUM } from "constants/permissions";
import usePermission from "hooks/context/use-permission";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled from "styled-components";
import fontWeight from "styles/fontWeight";
import { AppPaymentListItem } from "types/App/Payment";

import PaymentDownload from "./PaymentDownload";

interface PaymentTableProps {
  data: AppPaymentListItem[];
}

const PaymentTableStyled = styled.table`
  width: 100%;

  & th {
    padding-left: 0;
    font-weight: ${fontWeight.bold};
  }

  & td {
    padding-left: 0;
  }
`;

const PaymentTableWrapperStyled = styled.div`
  overflow-x: auto;

  & tr {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    & b {
      font-weight: ${fontWeight.semiBold};
    }
  }
`;

const columnHelper = createColumnHelper<AppPaymentListItem>();

const tableColumns = [
  columnHelper.accessor("TransferDate", {
    header: "Date",
    cell: info => new Date(info.getValue()).toLocaleDateString(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Filename", {
    header: "Filename",
    cell: info => <b>{info.getValue()}</b>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("DepositOrderId", {
    header: "Transaction ID",
    cell: info => <span>{info.getValue()}</span>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("PaymentInstructionId", {
    header: "Payment Reference",
    cell: info => <span>{info.getValue()}</span>,
    footer: info => info.column.id,
  }),
];

const mobileColumns = [tableColumns[0], tableColumns[1], tableColumns[2]];

export default function PaymentTableComponent({ data }: PaymentTableProps) {
  const isMobile = useIsMobile();
  const hasPermission = usePermission();

  const columns = React.useMemo(() => {
    const col = isMobile ? mobileColumns : tableColumns;
    if (hasPermission(PERMISSION_ENUM.DOWNLOAD_PAYMENT_INSTRUCTIONS))
      col.push(
        columnHelper.accessor("TransactionID", {
          header: "",
          cell: info => <PaymentDownload transactionId={info.getValue()} />,
          footer: info => info.column.id,
        })
      );
    return col;
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <PaymentTableWrapperStyled>
      <PaymentTableStyled>
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader key={header.id}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </tr>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>
                  );
                })}
              </TableRow>
            ))}
          </>
        </TableBody>
      </PaymentTableStyled>
    </PaymentTableWrapperStyled>
  );
}
