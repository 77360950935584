import Button from "components/atoms/Button/Button";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import spacing from "styles/spacing";

interface UserDropDownActionsProps {
  onLogout: () => void;
  version: string;
}

const UserDropDownActionsStyled = styled.div`
  margin: ${spacing.m} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VersionStyled = styled.span`
  text-decoration: underline;
  margin: ${spacing.m} 0 0 0;
  font-size: ${fontSize.small};
  color: ${color.greyDark1};
  font-family: ${fontFamily.primary};
`;

export default function UserDropDownActionsComponent({ onLogout, version }: UserDropDownActionsProps) {
  return (
    <UserDropDownActionsStyled>
      <Button size="small" varient="line" onClick={onLogout} rounded>
        Log Out
      </Button>
      <VersionStyled>Version: {version}</VersionStyled>
    </UserDropDownActionsStyled>
  );
}
