import Button from "components/atoms/Button/Button";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import ModalComponent from "components/molecules/Modal";
import React, { ReactElement } from "react";
import color from "styles/color";

export default function DeleteModal({
  title,
  open,
  varient,
  onClose,
  onConfirm,
  btnText,
  description,
  btnColor,
  btnBackgroundColor,
  iconElement,
  iconSize,
  loading,
  marginHorizontalSize,
  marginSize,
  errorMessage,
}: {
  btnText: string;
  title: string;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  varient?: "primary" | "secondary";
  description: string | ReactElement;
  btnColor?: string;
  btnBackgroundColor?: string;
  iconElement?: ReactElement;
  marginSize?: "large" | "small";
  marginHorizontalSize?: "large" | "small";
  iconSize?: number;
  loading?: boolean;
  errorMessage?: string;
}) {
  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      title={title}
      modalBody={
        <>
          <Paragraph>{description}</Paragraph>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </>
      }
      modalFooter={
        <>
          <Button
            varient="secondary"
            color={color.black}
            marginHorizontalSize="small"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            color={btnColor}
            varient={varient}
            icon={iconElement}
            iconSize={iconSize ?? 20}
            backgroundColor={btnBackgroundColor}
            onClick={onConfirm}
            disabled={loading}
            marginHorizontalSize={marginHorizontalSize}
            size={marginSize}
            loading={loading}
          >
            {btnText}
          </Button>
        </>
      }
    />
  );
}
