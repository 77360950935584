import { ReactComponent as EmailIcon } from "assets/icons/alternate-email.svg";
import { ReactComponent as PasswordIcon } from "assets/icons/lock.svg";
import { Auth } from "aws-amplify";
import Button from "components/atoms/Button/Button";
import InputLink from "components/atoms/Form/InputLink/InputLink";
import Heading from "components/atoms/Heading/Heading";
import Link from "components/atoms/Inline/Link";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import PasswordInput from "components/molecules/Form/PasswordInput/PasswordInput";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import Constants from "constants/index";
import { FORM_ERROR } from "final-form";
import composeTestID from "functions/compose-test-id";
import getErrorMessage from "functions/get-error-message";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import { validateFormValues } from "functions/validate-schema";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import HalfHalfResponsive from "templates/HalfHalfResponsive/HalfHalfResponsive";
import { AmplifyUser } from "types/amplify/AmplifyUser";
import * as yup from "yup";
interface SignInProps {
  testID?: string;
  onComplete: (user: AmplifyUser) => void;
}

interface SignInFormValues {
  email: string;
  password: string;
}

const SignInStyled = styled.main`
  width: 100%;
  min-height: 100%;
`;

SignInComponent.testID = "signIn";
yup;
export const LoginSchemaValidation = yup.object({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default function SignInComponent({ onComplete, ...props }: SignInProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOnSubmit = async ({ email, password }: SignInFormValues) => {
    setIsLoading(true);

    try {
      const user = await Auth.signIn(email, password);
      logEvent(EVENT_TYPE_ENUM.AUTHENTICATION, "LoginSubmit");
      onComplete(user);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        navigate("./new-password");
        return;
      } else if (user.challengeName === "SMS_MFA") {
        navigate("./sms-authentication");
        return;
      }

      const hasOrganisation = typeof user.attributes?.["custom:assignedOrganization"] === "string";

      if (hasOrganisation === false) {
        navigate("../sign-up/company-verification");
      } else {
        navigate("../dashboard"); // Should only need this route for users with disabled MFA
      }
    } catch (error) {
      setIsLoading(false);

      return { [FORM_ERROR]: getErrorMessage(error) };
    }
    setIsLoading(false);
  };

  return (
    <SignInStyled data-testid={composeTestID(SignInComponent.testID, props.testID)}>
      <HalfHalfResponsive>
        <Form onSubmit={handleOnSubmit} validate={validateFormValues(LoginSchemaValidation)}>
          {({ handleSubmit, submitError }) => (
            <>
              <Heading as="h1" varient="h2">
                Hi, Welcome Back!
              </Heading>
              <Paragraph>Easy access to fully backed digital assets</Paragraph>
              <Field
                name="email"
                label="Email Address"
                icon={<EmailIcon />}
                component={TextInput}
                placeholder="email@stablr.com"
              ></Field>
              <Field
                name="password"
                label="Password"
                icon={<PasswordIcon />}
                component={PasswordInput}
                placeholder="Password"
                appendElement={<InputLink to="/sign-in/reset-password">Forgot password?</InputLink>}
              ></Field>
              <ErrorMessage>{submitError}</ErrorMessage>
              <Button loading={isLoading} width="100%" onClick={handleSubmit}>
                Login
              </Button>
              {!!Constants.showSignUp && (
                <Paragraph>
                  Not registered yet? <Link to="/sign-up">Create an account</Link>
                </Paragraph>
              )}
            </>
          )}
        </Form>
      </HalfHalfResponsive>
    </SignInStyled>
  );
}
