import { Menu } from "@headlessui/react";
import { ReactComponent as CloseSVG } from "assets/icons/close.svg";
import { ReactComponent as MenuSVG } from "assets/icons/menu.svg";
import Icon from "components/atoms/Icon/Icon";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

import { MOBILE_HEADER_HEIGHT } from "./HeaderNavigation";

interface MenuDropDownProps {
  testID?: string;
  children?: React.ReactElement[] | React.ReactElement;
}

const MENU_SIZE = "54px";

const MenuButtonStyled = styled.button`
  background-color: ${color.white};
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${MENU_SIZE};
  width: ${MENU_SIZE};
  z-index: 200;
`;

const MenuItemsStyled = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${color.white};
  padding: ${spacing.xxs};
  width: 100%;
  height: 100%;
  padding-top: ${MOBILE_HEADER_HEIGHT};
  display: flex;
  flex-direction: column;
  z-index: 100;
`;

const ULStyled = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  overflow-y: auto;
  flex: 1;
`;

MenuDropDownComponent.testID = "MenuDropDown";

export default function MenuDropDownComponent({ children, ...props }: MenuDropDownProps) {
  return (
    <Menu data-testid={composeTestID(MenuDropDownComponent.testID, props.testID)}>
      {({ open }) => (
        <>
          <Menu.Button as={MenuButtonStyled}>
            <Icon size={24} gradient={open === false ? true : undefined} color={color.black}>
              {open === true ? <CloseSVG /> : <MenuSVG />}
            </Icon>
          </Menu.Button>
          <Menu.Items as={MenuItemsStyled}>
            <ULStyled>{children}</ULStyled>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}
