import { ReactComponent as PhoneSVG } from "assets/icons/phone.svg";
import ComboBox from "components/atoms/Form/ComboBox/ComboBox";
import Input from "components/atoms/Form/Input/Input";
import InputNote from "components/atoms/Form/InputNote/InputNote";
import Label from "components/atoms/Form/Label/Label";
import composeTestID from "functions/compose-test-id";
import { phone } from "phone";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";
import input from "styles/input";
import spacing from "styles/spacing";

import countryCodesJSON from "./country-codes.json";

interface PhoneNumberInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testID?: string;
  note?: string;
  compact?: boolean;
}

const BORDER_MARGIN = spacing.xs;

const COUNTRY_CODE_OPTIONS = countryCodesJSON.data;

const PhoneNumberInputStyled = styled.div`
  max-width: ${input.maxWidth};
`;

const InputGroupStyled = styled.div`
  display: flex;

  & > div:first-child {
    width: 135px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;

    &::after {
      content: "";
      height: calc(100% - ${BORDER_MARGIN} * 2);
      box-sizing: border-box;
      margin: ${BORDER_MARGIN} 0;
      width: 0;
      border-left: solid ${color.greyDark1} 1px;
    }
  }

  & > *:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    width: unset;
    flex: 1;
  }

  &.disabled {
    & > div:first-child {
      width: 110px;
    }
  }
`;

PhoneNumberInputComponent.testID = "phoneNumberInput";

export default function PhoneNumberInputComponent({
  input: { type = "tel", ...inputProps },
  note,
  compact,
  meta: { error, touched },
  disabled,
  ...props
}: PhoneNumberInputProps) {
  const [extension, setExtension] = React.useState(inputProps.value ? inputProps.value[0] : "");

  const handleOnChange = (ev: React.FormEvent<HTMLInputElement>) => {
    const target = ev.target as HTMLInputElement;
    inputProps.onChange({ ...ev, target: { ...ev.target, value: `${extension}${target.value}` } });
  };

  const [extensionValue, numberValue] = React.useMemo(() => {
    const { countryCode } = phone(inputProps.value);
    const number = typeof inputProps.value === "string" ? inputProps.value.replace(countryCode ?? extension, "") : "";
    return [countryCode, number];
  }, [inputProps.value]);

  const isError = touched && error;

  return (
    <PhoneNumberInputStyled data-testid={composeTestID(PhoneNumberInputComponent.testID, props.testID)}>
      <Label htmlFor={inputProps.name}>{props.label}</Label>
      <InputGroupStyled className={disabled ? "disabled" : ""}>
        <ComboBox
          icon={<PhoneSVG />}
          placeholder={"+00"}
          name={`${inputProps.name}-extension`}
          onChange={setExtension}
          options={COUNTRY_CODE_OPTIONS}
          value={extensionValue ?? extension}
          disabled={disabled}
        />
        <Input
          {...inputProps}
          {...props}
          disabled={disabled}
          type={type}
          onChange={handleOnChange}
          value={numberValue}
        ></Input>
      </InputGroupStyled>
      {(!compact || note?.trim() || isError) && (
        <InputNote htmlFor={inputProps.name} color={isError ? color.red : undefined}>
          {isError ? error : note}
        </InputNote>
      )}
    </PhoneNumberInputStyled>
  );
}
