import { APIPaymentListItem } from "types/API/Payment";

import { MOCK_ACCOUNT_LIST } from "./mock-bank-account";

export const MOCK_PAYMENT_DATA: APIPaymentListItem[] = [
  {
    Filename: "Payment_001.pdf",
    TransactionID: "98764321355677",
    OrganizationRegistrationNumber: "Org123",
    PaymentInstructionId: "payment_123",
    DepositOrderId: "deposit_123",
    Status: "Verified",
    FiatCurrency: {
      Amount: 1000000,
      Denominator: ".",
    },
    CreatedAt: new Date().toISOString(),
    TransferDate: new Date().toISOString(),
    ConfirmedDate: new Date().toISOString(),
    ToBankAccount: MOCK_ACCOUNT_LIST[1],
    FromBankAccount: MOCK_ACCOUNT_LIST[0],
  },
  {
    Filename: "Payment_002.pdf",
    TransactionID: "98764321355678",
    OrganizationRegistrationNumber: "Org123",
    PaymentInstructionId: "payment_123",
    DepositOrderId: "deposit_123",
    Status: "Verified",
    FiatCurrency: {
      Amount: 1000000,
      Denominator: ".",
    },
    CreatedAt: new Date().toISOString(),
    TransferDate: new Date().toISOString(),
    ConfirmedDate: new Date().toISOString(),
    ToBankAccount: MOCK_ACCOUNT_LIST[1],
    FromBankAccount: MOCK_ACCOUNT_LIST[0],
  },
  {
    Filename: "Payment_003.pdf",
    TransactionID: "58764321355677",
    OrganizationRegistrationNumber: "Org123",
    PaymentInstructionId: "payment_123",
    DepositOrderId: "deposit_123",
    Status: "Verified",
    FiatCurrency: {
      Amount: 1000000,
      Denominator: ".",
    },
    CreatedAt: new Date().toISOString(),
    TransferDate: new Date().toISOString(),
    ConfirmedDate: new Date().toISOString(),
    ToBankAccount: MOCK_ACCOUNT_LIST[1],
    FromBankAccount: MOCK_ACCOUNT_LIST[0],
  },
];
