import TableData from "components/atoms/Table/TableData/TableData";
import TableHeader from "components/atoms/Table/TableHeader/TableHeader";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";

interface FormSummaryTableDataItem {
  label: string;
  icon?: React.ReactElement;
  value?: string;
}

interface FormSummaryTableProps {
  testID?: string;
  data: FormSummaryTableDataItem[];
}

const FormSummaryTableStyled = styled.table`
  border-radius: 3px;
  border: 1px solid ${color.grey3};
  box-shadow: 0 0 8px ${color.borderGradient};
  background-color: ${color.white};
  width: 100%;
  box-sizing: border-box;
  table-layout: auto;
`;

const FormSummaryTableRowStyled = styled.tr`
  td {
    width: 100%;
  }
`;

FormSummaryTableComponent.testID = "formSummaryTable";

export default function FormSummaryTableComponent({ data, ...props }: FormSummaryTableProps) {
  return (
    <FormSummaryTableStyled cellSpacing="0" data-testid={composeTestID(FormSummaryTableComponent.testID, props.testID)}>
      {data.map(item => (
        <FormSummaryTableRowStyled key={item.label}>
          <TableHeader paddingSize="small" icon={item.icon}>
            {item.label}
          </TableHeader>
          <TableData paddingSize="small">{item.value}</TableData>
        </FormSummaryTableRowStyled>
      ))}
    </FormSummaryTableStyled>
  );
}
