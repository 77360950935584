export default function parseBadgeVarient(value: string) {
  switch (value.toLowerCase()) {
    case "pending":
      return "info";
    case "confirmed":
      return "success";
    case "rejected":
      return "error";
    default:
      return undefined;
  }
}
