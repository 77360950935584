import { useQuery } from "@tanstack/react-query";
import api from "api";

useBankAccounts.queryKey = "bankAccounts";

export function useBankAccounts() {
  return useQuery([useBankAccounts.queryKey], async () => api.BankAccount.getAccountList(), {
    notifyOnChangeProps: "all",
  });
}
