import { ReactComponent as PeopleIcon } from "assets/icons/people.svg";
import ActionHeader from "components/molecules/ActionHeader";
import CardEmpty from "components/molecules/Card/CardEmpty";
import CardError from "components/molecules/Card/CardError";
import CardLoader from "components/molecules/Card/CardLoader";
import { PERMISSION_ENUM } from "constants/permissions";
import getErrorMessage from "functions/get-error-message";
import withPermission from "hocs/permission";
import useUsers from "hooks/query/user/use-users";
import React from "react";
import { useNavigate } from "react-router-dom";

import UserTableComponent from "./UsersTable";

export function UserOverview() {
  const navigation = useNavigate();

  const { data: users, isLoading, error, refetch } = useUsers();

  return (
    <>
      <ActionHeader
        title="User Overview"
        btnText="Add User"
        onBtnClick={() => navigation("/accounts/user-management/add")}
        permission={PERMISSION_ENUM.ADD_USERS}
      />
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching users" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !users?.length ? (
        <CardEmpty
          iconElement={<PeopleIcon />}
          title="No Users found"
          body="There are no Users associated to your organization at the moment"
        />
      ) : (
        <UserTableComponent data={users} />
      )}
    </>
  );
}

export default withPermission(PERMISSION_ENUM.VIEW_USERS)(UserOverview);
