import { API } from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import Constants from "constants/index";
import logError from "functions/log/log-error";

interface SocketResponse {
  Result: "Fail" | "Success";
  Message: string;
  [anyKey: string]: string;
}

const connectSocket = async (eventId: string) => {
  const baseUrl = process.env.REACT_APP_BASE_WEBSOCKET;
  const socketURL = `${baseUrl}?eventId=${eventId}`;
  const socket = new WebSocket(socketURL);
  const message = await new Promise((resolve, reject) => {
    socket.onerror = (error: Event) => {
      console.log({ socketError: error });
      reject("Socket connection failure");
    };
    socket.onmessage = ({ data }: MessageEvent<string>) => {
      const result = JSON.parse(data) as SocketResponse;
      if (result.Result === "Fail") {
        reject(result.Message);
      } else if (result.Result === "Success") {
        resolve(result.Message);
      } else {
        console.log("Unknown response event", result);
        reject("Unhandled reponse rejected!");
      }
      socket.close();
    };
  });

  return message;
};

const Connection = {
  get: async (path: string, options?: object, errorMessage?: string) => {
    return API.get(Constants.apiName, `api${path}`, {
      headers: await getHeaders(),
      ...(options ? options : {}),
    }).catch(er => {
      errorMessage && logError(errorMessage);
      throw er;
    });
  },
  post: async (path: string, body?: object, options?: object) => {
    return API.post(Constants.apiName, `api${path}`, {
      body,
      headers: await getHeaders(),
      ...(options ? options : {}),
    });
  },
  postSocket: async (path: string, body?: object, options?: object, errorMessage?: string) => {
    try {
      const response = await API.post(Constants.apiName, `api${path}`, {
        body: body,
        headers: await getHeaders(),
        ...(options ? options : {}),
      });
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorMessage && logError(errorMessage);
      throw er;
    }
  },
  delete: async (path: string, body?: object, options?: object) => {
    return API.del(Constants.apiName, `api${path}`, {
      body,
      headers: await getHeaders(),
      ...(options ? options : {}),
    });
  },
  deleteSocket: async (path: string, body?: object, options?: object, errorMessage?: string) => {
    try {
      const response = await API.del(Constants.apiName, `api${path}`, {
        body,
        headers: await getHeaders(),
        ...(options ? options : {}),
      });
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorMessage && logError(errorMessage);
      throw er;
    }
  },
  patch: async (path: string, body?: object, options?: object) => {
    return API.patch(Constants.apiName, `api${path}`, {
      body,
      headers: await getHeaders(),
      ...(options ? options : {}),
    });
  },
  patchSocket: async (path: string, body?: object, options?: object, errorMessage?: string) => {
    try {
      const response = await API.patch(Constants.apiName, `api${path}`, {
        body,
        headers: await getHeaders(),
        ...(options ? options : {}),
      });
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorMessage && logError(errorMessage);
      throw er;
    }
  },
  put: async (path: string, body?: object, options?: object) => {
    return API.put(Constants.apiName, `api${path}`, {
      body,
      headers: await getHeaders(),
      ...(options ? options : {}),
    });
  },
  putSocket: async (path: string, body?: object, options?: object, errorMessage?: string) => {
    try {
      const response = await API.put(Constants.apiName, `api${path}`, {
        body,
        headers: await getHeaders(),
        ...(options ? options : {}),
      });
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorMessage && logError(errorMessage);
      throw er;
    }
  },
  postNoAuth: async (path: string, body?: object, options?: object) => {
    const response = await API.post(Constants.apiName, `api${path}`, {
      body,
      ...(options ? options : {}),
    });
    return typeof response === "string" ? JSON.parse(response) : response;
  },
};

export default Connection;

const getHeaders = async () => ({
  Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
});
