import { APICreateUser, APIUpdateUser, APIUserListItem } from "types/API/User";

import Connection from "./Connection";

const getUserList = (): Promise<APIUserListItem[]> => Connection.get("/users", undefined, "GetUserList");

const getUser = (userId: string): Promise<APIUserListItem> =>
  Connection.get(`/users/${userId}`, undefined, "GetUserDetail");

const createUser = (user: APICreateUser) => Connection.postSocket("/users", user, undefined, "PostCreateUser");

const updateUser = (userId: string, user: APIUpdateUser) =>
  Connection.putSocket(`/users/${userId}`, user, undefined, "UpdateUser");

const deleteUser = (userId: string) => Connection.deleteSocket(`/users/${userId}`, undefined, undefined, "DeleteUser");

const approveUser = (userId: string, data: object): Promise<boolean> =>
  Connection.post(`/users/${userId}/approve`, data);

const rejectUser = (userId: string, data: object): Promise<boolean> => Connection.post(`/users/${userId}/reject`, data);

export default {
  getUserList,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  approveUser,
  rejectUser,
};
