import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import getErrorMessage from "functions/get-error-message";
import { useToast } from "hooks/use-toast";

import { useUser } from "./use-user";
import useUsers from "./use-users";

export function useDeleteUser() {
  const queryClient = useQueryClient();
  const { successToast, errorToast } = useToast();
  return useMutation({
    mutationFn: (userId: string) => api.User.deleteUser(userId),
    onSuccess: async (message: any, userId: string) => {
      await queryClient?.invalidateQueries({ queryKey: [useUsers.queryKey] });
      await queryClient?.invalidateQueries({ queryKey: [useUser.queryKey, userId] });
      successToast({ message });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
