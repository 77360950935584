import { ReactComponent as AddIcon } from "assets/icons/add-circle-outline.svg";
import Button from "components/atoms/Button/Button";
import RenderWithPermissions from "components/atoms/Permission";
import Modal from "components/organisms/Modal";
import { PERMISSION_ENUM } from "constants/permissions";
import { useApproveAccount } from "hooks/query/bank-account/use-aprove-bank-account";
import React, { useEffect, useState } from "react";
import color from "styles/color";

export default function ApproveBankAccount({ title, accountId }: { accountId: string; title: string }) {
  const [open, setOpen] = useState<boolean>(false);

  const { mutate: mutateApproveAccount, isLoading, isSuccess } = useApproveAccount();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const onApprove = () => mutateApproveAccount({ accountId, accountDetails: {} });

  useEffect(() => {
    isSuccess && onCloseModal();
  }, [isSuccess]);

  return (
    <RenderWithPermissions permission={PERMISSION_ENUM.APPROVE_BANK_ACCOUNTS}>
      <Button color={color.white} icon={<AddIcon />} iconSize={20} onClick={openModal}>
        Add
      </Button>
      <Modal
        open={open}
        onClose={onCloseModal}
        title={`Add Bank Account '${title}'`}
        btnText="Confirm"
        description={`Are you sure you want to add ${title}?`}
        onConfirm={onApprove}
        btnColor={color.white}
        loading={isLoading}
      />
    </RenderWithPermissions>
  );
}
