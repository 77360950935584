import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const HomeContainer = styled.div``;

export default function HomePage() {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/sign-in");
  }, []);
  return <HomeContainer></HomeContainer>;
}
