import * as React from "react";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

interface SignUpTemplateProps {
  navigationElement: React.ReactElement;
  menuElement?: React.ReactElement;
  children: React.ReactElement;
}

const SignUpTemplateContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  @media ${media.mobile} {
    flex-direction: column;
  }
  @media ${media.desktop} {
    flex-direction: row;
  }
`;

const MainStyled = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MenuElementStyled = styled.div`
  z-index: 200;
  @media ${media.mobile} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: ${spacing.s} ${spacing.m};
  }
  @media ${media.desktop} {
    position: absolute;
    top: ${spacing.l};
    right: ${spacing.l};
  }
`;

export default function SignUpTemplateComponent({ navigationElement, menuElement, children }: SignUpTemplateProps) {
  return (
    <SignUpTemplateContainer>
      {navigationElement}
      {menuElement && <MenuElementStyled>{menuElement}</MenuElementStyled>}
      <MainStyled>{children}</MainStyled>
    </SignUpTemplateContainer>
  );
}
