import { ReactComponent as DeleteIcon } from "assets/icons/delete-outline.svg";
import Button from "components/atoms/Button/Button";
import ButtonComponent from "components/atoms/Button/Button";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import RenderWithPermissions from "components/atoms/Permission";
import Spacer from "components/atoms/Spacer/Spacer";
import Modal from "components/molecules/Modal";
import { PERMISSION_ENUM } from "constants/permissions";
import { useDeleteUser } from "hooks/query/user/use-delete-user";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import color from "styles/color";
import spacing from "styles/spacing";
import { APIObjectStatus } from "types/API/Shared";

export default function DeleteUser({ name, id, status }: { id: string; name?: string; status?: APIObjectStatus }) {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const { mutate: mutateDeleteUser, isLoading, isSuccess } = useDeleteUser();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const onDelete = () => mutateDeleteUser(id);

  useEffect(() => {
    if (isSuccess) {
      onCloseModal();
      navigate("/accounts/user-management");
    }
  }, [isSuccess]);

  const { btnText, varient, btnColor, backgroundColor } = useMemo(() => {
    if (status === "Pending Delete") {
      return {
        btnText: "Delete",
        varient: "primary",
        btnColor: color.white,
        backgroundColor: color.red,
      };
    }
    return {
      btnText: "Delete Account",
      varient: "secondary",
      backgroundColor: color.white,
      btnColor: color.red,
    };
  }, [status]);

  return (
    <RenderWithPermissions permission={PERMISSION_ENUM.DELETE_USERS}>
      <ButtonComponent
        varient={varient as "primary" | "secondary"}
        iconSize={20}
        color={btnColor}
        backgroundColor={backgroundColor}
        icon={<DeleteIcon />}
        onClick={openModal}
      >
        {btnText}
      </ButtonComponent>
      <Modal
        open={open}
        onClose={onCloseModal}
        title={`Delete User '${name}'`}
        modalBody={<Paragraph>{`Are you sure you want to delete ${name}?`}</Paragraph>}
        modalFooter={
          <>
            <Button
              onClick={onCloseModal}
              color={color.black}
              backgroundColor={color.white}
              marginHorizontalSize="small"
            >
              Cancel
            </Button>
            <Spacer width={spacing.m} />
            <Button
              loading={isLoading}
              color={color.white}
              backgroundColor={color.red}
              icon={<DeleteIcon />}
              onClick={onDelete}
              iconSize={20}
            >
              Delete
            </Button>
          </>
        }
      />
    </RenderWithPermissions>
  );
}
