import { ReactComponent as LogoMarkSVG } from "assets/svg/logo-mark-white.svg";
import { ReactComponent as LogoStablrSVG } from "assets/svg/logo-stablr.svg";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";

interface HalfHalfResponsiveProps {
  children: React.ReactElement;
}

const HalfHalfResponsiveStyled = styled.div`
  @media ${media.desktop} {
    display: flex;
    width: 100%;
    min-height: 100%;
  }
`;

const HalfHalfResponsiveLogoStyled = styled.div`
  position: absolute;
  top: ${spacing.m};
  left: ${spacing.m};
`;

const HalfHalfResponsiveMainStyled = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${media.desktop} {
    flex: 1;
    justify-content: center;
  }
`;

const HalfHalfResponsiveMainContentStyled = styled.div`
  @media ${media.mobile} {
    margin: ${spacing.l};
    margin-top: 150px;
    max-width: 400px;
  }

  @media ${media.desktop} {
    max-width: 400px;
    width: 400px;
    text-align: center;
    margin: ${spacing.l};
  }
`;

const HalfHalfResponsiveSecondaryStyled = styled.div`
  @media ${media.desktop} {
    background: ${color.gradient};
    margin: ${spacing.s};
    flex: 1;
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
      opacity: 0.2;
      padding: ${spacing.xxl};
      max-height: 80vh;
    }
  }
`;

HalfHalfResponsiveComponent.testID = "halfHalfResponsive";

export default function HalfHalfResponsiveComponent({ children }: HalfHalfResponsiveProps) {
  const isMobile = useIsMobile();
  return (
    <HalfHalfResponsiveStyled data-testid={HalfHalfResponsiveComponent.testID}>
      <HalfHalfResponsiveLogoStyled as={LogoStablrSVG} />
      <HalfHalfResponsiveMainStyled>
        <HalfHalfResponsiveMainContentStyled>{children}</HalfHalfResponsiveMainContentStyled>
      </HalfHalfResponsiveMainStyled>
      {isMobile === false && (
        <HalfHalfResponsiveSecondaryStyled>
          <LogoMarkSVG height="100%" width="100%" />
        </HalfHalfResponsiveSecondaryStyled>
      )}
    </HalfHalfResponsiveStyled>
  );
}
