import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "hooks/use-toast";
import { APIWallet } from "types/API/Wallet";

import { useWallets } from "./use-wallets";

export function useCreateWallet() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation(async (wallet: APIWallet) => api.Wallet.createWallet(wallet), {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [useWallets.queryKey] });
      successToast({ message: "Wallet has been created successfully" });
    },
  });
}
