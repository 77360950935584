import { Auth } from "aws-amplify";
import Heading from "components/atoms/Heading/Heading";
import Link from "components/atoms/Inline/Link";
import Loader from "components/atoms/Loader/Loader";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Container from "components/molecules/Container/Container";
import MFAInput from "components/molecules/Form/MFAInput/MFAInput";
import FormLayout from "components/organisms/FormLayout/FormLayout";
import getErrorMessage from "functions/get-error-message";
import { UserInformation } from "pages/SignUp";
import * as React from "react";
import { Field, Form } from "react-final-form";
import color from "styles/color";
import StepTemplate from "templates/StepTemplate/StepTemplate";

interface EmailVerificationProps {
  onContinue: () => void;
  userInformation: UserInformation;
}

EmailVerificationComponent.testID = "EmailVerification";

const RESEND_DELAY = 5000;

export default function EmailVerificationComponent({ onContinue, userInformation }: EmailVerificationProps) {
  const [resendingError, setResendingError] = React.useState<undefined | string>();
  const [resentCode, setResentCode] = React.useState(false);
  const [resending, setResending] = React.useState(false);

  const handleConfirmSignup = async (values: { code: string }) => {
    try {
      await Auth.confirmSignUp(userInformation.email, values.code);
      onContinue();
    } catch (error) {
      return { code: getErrorMessage(error) };
    }
  };

  const handleResendCode = async () => {
    try {
      setResendingError(undefined);
      setResending(true);
      await Auth.resendSignUp(userInformation.email);
      setResentCode(true);
    } catch (error) {
      setResendingError(getErrorMessage(error));
    }
    setResending(false);
    setTimeout(() => setResentCode(false), RESEND_DELAY);
  };

  return (
    <Form onSubmit={handleConfirmSignup}>
      {({ handleSubmit, submitError }) => (
        <StepTemplate>
          <Container as="section" maxWidth={1000} id="title-container">
            <header>
              <Heading as="h1" varient="h2">
                3. Email verification
              </Heading>
            </header>
            <Paragraph>Verify your email address</Paragraph>
            <br />
            <Paragraph>
              Please fill in the 6-digit code sent to <b>{userInformation.email}</b> to verify this email belongs to you
            </Paragraph>
            <FormLayout>
              <>
                <Field
                  name="code"
                  invalid={submitError !== undefined}
                  component={MFAInput}
                  ariaLabel="MFA"
                  onComplete={handleSubmit}
                />
              </>
            </FormLayout>

            <Paragraph>
              It may take a minute to receive your code.
              <br />
              Haven’t received it?{" "}
              <Link to="" onClick={resentCode ? undefined : handleResendCode}>
                Resend a new code
              </Link>
              .
            </Paragraph>
            {resending ? <Loader /> : <></>}
            {resentCode ? <Paragraph color={color.primary}>New code sent to {userInformation.email}</Paragraph> : <></>}
            {resendingError ? <Paragraph color={color.red}>{resendingError}</Paragraph> : <></>}
          </Container>
        </StepTemplate>
      )}
    </Form>
  );
}
