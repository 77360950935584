export default {
  h1: "54px",
  h2: "32px",
  h3: "24px",
  h4: "21px",
  button: "18px",
  input: "16px",
  p: "14px", // TODO discuss with Aaron
  data: "16px", // TODO discuss with Aaron
  small: "12px",
};
