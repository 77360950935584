import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import BottomBar from "components/organisms/BottomBar/BottomBar";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import fontWeight from "styles/fontWeight";
import media from "styles/media";
import spacing from "styles/spacing";
import { AppTransaction } from "types/App/Transaction";

import SuccessfulTransactionTable from "./components/SuccessfulTransactionTable";

interface SuccessScreenProps {
  title: string;
  body: string;
  transaction: AppTransaction;
}

const SuccessScreenStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: ${fontWeight.medium};
    margin-bottom: ${spacing.xs};
  }
`;

const ContentContainerStyled = styled.div`
  flex: 1;

  @media ${media.desktop} {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    min-height: 100%;
  }
`;

const FormContainerStyled = styled.div`
  flex: 1;
`;

const ContentLeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media ${media.desktop} {
    flex-direction: column;
    min-height: 100%;
    flex-grow: 1;
  }
`;

const ContentRightStyled = styled.div`
  flex: 1;
`;

export default function SuccessScreenComponent({ title, body, transaction }: SuccessScreenProps) {
  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const handleOnBack = () => {
    navigate("/dashboard");
  };

  return (
    <SuccessScreenStyled>
      <Heading as="h2" varient="h3">
        {title}
      </Heading>
      <Paragraph>{body}</Paragraph>
      <ContentContainerStyled>
        <ContentLeftStyled>
          <FormContainerStyled>
            <SuccessfulTransactionTable transaction={transaction} />
          </FormContainerStyled>
          <BottomBar
            paddingHorizontal="0px"
            leftElement={
              <Button varient="line" onClick={handleOnBack}>
                Back to Dashboard
              </Button>
            }
          ></BottomBar>
        </ContentLeftStyled>
        {!isMobile && <ContentRightStyled></ContentRightStyled>}
      </ContentContainerStyled>
    </SuccessScreenStyled>
  );
}
