import { useQuery } from "@tanstack/react-query";
import api from "api";
import { AppPaymentListItem } from "types/App/Payment";

usePayments.queryKey = "payments";

export default function usePayments() {
  return useQuery([usePayments.queryKey], async () => api.Payment.getPaymentList() as Promise<AppPaymentListItem[]>, {
    notifyOnChangeProps: "all",
  });
}
