import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";

interface SummaryListProps {
  testID?: string;
  children: React.ReactElement | React.ReactElement[];
}

const SummaryListStyled = styled.div``;

SummaryListComponent.testID = "summaryList";

export default function SummaryListComponent({ children, ...props }: SummaryListProps) {
  return (
    <SummaryListStyled data-testid={composeTestID(SummaryListComponent.testID, props.testID)}>
      {children}
    </SummaryListStyled>
  );
}
