import TableBody from "components/atoms/Table/TableBody";
import TableData from "components/atoms/Table/TableData/TableData";
import TableDataCopyable from "components/atoms/Table/TableDataCopyable";
import TableHeader from "components/atoms/Table/TableHeader/TableHeader";
import TableRow from "components/atoms/Table/TableRow";
import formatCurrency from "functions/format-currency";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";
import { AppTransaction } from "types/App/Transaction";

interface StablrBankDetails {
  BankAccountAddress: string;
  BankAccountName: string;
  BIC: string;
  BankName: string;
  BankAddress: string;
  PostCode: string;
  City: string;
}

interface BankDetailsTableProps {
  transaction: AppTransaction;
  destinationBank: StablrBankDetails;
}

const BankDetailsTableStyled = styled.table`
  width: 100%;
  padding: ${spacing.xs};

  & > tbody > tr:not(:last-child) > td {
    border-bottom: none;
  }

  & > tbody > tr > td {
    width: 60%;
  }

  & > tbody > tr > th {
    color: ${color.black};
  }

  & > tbody > tr > td,
  & > tbody > tr > th {
    padding: ${spacing.xs} ${spacing.s};
    font-weight: ${fontWeight.default};
  }
`;

export default function BankDetailsTableComponent({ transaction, destinationBank }: BankDetailsTableProps) {
  return (
    <BankDetailsTableStyled cellSpacing="0">
      <TableBody>
        <>
          <TableRow>
            <TableHeader>Bank account:</TableHeader>
            <TableDataCopyable value={destinationBank.BankAccountAddress} />
          </TableRow>
          <TableRow>
            <TableHeader>Bank account Name:</TableHeader>
            <TableData>{destinationBank.BankAccountName}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>Payment reference:</TableHeader>
            <TableData>{transaction.Reference}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>Amount:</TableHeader>
            <TableData>{formatCurrency(transaction.FiatAmount)}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>BIC: (optional)</TableHeader>
            <TableData>{destinationBank.BIC}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>Bank Name: (optional)</TableHeader>
            <TableData>{destinationBank.BankName}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>Bank Address: (optional)</TableHeader>
            <TableData>{destinationBank.BankAddress}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>Postal Code: (optional)</TableHeader>
            <TableData>{destinationBank.PostCode}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>City: (optional)</TableHeader>
            <TableData>{destinationBank.City}</TableData>
          </TableRow>
        </>
      </TableBody>
    </BankDetailsTableStyled>
  );
}
