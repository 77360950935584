import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colorStyle from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";

interface InputLinkProps {
  to: string;
  children: string;
}

const InputLinkStyled = styled(Link)`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  color: ${colorStyle.greyDark1};
  line-height: 21px;
  min-height: 21px;
  font-weight: ${fontWeight.regular};
  margin: 0;
  text-align: right;
  text-decoration: underline;
  flex: 1;
`;

InputLinkComponent.testID = "InputLink";

export default function InputLinkComponent({ to, children }: InputLinkProps) {
  return <InputLinkStyled to={to}>{children}</InputLinkStyled>;
}
