import { Menu } from "@headlessui/react";
import Icon from "components/atoms/Icon/Icon";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface MenuListItemProps {
  testID?: string;
  children: string;
  icon?: React.ReactElement;
  onClick: () => void;
}

const MENU_LIST_ITEM_PADDING_HORIZONTAL = "20px";
const MENU_LIST_ITEM_PADDING_VERTICAL = "5px";

const MenuListItemStyled = styled.button`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.button};
  border-radius: 5px;
  background-color: transparent;
  color: ${color.white};
  line-height: 27px;
  padding: ${MENU_LIST_ITEM_PADDING_VERTICAL} ${MENU_LIST_ITEM_PADDING_HORIZONTAL};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  width: 100%;
  text-align: right;

  :hover {
    background-color: ${color.white};
    color: ${color.black};

    & path {
      fill: ${color.black};
    }
  }
`;

const IconStyled = styled.div`
  padding-left: ${spacing.m};
  padding-right: 0;

  & path {
    fill: ${color.white};
  }
`;

MenuListItemComponent.testID = "menuListItem";

export default function MenuListItemComponent({ onClick, children, icon, ...props }: MenuListItemProps) {
  return (
    <Menu.Item
      onClick={onClick}
      as={MenuListItemStyled}
      data-testid={composeTestID(MenuListItemComponent.testID, props.testID)}
    >
      {children}
      {icon && <IconStyled as={Icon}>{icon}</IconStyled>}
    </Menu.Item>
  );
}
