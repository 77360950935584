import { ReactComponent as DeleteIcon } from "assets/icons/delete-outline.svg";
import Button from "components/atoms/Button/Button";
import RenderWithPermissions from "components/atoms/Permission";
import Modal from "components/organisms/Modal";
import { PERMISSION_ENUM } from "constants/permissions";
import { useDeleteAccount } from "hooks/query/bank-account/use-delete-bank-account";
import React, { useEffect, useMemo, useState } from "react";
import color from "styles/color";

export default function DeleteAccount({ title, id, status }: { id: string; title: string; status: string }) {
  const [open, setOpen] = useState<boolean>(false);

  const { mutate: mutateDeleteAccount, isLoading, isSuccess } = useDeleteAccount();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const onDelete = () => mutateDeleteAccount({ accountId: id, title });

  useEffect(() => {
    isSuccess && onCloseModal();
  }, [isSuccess]);

  const { varient, btnColor, backgroundColor } = useMemo(() => {
    if (status === "pending_delete") {
      return {
        varient: "primary",
        backgroundColor: color.red,
        btnColor: color.white,
      };
    }
    return {
      varient: "secondary",
      backgroundColor: color.white,
      btnColor: color.red,
    };
  }, [status]);

  return (
    <RenderWithPermissions permission={PERMISSION_ENUM.DELETE_BANK_ACCOUNTS}>
      <Button
        varient={varient as "primary" | "secondary"}
        color={btnColor}
        backgroundColor={backgroundColor}
        icon={<DeleteIcon />}
        onClick={openModal}
        iconSize={20}
      >
        Delete
      </Button>
      <Modal
        open={open}
        onClose={onCloseModal}
        title={`Delete Bank Account '${title}'`}
        description={`Are you sure you want to delete ${title}?`}
        btnText="Delete"
        marginSize="small"
        btnBackgroundColor={color.red}
        iconElement={<DeleteIcon />}
        btnColor={color.white}
        onConfirm={onDelete}
        loading={isLoading}
      />
    </RenderWithPermissions>
  );
}
