import Heading from "components/atoms/Heading/Heading";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";

interface TableGroupProps {
  children?: React.ReactElement;
  label: string;
  varient?: "primary" | "line";
}

const TableGroupStyled = styled.div`
  & > h1,
  & > h2,
  & > h3,
  & > h4 {
    font-size: ${fontSize.input};
    font-weight: ${fontWeight.regular};
    font-family: ${fontFamily.secondary};
  }

  max-width: 750px;
`;

const TableGroupContainerStyled = styled.div<{ $varient: "primary" | "line" }>`
  ${({ $varient }) =>
    $varient === "primary"
      ? `
background-color: ${color.grey1};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  `
      : `
  background-color: ${color.white};
  border: 1px solid ${color.grey3};
  border-radius: 3px;`}
  min-height: 55px;
  overflow-x: auto;
`;

export default function TableGroupComponent({ label, children, varient = "primary" }: TableGroupProps) {
  return (
    <TableGroupStyled>
      <Heading as="h2" varient="h4">
        {label}
      </Heading>
      <TableGroupContainerStyled $varient={varient}>{children}</TableGroupContainerStyled>
    </TableGroupStyled>
  );
}
