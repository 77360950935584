import { track } from "@amplitude/analytics-browser";
import { ReactComponent as TextIconSVG } from "assets/icons/text-sms.svg";
import { Auth } from "aws-amplify";
import Heading from "components/atoms/Heading/Heading";
// import Link from "components/atoms/Inline/Link";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import FeatureIcon from "components/molecules/FeatureIcon/FeatureIcon";
import MFAInput from "components/molecules/Form/MFAInput/MFAInput";
import composeTestID from "functions/compose-test-id";
import getErrorMessage from "functions/get-error-message";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import HalfHalfResponsive from "templates/HalfHalfResponsive/HalfHalfResponsive";
import { AmplifyUser } from "types/amplify/AmplifyUser";

interface TwoFactorAuthenticationProps {
  testID?: string;
  user: AmplifyUser;
  onComplete: (user: AmplifyUser) => void;
}

interface TwoFactorAuthenticationFormValues {
  code: string;
}

const TwoFactorAuthenticationStyled = styled.main`
  width: 100%;
  min-height: 100%;
`;

TwoFactorAuthenticationComponent.testID = "TwoFactorAuthentication";

export default function TwoFactorAuthenticationComponent({ user, onComplete, ...props }: TwoFactorAuthenticationProps) {
  const navigate = useNavigate();

  const handleOnSubmit = async (values: TwoFactorAuthenticationFormValues) => {
    try {
      const newUser = await Auth.confirmSignIn(user, values.code, "SMS_MFA");

      onComplete(newUser);

      if (newUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        navigate("./new-password");
        return;
      } else {
        logEvent(EVENT_TYPE_ENUM.AUTHENTICATION, "LoginSuccess");
        navigate("/dashboard");
        track("Authentication: Login Success");
        return;
      }
    } catch (error) {
      return { code: getErrorMessage(error) };
    }
  };

  // const handleResend = async () => {
  //   // See here, there is a possible solution but it is a little hacky and bad practice. Needs discussion
  //   // https://github.com/aws-amplify/amplify-js/issues/6676
  // };

  return (
    <TwoFactorAuthenticationStyled data-testid={composeTestID(TwoFactorAuthenticationComponent.testID, props.testID)}>
      <HalfHalfResponsive>
        <Form onSubmit={handleOnSubmit}>
          {({ handleSubmit, submitError }) => (
            <>
              <FeatureIcon icon={<TextIconSVG />} />
              <Heading as="h1" varient="h2">
                2 Factor Authentication
              </Heading>
              <Paragraph>
                Please confirm your sign-in by entering the authentication code sent to{" "}
                <b>{user?.challengeParam?.CODE_DELIVERY_DESTINATION ?? "**********"}</b>
              </Paragraph>
              <Field
                name="code"
                invalid={submitError !== undefined}
                onComplete={handleSubmit}
                component={MFAInput}
              ></Field>

              {/* <Paragraph>
                It may take a minute to receive your code. Haven’t received it?{" "}
                <Link to="" onClick={handleResend}>
                  Resend a new code.
                </Link>
              </Paragraph> */}
            </>
          )}
        </Form>
      </HalfHalfResponsive>
    </TwoFactorAuthenticationStyled>
  );
}
