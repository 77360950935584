import { Auth, Hub } from "aws-amplify";
import UserData, { EMPTY_USER_DATA } from "context/UserData";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CognitoUserData, EmptyCognitoUserData } from "types/amplify/CognitoUserData";

interface CognitoUserSessionContextProps {
  children: React.ReactElement;
}

export default function UserDataContextProvider({ children }: CognitoUserSessionContextProps) {
  const [cognitoData, setCognitoData] = React.useState<CognitoUserData | EmptyCognitoUserData>(EMPTY_USER_DATA);

  const navigate = useNavigate();

  React.useEffect(() => {
    const initialiseData = async () => {
      setCognitoData({ ...(await Auth.currentSession()).getIdToken().decodePayload() } as CognitoUserData);
    };
    initialiseData();

    const listener = Hub.listen("auth", async data => {
      switch (data.payload.event) {
        case "signIn":
          setCognitoData({
            ...(await Auth.currentSession()).getIdToken().decodePayload(),
            data: data?.payload?.data,
          } as CognitoUserData);
          break;
        case "signUp":
          // Do nothing
          break;
        case "signOut":
          setCognitoData(EMPTY_USER_DATA);
          break;
        case "signIn_failure":
          setCognitoData(EMPTY_USER_DATA);
          break;
        case "tokenRefresh":
          setCognitoData({
            ...(await Auth.currentSession()).getIdToken().decodePayload(),
            data: data?.payload?.data,
          } as CognitoUserData);
          break;
        case "tokenRefresh_failure":
          setCognitoData(EMPTY_USER_DATA);
          break;
        case "autoSignIn":
          setCognitoData({
            ...EMPTY_USER_DATA,
            data: data?.payload?.data,
          } as CognitoUserData | EmptyCognitoUserData);
          break;
        case "autoSignIn_failure":
          setCognitoData(EMPTY_USER_DATA);
          navigate("/sign-up");
          // TODO create error toast
          break;
        case "configured":
        // Do nothing
      }
      return () => {
        Hub.remove("auth", listener);
      };
    });
  }, []);

  return <UserData.Provider value={cognitoData}>{children}</UserData.Provider>;
}
