import * as React from "react";
import styled from "styled-components";
import spacing from "styles/spacing";

interface MenuDropDownFeatureListProps {
  children: React.ReactElement | React.ReactElement[];
}

const MenuDropDownFeatureListStyled = styled.div`
  display: flex;
  padding: ${spacing.s} 0;
`;

export default function MenuDropDownFeatureListComponent({ children }: MenuDropDownFeatureListProps) {
  return <MenuDropDownFeatureListStyled>{children}</MenuDropDownFeatureListStyled>;
}
