import { ReactComponent as WizardStepActive } from "assets/svg/WizardStepActive.svg";
import { ReactComponent as WizardStepComplete } from "assets/svg/WizardStepComplete.svg";
import { ReactComponent as WizardStepIncomplete } from "assets/svg/WizardStepIncomplete1.svg";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import composeTestID from "functions/compose-test-id";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled, { useTheme } from "styled-components";
import spacing from "styles/spacing";

interface WizardSideBarNavigationStepProps {
  testID?: string;
  title?: string;
  description?: string;
  type?: "incomplete" | "active" | "complete";
}

export const WIZARD_NAVIGATION_STEP_SVG_SIZE = "38px";
export const WIZARD_NAVIGATION_STEP_OVERFLOW_HEIGHT = spacing.xs;

const WizardSideBarNavigationStepStyled = styled.li`
  display: flex;
  align-items: center;
  position: relative;

  & h1,
  p {
    margin: ${({ theme: { spacing } }) => `${spacing.xxs} 0 ${spacing.xxs} ${spacing.m}`};
  }

  & svg {
    width: ${WIZARD_NAVIGATION_STEP_SVG_SIZE};
    height: ${WIZARD_NAVIGATION_STEP_SVG_SIZE};
  }

  @media ${({ theme: { media } }) => media.desktop} {
    height: calc(${WIZARD_NAVIGATION_STEP_SVG_SIZE} + (${WIZARD_NAVIGATION_STEP_OVERFLOW_HEIGHT} * 2));
  }
`;

const WizardStepTextWrapper = styled.div`
  flex: 1;
`;

WizardSideBarNavigationStepComponent.testID = "WizardSideBarNavigationStep";

export default function WizardSideBarNavigationStepComponent({
  testID,
  title,
  description,
  type = "complete",
}: WizardSideBarNavigationStepProps) {
  const isMobile = useIsMobile();
  const { color } = useTheme();

  return (
    <WizardSideBarNavigationStepStyled data-testid={composeTestID(WizardSideBarNavigationStepComponent.testID, testID)}>
      {type === "complete" ? (
        <WizardStepComplete />
      ) : type === "active" ? (
        <WizardStepActive />
      ) : (
        <WizardStepIncomplete />
      )}
      {!isMobile && (title !== undefined || description != undefined) && (
        <WizardStepTextWrapper>
          <Heading as="h1" varient="h4" color={color.white}>
            {title}
          </Heading>
          <Paragraph color={color.white}>{description}</Paragraph>
        </WizardStepTextWrapper>
      )}
    </WizardSideBarNavigationStepStyled>
  );
}
