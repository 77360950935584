import { useQuery } from "@tanstack/react-query";
import api from "api";

useUsers.queryKey = "users";

export default function useUsers() {
  return useQuery([useUsers.queryKey], async () => api.User.getUserList(), {
    notifyOnChangeProps: "all",
  });
}
