import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import getErrorMessage from "functions/get-error-message";
import { useToast } from "hooks/use-toast";

import { useWallets } from "./use-wallets";

export function useRejectWallet() {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useToast();

  return useMutation(async ({ walletId }: { walletId: string; title: string }) => api.Wallet.rejectWallet(walletId), {
    onSuccess: async (_, { title }) => {
      await queryClient?.invalidateQueries({ queryKey: [useWallets.queryKey] });
      successToast({ message: ` ${title} wallet request rejection successful` });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
