import BalanceInput from "components/molecules/Form/BalanceInput";
import CurrencyInput from "components/molecules/Form/CurrencyInput";
import FieldSet from "components/molecules/Form/FieldSet";
import WalletInput from "components/molecules/Form/WalletInput";
import React from "react";
import { Field } from "react-final-form";

import { SELL_ORDER_SCHEMA } from "./schemaValidation";

export default function SellOrderForm() {
  return (
    <>
      <FieldSet>
        <Field name={SELL_ORDER_SCHEMA.WALLET} compact label="To Wallet Address" component={WalletInput} />
        <Field
          name="currentBalance"
          compact
          disabled
          label="Your Current Balance"
          inputType="EURR"
          component={BalanceInput}
        />
        <Field name="newBalance" disabled compact label="Your New Balance" inputType="EURR" component={BalanceInput} />
      </FieldSet>
      <Field name={SELL_ORDER_SCHEMA.SELL} compact label="To Sell" inputType="EURR" component={CurrencyInput} />
      <Field name="recieve" compact label="You Recieve" readOnly inputType="EUR" component={CurrencyInput} />
      {/* <Field
        name="reference"
        compact
        label="Reference (Optional)"
        placeholder="Payment Reference"
        component={TextInput}
      /> */}
    </>
  );
}
