import React from "react";
import { Route, Routes } from "react-router-dom";

import AddWallet from "./AddWallet";
import EditWallet from "./EditWallet";
import { WalletOverview } from "./WalletOverview";

export default function WalletComponent() {
  return (
    <Routes>
      <Route path="*" element={<WalletOverview />} />
      <Route path="/add" element={<AddWallet />} />
      <Route path="/:walletId/edit" element={<EditWallet />} />
    </Routes>
  );
}
