import { ReactComponent as DeleteIcon } from "assets/icons/delete-outline.svg";
import Button from "components/atoms/Button/Button";
import Modal from "components/organisms/Modal";
import { useDeleteWallet } from "hooks/query/wallet/use-delete-wallet";
import React, { memo, useState } from "react";
import color from "styles/color";

function DeleteWallet({ title, id, isPending }: { id: string; title: string; isPending?: boolean }) {
  const [open, setOpen] = useState<boolean>(false);

  const { mutate: mutateDeleteWallet, isLoading } = useDeleteWallet();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const onDelete = () => mutateDeleteWallet({ walletId: id, title });

  return (
    <>
      <Button
        varient={isPending ? "primary" : "secondary"}
        icon={<DeleteIcon />}
        iconSize={20}
        onClick={openModal}
        color={isPending ? color.white : color.red}
        backgroundColor={isPending ? color.red : undefined}
      >
        Delete
      </Button>
      <Modal
        open={open}
        onClose={onCloseModal}
        title={`Delete Wallet ${title}`}
        btnText="Delete"
        description={`Are you sure you want to delete ${title} from Whitelist?`}
        onConfirm={onDelete}
        iconElement={<DeleteIcon />}
        btnColor={color.white}
        btnBackgroundColor={color.red}
        loading={isLoading}
      />
    </>
  );
}

export default memo(DeleteWallet);
