import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import React from "react";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";

interface ModalProps {
  open: boolean;
  onClose?: () => void;
  title: string;
  modalBody?: React.ReactElement;
  modalFooter?: React.ReactElement;
}

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
`;

const ModalBody = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: ${color.white};
  padding: ${spacing.l};
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
  font-weight: 100;
  pointer-events: auto;
  min-width: 300px;

  @media (${media.desktop}) {
    min-width: 500px;
    padding: ${spacing.l};
  }
`;

const ModalContent = styled.div`
  padding: ${spacing.s} 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${spacing.l};
  flex-flow: wrap;
`;

export default function ModalComponent({ open, onClose, title, modalBody, modalFooter }: ModalProps) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as={Modal} onClose={() => onClose?.()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div />
        </Transition.Child>

        <ModalBody className="fixed inset-0 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel as="div">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                {title}
              </Dialog.Title>
              <ModalContent>{modalBody}</ModalContent>

              <ModalFooter className="mt-4">{modalFooter}</ModalFooter>
            </Dialog.Panel>
          </Transition.Child>
        </ModalBody>
      </Dialog>
    </Transition>
  );
}
