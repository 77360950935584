import React from "react";
import { CognitoUserData, EmptyCognitoUserData } from "types/amplify/CognitoUserData";

export const EMPTY_USER_DATA: EmptyCognitoUserData = {
  aud: undefined,
  auth_time: undefined,
  "cognito:groups": [],
  "cognito:username": undefined,
  "custom:assignedOrganization": undefined,
  "custom:sessionId": undefined,
  "custom:kybStatus": undefined,
  email: undefined,
  email_verified: undefined,
  event_id: undefined,
  exp: undefined,
  iat: undefined,
  iss: undefined,
  jti: undefined,
  name: undefined,
  origin_jti: undefined,
  phone_number: undefined,
  phone_number_verified: undefined,
  sub: undefined,
  token_use: undefined,
  data: undefined,
};

const UserData = React.createContext(EMPTY_USER_DATA as CognitoUserData | EmptyCognitoUserData);

export default UserData;
