import blackLoaderData from "assets/lottie/loader-simple-black.json";
import defaultLoaderData from "assets/lottie/loader-simple-primary.json";
import whiteLoaderData from "assets/lottie/loader-simple-white.json";
import * as React from "react";
import Lottie from "react-lottie";

interface LoaderProps {
  varient?: "white" | "default" | "black";
  size?: string;
}

const DEFAULT_SIZE = "40px";

export default function LoaderComponent({ varient = "default", size = DEFAULT_SIZE }: LoaderProps) {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData:
          varient === "default" ? defaultLoaderData : varient === "white" ? whiteLoaderData : blackLoaderData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      width={size}
      height={size}
    ></Lottie>
  );
}
