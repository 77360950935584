import Input from "components/atoms/Form/Input/Input";
import InputAppendType from "components/atoms/Form/InputAppendType";
import InputNote from "components/atoms/Form/InputNote/InputNote";
import InputType from "components/atoms/Form/InputType";
import Label from "components/atoms/Form/Label/Label";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";

interface CurrencyInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testID?: string;
  icon?: React.ReactElement;
  note?: string;
  compact?: boolean;
  inputType?: string;
}
const MAX_INPUT_WIDTH = "500px";

const CurrencyInputStyled = styled.div`
  width: 100%;
  max-width: ${MAX_INPUT_WIDTH};
`;

const InputGroupStyled = styled.div`
  display: flex;

  & > div:first-child {
    width: 80px;
  }

  & > *:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    width: unset;
    flex: 1;
  }
`;

CurrencyInputComponent.testID = "CurrencyInput";

export default function CurrencyInputComponent({
  input: { ...inputProps },
  label,
  note,
  inputType,
  compact = false,
  ...props
}: CurrencyInputProps) {
  const error = props.meta.touched && props.meta.error;

  return (
    <CurrencyInputStyled data-testid={composeTestID(CurrencyInputComponent.testID, props.testID)}>
      <Label htmlFor={inputProps.name}>{label}</Label>
      <InputGroupStyled>
        <InputType>{inputType ?? ""}</InputType>
        <Input
          {...inputProps}
          {...props}
          textAlign="right"
          type={"number"}
          appendElement={<InputAppendType>{inputType ?? ""}</InputAppendType>}
        ></Input>
      </InputGroupStyled>
      {(!compact || note?.trim() || error) && (
        <InputNote htmlFor={inputProps.name} color={error ? color.red : undefined}>
          {error ? error : note}
        </InputNote>
      )}
    </CurrencyInputStyled>
  );
}
