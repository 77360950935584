import Button from "components/atoms/Button/Button";
import React, { memo } from "react";

function SubmitButton({ loading, buttonText }: { loading: boolean; buttonText: string }) {
  return (
    <Button type="submit" loading={loading}>
      {buttonText}
    </Button>
  );
}

export default memo(SubmitButton);
