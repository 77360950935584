import { ReactComponent as EmailSVG } from "assets/icons/alternate-email.svg";
import { ReactComponent as IconBackIOS } from "assets/icons/icon-back-ios.svg";
import { ReactComponent as PersonSVG } from "assets/icons/person.svg";
import { ReactComponent as PhoneSVG } from "assets/icons/phone.svg";
import { Auth } from "aws-amplify";
import Badge from "components/atoms/Badge";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Container from "components/molecules/Container/Container";
import FormSummaryTable from "components/molecules/FormSummaryTable/FormSummaryTable";
import BottomBar from "components/organisms/BottomBar/BottomBar";
import SummaryList from "components/organisms/SummaryList/SummaryList/SummaryList";
import SummaryListItem from "components/organisms/SummaryList/SummaryListItem/SummaryListItem";
import useUserData from "hooks/context/use-user-data";
import * as React from "react";
import StepTemplate from "templates/StepTemplate/StepTemplate";

import parseTransactionBadgeText from "../functions/parse-transaction-badge-text";
import parseBadgeVarient from "../functions/parse-transaction-badge-varient";

interface SummaryAndSignUpProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SummaryAndSignUpComponent({ onBack, onContinue }: SummaryAndSignUpProps) {
  const user = useUserData();
  const [currentKYBStatus, setCurrentKYBStatus] = React.useState<undefined | string>(user?.["custom:kybStatus"] ?? "");

  React.useEffect(() => {
    const getCurrentUserAttributes = async () => {
      try {
        const { attributes } = await Auth.currentUserInfo();
        setCurrentKYBStatus(attributes?.["custom:kybStatus"] ?? "");
      } catch (er) {
        setCurrentKYBStatus("");
      }
    };
    getCurrentUserAttributes();
  }, []);

  return (
    <StepTemplate
      bottomBarElement={
        <BottomBar
          leftElement={
            <Button
              varient="secondary"
              onClick={onBack}
              layout="icon-left"
              mobileLayout="icon-only"
              icon={<IconBackIOS />}
            >
              Back
            </Button>
          }
          rightElement={
            currentKYBStatus === "VALIDATED" ? (
              <Button varient="primary" onClick={onContinue}>
                Finish
              </Button>
            ) : undefined
          }
        />
      }
    >
      <>
        <Container as="section" maxWidth={822} id="title-container">
          <header>
            <Heading as="h1" varient="h2">
              5. Summary and Sign Up
            </Heading>
          </header>
          <Paragraph>Summary of the previous steps</Paragraph>
          <SummaryList>
            <SummaryListItem
              title={
                <>
                  1. Company Verification{" "}
                  <Badge
                    varient={parseBadgeVarient(currentKYBStatus)}
                    text={parseTransactionBadgeText(currentKYBStatus)}
                  ></Badge>
                </>
              }
            ></SummaryListItem>
            <SummaryListItem title="2. User Information" defaultOpen>
              <FormSummaryTable
                data={[
                  {
                    label: "Email Address",
                    icon: <EmailSVG />,
                    value: user?.email,
                  },
                  {
                    label: "Full Name",
                    icon: <PersonSVG />,
                    value: user?.name,
                  },
                  {
                    label: "Mobile Number",
                    icon: <PhoneSVG />,
                    value: user?.phone_number,
                  },
                ]}
              />
            </SummaryListItem>
            <SummaryListItem title="3. Create Password" />
            <SummaryListItem title="4. Two Factor Authentication" />
          </SummaryList>
        </Container>
      </>
    </StepTemplate>
  );
}
