import api from "api";
import { APIPaymentListItem } from "types/API/Payment";

// import Connection from "./Connection";
import { MOCK_PAYMENT_DATA } from "./mocks/mock-payment-instructions";

// const getPaymentList = (): Promise<APIPaymentListItem[]> => Connection.get("/payment/list");

const getPaymentList = (): Promise<APIPaymentListItem[]> => api.Mock.mockRequest("/payment/list", MOCK_PAYMENT_DATA);

// const getPaymentFile = (paymentId: string): Promise<string> => Connection.get(`/payment/${paymentId}`);

const getPaymentFile = (transactionID: string): Promise<string> => {
  return api.Mock.mockRequest(`/payment/${transactionID}`, "http://example.com");
};

export default {
  getPaymentList,
  getPaymentFile,
};
