import { ReactComponent as LibraryBookIcon } from "assets/icons/library-books.svg";
import CardEmpty from "components/molecules/Card/CardEmpty";
import CardError from "components/molecules/Card/CardError";
import CardLoader from "components/molecules/Card/CardLoader";
import HeaderBackNav from "components/molecules/HeaderBackNav";
import { PERMISSION_ENUM } from "constants/permissions";
import getErrorMessage from "functions/get-error-message";
import withPermission from "hocs/permission";
import usePayments from "hooks/query/payment/use-payments";
import React from "react";

import PaymentTableComponent from "./PaymentTable";

function PaymentsView() {
  const { data: payments, error, isLoading, refetch } = usePayments();
  return (
    <>
      <HeaderBackNav title={`Payment Instructions ${payments?.length ? `(${payments?.length})` : ""}`} />
      {error ? (
        <CardError body={getErrorMessage(error)} title="Can not load Payment Instructions" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !payments?.length ? (
        <CardEmpty
          iconElement={<LibraryBookIcon />}
          title="No Payment Instructions found"
          body="There are no Payment Instructions found at the moment"
        />
      ) : (
        <PaymentTableComponent data={payments} />
      )}
    </>
  );
}

export default withPermission(PERMISSION_ENUM.VIEW_PAYMENT_INSTRUCTIONS)(PaymentsView);
