import * as React from "react";
import styled from "styled-components";
import spacing from "styles/spacing";

interface BottomNavigationProps {
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
  paddingHorizontal?: string;
  rowReversed?: boolean;
}

const BottomNavigationStyled = styled.div<{ $paddingHorizontal?: string; rowReversed?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${spacing.l};
  ${({ $paddingHorizontal }) =>
    $paddingHorizontal !== undefined ? `padding-left: ${$paddingHorizontal}; padding-right: ${$paddingHorizontal}` : ""}
`;

const LeftNavigationStyled = styled.div``;

const RightNavigationStyled = styled.div``;

export default function BottomNavigationComponent({
  leftElement,
  rightElement,
  paddingHorizontal,
  rowReversed,
}: BottomNavigationProps) {
  return (
    <BottomNavigationStyled $paddingHorizontal={paddingHorizontal} rowReversed={rowReversed}>
      <LeftNavigationStyled>{leftElement}</LeftNavigationStyled>
      <RightNavigationStyled>{rightElement}</RightNavigationStyled>
    </BottomNavigationStyled>
  );
}
