import api from "api";
import Constants from "constants/index";
import { APIDashboard } from "types/API/Dashboard";

import { MOCK_DASHBOARD, MOCK_EMPTY_DASHBOARD } from "./mocks/mock-dashboard";
// import Connection from "./Connection";

// const getDashboard = (): Promise<APICompanyInfo> => Connection.get("/dashboard");

const getDashboard = (): Promise<APIDashboard> =>
  api.Mock.mockRequest("/dashboard", Constants.disableBuySell ? MOCK_EMPTY_DASHBOARD : MOCK_DASHBOARD);

export default {
  getDashboard,
};
