import Alert from "components/atoms/Alert";
import Badge from "components/atoms/Badge";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Spacer from "components/atoms/Spacer/Spacer";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import Modal from "components/molecules/Modal";
import TableGroup from "components/molecules/Table/TableGroup";
import BottomBar from "components/organisms/BottomBar/BottomBar";
import { FORM_ERROR } from "final-form";
import getErrorMessage from "functions/get-error-message";
import useConfirmTransaction from "hooks/query/transaction/use-confirm-transaction";
import * as React from "react";
import { Field, Form } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";
import { AppTransaction } from "types/App/Transaction";

import OurWalletDetailsTable from "./components/OurWalletDetailsTable";

interface PayPendingScreenProps {
  title: string;
  body: string;
  transaction: AppTransaction;
}

interface PayPendingValues {
  transactionHash: string;
}

const STABLR_WALLET = {
  WalletOwner: "StablR B.V",
  WalletAddress: "TESTbHfnpaZjKFvyi1okTjJJusN455paPH",
};

const PayPendingScreenStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  & > h2 {
    font-weight: ${fontWeight.medium};
    margin-bottom: ${spacing.xs};
  }
`;

const PayPendingContainerStyled = styled.div`
  flex: 1;
`;

export default function PayPendingScreenComponent({ title, body, transaction }: PayPendingScreenProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { mutateAsync: mutateConfirmTransactionAsync } = useConfirmTransaction();

  const handleTransactionSubmit = async ({ transactionHash }: PayPendingValues) => {
    try {
      await mutateConfirmTransactionAsync({ id: transaction.Id, transactionHash });
      setIsModalOpen(false);
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  return (
    <PayPendingScreenStyled>
      <PayPendingContainerStyled>
        <Heading as="h2" varient="h3">
          <>
            {title}
            <Badge varient="info" text="Action Required"></Badge>
          </>
        </Heading>
        <Paragraph>{body}</Paragraph>
        <Spacer height={spacing.s} />
        <TableGroup varient="line" label="Our Wallet Details">
          <OurWalletDetailsTable transaction={transaction} destinationWallet={STABLR_WALLET} />
        </TableGroup>
        <Spacer height={spacing.m} />
        <Alert
          iconColor={color.yellow}
          message="Use the exact wallet address provided here when transferring the amount of EURR. This will prevent any loss of
          tokens and speeds up the redemption."
        ></Alert>
      </PayPendingContainerStyled>
      <BottomBar
        paddingHorizontal="0"
        rightElement={
          <>
            <Button
              varient="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              EURR Transferred
            </Button>
          </>
        }
      ></BottomBar>
      <Form onSubmit={handleTransactionSubmit}>
        {({ handleSubmit, submitting, submitError }) => (
          <Modal
            open={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
            title="Transfer confirmation"
            modalBody={
              <>
                <Paragraph>Please enter the transaction url of the successful EURR transfer</Paragraph>
                <Field name="transactionHash" component={TextInput} placeholder="0xb5c8bd94..." />
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
              </>
            }
            modalFooter={
              <>
                <Button
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                  varient="primary"
                  color={color.black}
                  backgroundColor={color.white}
                >
                  Cancel
                </Button>
                <Spacer width={spacing.s} />
                <Button type="submit" loading={submitting} onClick={handleSubmit} varient="primary">
                  Confirm
                </Button>
              </>
            }
          />
        )}
      </Form>
    </PayPendingScreenStyled>
  );
}
