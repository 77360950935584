import { Listbox as HeadlessComboBox } from "@headlessui/react";
import { ReactComponent as CheckSVG } from "assets/icons/check.svg";
import { ReactComponent as KeyboardArrowDownSVG } from "assets/icons/keyboard-arrow-down.svg";
import Icon from "components/atoms/Icon/Icon";
import Loader from "components/atoms/Loader/Loader";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import spacing from "styles/spacing";

export interface ComboBoxComplexOption {
  id: string;
  value: string;
  label: string;
}

interface ComboBoxProps extends Omit<React.InputHTMLAttributes<HTMLSelectElement>, "onChange"> {
  value: string[];
  options: (string | ComboBoxComplexOption)[];
  onChange?: (value: string[]) => void;
  placeholder?: string;
  icon?: React.ReactElement;
  name?: string;
  loading?: boolean;
}

const INPUT_ICON_SIZE = 20;

const HeadlessComboBoxStyled = styled.div`
  border: solid ${color.greyDark1} 1px;
  border-radius: 3px;
  width: 100%;
  display: flex;
  position: relative;

  &.disabled {
    background-color: ${color.grey1};

    & button {
      background-color: ${color.grey1};
      cursor: default;
    }
  }
`;

const HeadlessComboBoxInputStyled = styled.div`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${color.black};
  padding: ${spacing.m} 0 ${spacing.m} ${spacing.s};
  border: none;
  min-height: 58px;

  ::placeholder {
    color: ${color.greyDark1};
  }
`;

const HeadlessComboBoxButtonStyled = styled.button`
  border: none;
  background-color: ${color.white};
  padding: 0 ${spacing.m} 0 ${spacing.xs};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeadlessComboBoxOptionsStyled = styled.ul`
  width: 100%;
  top: calc(100% + ${spacing.xs});
  left: 0;
  position: absolute;
  min-height: 10px;
  border-radius: 3px;
  background-color: ${color.white};
  border: solid ${color.greyDark1} 1px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 300px;
  z-index: 100;
`;

const HeadlessComboBoxOptionStyled = styled.li(
  ({ $active }: { $active: boolean }) => `
  width: 100%;
  background-color: ${color.white};
  padding: ${spacing.s} ${spacing.m} ${spacing.s} ${spacing.l};
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${color.black};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${$active === true ? `background-color: ${color.primary}; color:${color.white};` : ""}
  & > *:first-child {
    position: absolute;
    left: ${spacing.s};
  }
`
);

const IconStyled = styled.div`
  padding: 0 0 0 ${spacing.m};
  display: flex;
  align-items: center;
`;

const PlaceholderStyled = styled.p`
  color: ${color.greyDark1};
  margin-left: ${spacing.s};
`;

ComboBoxComponent.testID = "ComboBox";

export default function ComboBoxComponent({
  icon,
  value = [],
  options,
  onChange,
  name,
  placeholder,
  disabled,
  loading = false,
}: ComboBoxProps) {
  if (typeof value == "string") value = [];

  const handleOnChange = (value: string[] | ComboBoxComplexOption[]) => {
    const selectedValues: string[] = value?.map(val => (typeof val === "string" ? val : val?.id));
    onChange && onChange(selectedValues);
  };

  return (
    <HeadlessComboBoxStyled className={disabled === true ? "disabled" : ""}>
      <HeadlessComboBox disabled={disabled} value={value} onChange={handleOnChange} multiple name={name}>
        {icon && (
          <IconStyled size={INPUT_ICON_SIZE} as={Icon}>
            {icon}
          </IconStyled>
        )}
        <HeadlessComboBox.Button as={HeadlessComboBoxButtonStyled}>
          {value?.length ? (
            <HeadlessComboBoxInputStyled>{value.join(", ")}</HeadlessComboBoxInputStyled>
          ) : (
            <PlaceholderStyled>{placeholder}</PlaceholderStyled>
          )}
          {!disabled && !loading ? (
            <Icon>
              <KeyboardArrowDownSVG />
            </Icon>
          ) : loading ? (
            <HeadlessComboBoxButtonStyled disabled>
              <Loader size={"27px"} varient="default" />
            </HeadlessComboBoxButtonStyled>
          ) : (
            <></>
          )}
        </HeadlessComboBox.Button>

        <HeadlessComboBox.Options as={HeadlessComboBoxOptionsStyled}>
          {options?.map((option: string | ComboBoxComplexOption) => {
            const key = typeof option === "string" ? option : option.id;
            const label = typeof option === "string" ? option : option.label;
            return (
              <HeadlessComboBox.Option as={React.Fragment} key={key} value={option}>
                {({ selected, active }) => {
                  return (
                    <HeadlessComboBoxOptionStyled $active={active}>
                      <Icon color={active ? color.white : color.black}>
                        {(selected || value.includes(label)) && <CheckSVG />}
                      </Icon>

                      {label}
                    </HeadlessComboBoxOptionStyled>
                  );
                }}
              </HeadlessComboBox.Option>
            );
          })}
        </HeadlessComboBox.Options>
      </HeadlessComboBox>
    </HeadlessComboBoxStyled>
  );
}
