import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as NotAvailableBuy } from "assets/svg/NotAvailableBuySell.svg";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import IconStyled from "components/atoms/Icon/Icon";
import CardData from "components/molecules/Card/CardData";
import CardWrapper from "components/molecules/Card/CardWrapper";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

const BalanceCardStyled = styled(CardWrapper)`
  background: ${color.mauve};
  padding: ${spacing.m} ${spacing.l};
  align-items: center;
  position: relative;
  display: flex;

  & h4 {
    margin: 0;
    word-spacing: 2px;

    & > span {
      color: ${color.purple};
    }
  }

  & > button {
    position: absolute;
    right: 0;
    top: 4px;
  }
`;

const IconContainer = styled(IconStyled)`
  width: 50px;
  border-radius: 50%;
  background-color: ${color.purple};
  height: 50px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  margin-right: ${spacing.s};
`;

export default function NotAvailableBuySell() {
  const [showNotice, setNoticeVisibility] = React.useState(true);

  if (!showNotice) return <></>;

  return (
    <BalanceCardStyled>
      <Button
        layout="icon-only"
        backgroundColor={color.transparent}
        icon={<CloseIcon />}
        iconSize={22}
        iconColor={color.greyDark1}
        onClick={() => setNoticeVisibility(false)}
      />
      <IconContainer size={24} color={color.white}>
        <NotAvailableBuy />
      </IconContainer>
      <CardData
        label="EURR"
        data={
          <Heading as="h4">
            Buying & Selling <span>{" EURR "}</span> not available yet.
          </Heading>
        }
      />
    </BalanceCardStyled>
  );
}
