import Link from "components/atoms/Inline/Link";
import TableBody from "components/atoms/Table/TableBody";
import TableData from "components/atoms/Table/TableData/TableData";
import TableHeader from "components/atoms/Table/TableHeader/TableHeader";
import TableRow from "components/atoms/Table/TableRow";
import format from "date-fns/format";
import formatCurrency from "functions/format-currency";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";
import { AppTransaction } from "types/App/Transaction";

interface SuccessfulTransactionTableProps {
  transaction: AppTransaction;
}

const SuccessfulTransactionTableStyled = styled.table`
  width: 100%;
  padding: ${spacing.m} 0;

  & > tbody > tr:not(:last-child) > td,
  & > tbody > tr:not(:last-child) > th {
    border-bottom: 1px solid ${color.grey3};
  }

  & > tbody > tr > td {
    width: 60%;
  }

  & > tbody > tr > th {
    color: ${color.black};
  }

  & > tbody > tr > td,
  & > tbody > tr > th {
    padding: ${spacing.m} 0;
    font-weight: ${fontWeight.default};
  }
`;

export default function SuccessfulTransactionTableComponent({ transaction }: SuccessfulTransactionTableProps) {
  return (
    <SuccessfulTransactionTableStyled cellSpacing="0">
      <TableBody>
        <>
          <TableRow>
            <TableHeader>Date:</TableHeader>
            <TableData>{format(new Date(transaction.CreatedAt), "dd/mm/yyyy")}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>Recieved:</TableHeader>
            <TableData>{formatCurrency(transaction.FiatAmount)}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>Paid:</TableHeader>
            <TableData>{formatCurrency(transaction.DigitalAmount)}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>From Wallet Address:</TableHeader>
            <TableData>{transaction.WalletAddress}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>View Transaction:</TableHeader>
            <TableData>
              {transaction.EtherscanURL && (
                <Link target="_blank" external to={transaction.EtherscanURL}>
                  Etherscan
                </Link>
              )}
            </TableData>
          </TableRow>
        </>
      </TableBody>
    </SuccessfulTransactionTableStyled>
  );
}
