import Avatar from "components/atoms/Avatar/Avatar";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface UserDropDownInfoProps {
  name?: string;
  email?: string;
}

const UserDropDownInfoStyled = styled.span`
  text-align: center;
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.data};
  color: ${color.black};
  margin: ${spacing.xs};
  display: block;
  font-weight: ${fontWeight.regular};
  line-height: 24px;
`;

const DataContainerStyled = styled.div`
  padding: ${spacing.xs} 0;
`;

const UserDropDownInfoContainerStyled = styled.div`
  padding: ${spacing.m} 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default function UserDropDownInfoComponent({ name, email }: UserDropDownInfoProps) {
  return (
    <UserDropDownInfoContainerStyled>
      <Avatar name={name} size="large" />
      <DataContainerStyled>
        <UserDropDownInfoStyled>{name}</UserDropDownInfoStyled>
        <UserDropDownInfoStyled>{email}</UserDropDownInfoStyled>
      </DataContainerStyled>
    </UserDropDownInfoContainerStyled>
  );
}
