import { ReactComponent as DownloadSVG } from "assets/icons/download.svg";
import { ReactComponent as IconBackIOSSVG } from "assets/icons/icon-back-ios.svg";
import { ReactComponent as TransactionsSVG } from "assets/icons/transactions.svg";
import Alert from "components/atoms/Alert";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Spacer from "components/atoms/Spacer/Spacer";
import TableGroup from "components/molecules/Table/TableGroup";
import BottomBar from "components/organisms/BottomBar/BottomBar";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import color from "styles/color";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";
import { AppTransaction } from "types/App/Transaction";

import BankDetailsTable from "./components/BankDetailsTable";
import YourBankAccountTable from "./components/YourBankAccountTable";

const DESTINATION_BANK = {
  BankAccountAddress: "NLXXABNAXXXX321789",
  BankAccountName: "StablR B.V.",
  BIC: "12345",
  BankName: "ABN Amro - Test",
  BankAddress: "123 Test Street",
  PostCode: "1234TS",
  City: "Amsterdam",
};

interface PaymentInstructionProps {
  title: string;
  body: string;
  onBack: () => void;
  transaction: AppTransaction;
}

const PaymentInstructionContainerStyled = styled.div`
  flex: 1;
`;

const PaymentScreenStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h2 {
    font-weight: ${fontWeight.medium};
    margin-bottom: ${spacing.xs};
  }
`;

export default function PaymentInstructionComponent({ title, body, onBack, transaction }: PaymentInstructionProps) {
  const navigate = useNavigate();

  const handleOnOpenTransactions = () => {
    navigate("/transactions");
  };

  const handleOnOpenPaymentInstruction = () => {
    // TODO add payment pdf
    window.open("https://www.ethereum.org");
  };

  return (
    <PaymentScreenStyled>
      <Heading as="h2" varient="h3">
        {title}
      </Heading>
      <Paragraph>{body}</Paragraph>
      <PaymentInstructionContainerStyled>
        <Spacer height={spacing.s} />
        <TableGroup label="Your Bank Account">
          <YourBankAccountTable transaction={transaction} />
        </TableGroup>
        <Spacer height={spacing.m} />
        <TableGroup label="Our Bank Details">
          <BankDetailsTable transaction={transaction} destinationBank={DESTINATION_BANK} />
        </TableGroup>
        <Spacer height={spacing.l} />
        <Alert
          iconColor={color.yellow}
          message="Use the exact payment reference in your wire transfer. If your reference deviates, your payment will be
              refunded."
        ></Alert>
      </PaymentInstructionContainerStyled>
      <BottomBar
        paddingHorizontal="0"
        leftElement={
          <Button varient="secondary" color={color.black} icon={<IconBackIOSSVG />} onClick={onBack}>
            Back
          </Button>
        }
        rightElement={
          <>
            <Button
              varient="line"
              iconSize={22}
              width={"100%"}
              color={color.black}
              icon={<DownloadSVG />}
              onClick={handleOnOpenPaymentInstruction}
            >
              Payment Instruction
            </Button>
            <Spacer height={spacing.m} />
            <Button width={"100%"} varient="primary" icon={<TransactionsSVG />} onClick={handleOnOpenTransactions}>
              Open Transactions
            </Button>
          </>
        }
      ></BottomBar>
    </PaymentScreenStyled>
  );
}
