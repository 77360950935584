import Input from "components/atoms/Form/Input/Input";
import InputAppendType from "components/atoms/Form/InputAppendType";
import InputNote from "components/atoms/Form/InputNote/InputNote";
import Label from "components/atoms/Form/Label/Label";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";

interface BalanceInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testID?: string;
  icon?: React.ReactElement;
  note?: string;
  compact?: boolean;
  inputType?: string;
}
const MAX_INPUT_WIDTH = "500px";

const BalanceInputStyled = styled.div`
  width: 100%;
  max-width: min(${MAX_INPUT_WIDTH}, 100%);
`;

const InputGroupStyled = styled.div`
  & > div {
    background: ${color.gradient};
    border: none;
  }

  & > div > input,
  & > div > input::placeholder,
  & > div > input:disabled {
    background: none;
    color: ${color.white};
  }
`;

BalanceInputComponent.testID = "BalanceInput";

export default function BalanceInputComponent({
  input: { ...inputProps },
  label,
  note,
  inputType,
  compact = false,
  ...props
}: BalanceInputProps) {
  const error = props.meta.touched && props.meta.error;

  return (
    <BalanceInputStyled data-testid={composeTestID(BalanceInputComponent.testID, props.testID)}>
      <Label htmlFor={inputProps.name}>{label}</Label>
      <InputGroupStyled>
        <Input
          {...inputProps}
          {...props}
          textAlign="right"
          type={"text"}
          placeholder="-"
          appendElement={<InputAppendType color={color.white}>{inputType ?? ""}</InputAppendType>}
        ></Input>
      </InputGroupStyled>
      {(!compact || note?.trim() || error) && (
        <InputNote htmlFor={inputProps.name} color={error ? color.red : undefined}>
          {error ? error : note}
        </InputNote>
      )}
    </BalanceInputStyled>
  );
}
