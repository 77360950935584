import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import getErrorMessage from "functions/get-error-message";
import { useToast } from "hooks/use-toast";

import { useBankAccounts } from "./use-bank-accounts";

export function useDeleteAccount() {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useToast();
  return useMutation({
    mutationFn: async ({ accountId }: { accountId: string; title: string }) => api.BankAccount.deleteAccount(accountId),
    onSuccess: async (_, { title }) => {
      await queryClient?.invalidateQueries({ queryKey: [useBankAccounts.queryKey] });
      successToast({ message: `${title} account has been deleted successfully.` });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
