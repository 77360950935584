import Button from "components/atoms/Button/Button";
import React, { memo } from "react";

const SubmitButton = ({ buttonText, disabled }: { buttonText: string; disabled: boolean }) => {
  return (
    <Button type="submit" disabled={disabled}>
      {buttonText}
    </Button>
  );
};

export default memo(SubmitButton);
