import { useQuery } from "@tanstack/react-query";
import api from "api";
import useUserData from "hooks/context/use-user-data";

useCompanyInfo.queryKey = "companyInfo";

useCompanyInfo.queryKey = "companyDetails";
export default function useCompanyInfo() {
  const user = useUserData();
  return useQuery(
    [useCompanyInfo.queryKey, user["custom:assignedOrganization"]],
    async () => api.Company.getCompanyDetails(user["custom:assignedOrganization"] ?? ""),
    {
      notifyOnChangeProps: "all",
    }
  );
}
