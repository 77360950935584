import { useMutation } from "@tanstack/react-query";
import api from "api";
import { APIPostBuyOrder } from "api/Transaction";

export function usePostBuyOrder() {
  return useMutation([], async (buyPayload: APIPostBuyOrder) => api.Transaction.postBuyOrder(buyPayload));
}

// export function usePostBuyOrder() {
//   return useMutation([], async (buyPayload: APIPostBuyOrder) =>
//     api.Mock.mockRequest<APIPostBuyOrderResponse>(buyPayload, {
//       Entries: [
//         {
//           EventId: "1",
//         },
//       ],
//       FailedEntryCount: 0,
//     })
//   );
// }
