import { APIPostBuyOrderResponse } from "api/Transaction";
import { APITransaction, APITransactionListItem } from "types/API/Transaction";

export const MOCK_BUY_TRANSACTION: APITransaction = {
  Id: "12345-BUY",
  Type: "Buy",
  FiatAmount: {
    Amount: 1010000,
    Denominator: ".",
  },
  DigitalAmount: {
    Amount: 1000000,
    Denominator: ".",
  },
  CreatedAt: "2022-12-13T10:12:10+0000",
  Reference: "1736284",
  WalletAddress: "abcdefghijxyz",
  Status: "PENDING",
  // End of BE Confirmed
  OrderReference: "Order reference from user", // Entered By User
  PaymentReference: "17362734", // Used to make payment
  WalletName: "Wallet A",
  BankAccountName: "Bank Account A",
  BankAccountAddress: "NL ABNA 1836234 847",
  FiatCurrency: "EUR",
  EtherscanURL: "https://www.stablr.com",
};

export const MOCK_BUY_ORDER: APIPostBuyOrderResponse = {
  Entries: [
    {
      EventId: "1",
    },
  ],
  FailedEntryCount: 0,
};

export const MOCK_TRANSACTIONS: APITransactionListItem[] = [
  {
    Id: "1",
    Type: "Buy",
    FiatAmount: {
      Amount: 1010000,
      Denominator: ".",
    },
    DigitalAmount: {
      Amount: 1000000,
      Denominator: ".",
    },
    CreatedAt: new Date().toISOString(),
    Reference: "API BE reference",
    WalletAddress: "abcdefghijxyz",
    Status: "PENDING",
    OrderReference: "Order reference from user", // Entered By User
    PaymentReference: "17362734", // Used to make payment
    WalletName: "Wallet A",
    BankAccountName: "Bank Account A",
    BankAccountAddress: "NL ABNA 1836234 847",
    FiatCurrency: "EUR",
  },
  {
    Id: "2",
    Type: "Sell",
    FiatAmount: {
      Amount: 1010000,
      Denominator: ".",
    },
    DigitalAmount: {
      Amount: 1000000,
      Denominator: ".",
    },
    CreatedAt: new Date().toISOString(),
    Reference: "API BE reference",
    WalletAddress: "abcdefghijxyz",
    Status: "CONFIRMED",
    OrderReference: "Order reference from user", // Entered By User
    PaymentReference: "17362734", // Used to make payment
    WalletName: "Wallet A",
    BankAccountName: "Bank Account A",
    BankAccountAddress: "NL ABNA 1836234 847",
    FiatCurrency: "EUR",
  },
  {
    Id: "12345-BUY",
    Type: "Buy",
    FiatAmount: {
      Amount: 40000023,
      Denominator: ",",
    },
    DigitalAmount: {
      Amount: 40000000,
      Denominator: ",",
    },
    CreatedAt: new Date().toISOString(),
    Reference: "API BE reference",
    WalletAddress: "abcdefghijxyz",
    Status: "REJECTED",
    OrderReference: "Order reference from user", // Entered By User
    PaymentReference: "17362734", // Used to make payment
    WalletName: "Wallet A",
    BankAccountName: "Bank Account A",
    BankAccountAddress: "NL ABNA 1836234 847",
    FiatCurrency: "EUR",
  },
];
