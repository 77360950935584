import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import Button from "components/atoms/Button/Button";
import useDownloadPayment from "hooks/query/payment/use-download-payment";
import { useIsMobile } from "hooks/use-is-mobile";
import React from "react";
import color from "styles/color";

export default function PaymentDownload({ transactionId }: { transactionId: string }) {
  const { isFetching, refetch } = useDownloadPayment(transactionId);

  const isMobile = useIsMobile();

  return (
    <Button
      icon={<DownloadIcon />}
      iconSize={20}
      varient={isMobile ? "gradient" : "line"}
      borderColor={isMobile ? color.transparent : color.grey4}
      backgroundColor={isMobile ? color.purple : color.white}
      iconColor={isMobile ? color.white : color.purple}
      layout={isMobile ? "icon-only" : undefined}
      marginHorizontalSize="small"
      size="small"
      disabled={isFetching}
      onClick={() => refetch()}
    >
      Download
    </Button>
  );
}
