import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

interface SVGFeatureProps {
  testID?: string;
  svg: React.ReactElement;
  contentElement: React.ReactElement;
}

const SVGFeatureStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  @media ${media.mobile} {
    flex-direction: column;
    padding: ${spacing.m} ${spacing.l};
  }
`;

const SVGFeatureLeftStyled = styled.div`
  @media ${media.mobile} {
    display: flex;
    justify-content: center;
  }

  @media ${media.desktop} {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SVGFeatureRightStyled = styled.div`
  @media ${media.desktop} {
    flex: 4;
    display: flex;
    align-items: center;
    padding-right: ${spacing.l};
  }
`;

SVGFeatureComponent.testID = "SVGFeature";

export default function SVGFeatureComponent({ svg, contentElement, ...props }: SVGFeatureProps) {
  return (
    <SVGFeatureStyled data-testid={composeTestID(SVGFeatureComponent.testID, props.testID)}>
      <SVGFeatureLeftStyled>{svg}</SVGFeatureLeftStyled>
      <SVGFeatureRightStyled>{contentElement}</SVGFeatureRightStyled>
    </SVGFeatureStyled>
  );
}
