import ComboBox from "components/atoms/Form/ComboBox/ComboBox";
import InputNote from "components/atoms/Form/InputNote/InputNote";
import Label from "components/atoms/Form/Label/Label";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";
import input from "styles/input";

interface ComboBoxProps extends FieldRenderProps<string, any> {
  // any = Workaround for TS issue
  options: string[];
  iconElement?: React.ReactElement;
  label?: string;
  compact?: boolean;
}

const HeadlessComboBoxContainer = styled.div`
  width: 100%;
  max-width: ${input.maxWidth};
`;

ComboBoxInput.testID = "ComboBoxInput";

export default function ComboBoxInput({
  input: { ...inputProps },
  iconElement,
  options = [],
  name,
  label,
  meta,
  compact = false,
  ...props
}: ComboBoxProps) {
  return (
    <HeadlessComboBoxContainer data-testid={ComboBoxInput.testID}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <ComboBox {...inputProps} {...props} options={options} icon={iconElement} />
      {!compact ||
        (meta?.dirty && meta.error && (
          <InputNote htmlFor={name} color={meta?.error ? color.red : undefined}>
            {meta?.error}
          </InputNote>
        ))}
    </HeadlessComboBoxContainer>
  );
}
