import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";

const LARGE_HORIZONTAL_MARGIN = spacing.m;
const LARGE_VERTICAL_MARGIN = spacing.m;
const SMALL_HORIZONTAL_MARGIN = spacing.l;
const SMALL_VERTICAL_MARGIN = spacing.m;

const DashboardContainerComponent = styled.div`
  display: flow-root;
  position: relative;

  @media ${media.mobile} {
    background-color: ${color.white};
    padding: ${SMALL_VERTICAL_MARGIN} ${SMALL_HORIZONTAL_MARGIN};
    width: 100%;
    min-height: 100%;
  }
  @media ${media.desktop} {
    width: calc(100% - ${LARGE_HORIZONTAL_MARGIN} * 2);
    min-height: calc(100% - ${LARGE_VERTICAL_MARGIN} * 2);
    background-color: ${color.white};
    border-radius: 10px;
    margin: ${LARGE_VERTICAL_MARGIN} ${LARGE_HORIZONTAL_MARGIN};
  }
`;

export default DashboardContainerComponent;
