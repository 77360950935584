import Dropdown from "components/atoms/Form/DropDown";
import Tabs from "components/molecules/Tabs";
import { PERMISSION_ENUM } from "constants/permissions";
import withPermission from "hocs/permission";
import usePermission from "hooks/context/use-permission";
import useCurrentLocationFromPaths from "hooks/use-current-location-from-paths";
import { useIsMobile } from "hooks/use-is-mobile";
import DashboardContainer from "pages/components/DashboardContainer";
import React, { useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

import BankAccounts from "./screens/BankAccounts";
import CompanyInformation from "./screens/CompanyInformation";
import PaymentInstructions from "./screens/PaymentInstructions";
import UserManagement from "./screens/UserManagement";
import Wallet from "./screens/Wallet";

const TAB_LIST = [
  {
    label: "Company Information",
    value: "/accounts/company-information",
    routes: [{ path: "/accounts/company-information" }],
    permission: PERMISSION_ENUM.VIEW_ACCOUNT,
  },
  {
    label: "Wallets",
    value: "/accounts/wallet",
    routes: [
      { path: "/accounts/wallet" },
      { path: "/accounts/wallet/add" },
      { path: "/accounts/wallet/:walletId/edit" },
    ],
    permission: PERMISSION_ENUM.VIEW_WALLETS,
  },
  {
    label: "Bank Accounts",
    value: "/accounts/bank-accounts",
    routes: [
      { path: "/accounts/bank-accounts" },
      { path: "/accounts/bank-accounts/add" },
      { path: "/accounts/bank-accounts/:accountId/edit" },
    ],
    permission: PERMISSION_ENUM.VIEW_WALLETS,
  },
  {
    label: "Payment Instructions",
    value: "/accounts/payment-instructions",
    routes: [{ path: "/accounts/payment-instructions" }],
    permission: PERMISSION_ENUM.VIEW_PAYMENT_INSTRUCTIONS,
  },
  {
    label: "User Management",
    value: "/accounts/user-management",
    routes: [
      { path: "/accounts/user-management" },
      { path: "/accounts/user-management/add" },
      { path: "/accounts/user-management/:userId/edit" },
    ],
    permission: PERMISSION_ENUM.VIEW_USERS,
  },
];

const TAB_LINKS = TAB_LIST.map(tabItem => tabItem.routes ?? tabItem.value);

const TabPanelsStyled = styled.div`
  @media (${media.small}) {
    padding: ${spacing.s} ${spacing.l};
  }
`;

function Accounts() {
  const navigation = useNavigate();
  const isMobile = useIsMobile();
  const hasPermission = usePermission();

  const handleOnTabChange = React.useCallback((value: string) => {
    navigation(value);
  }, []);

  const currentLocation = useCurrentLocationFromPaths(TAB_LINKS);

  const { permittedTabs, routes } = useMemo(() => {
    return {
      permittedTabs: TAB_LIST.filter(tabItem => hasPermission(tabItem.permission)),
      routes: (
        <TabPanelsStyled>
          <Routes>
            <Route path="*" element={<CompanyInformation />} />
            <Route path="/company-information" element={<CompanyInformation />} />
            <Route path="/bank-accounts/*" element={<BankAccounts />} />
            <Route path="/payment-instructions/*" element={<PaymentInstructions />} />
            <Route path="/user-management/*" element={<UserManagement />} />
            <Route path="/wallet/*" element={<Wallet />} />
          </Routes>
        </TabPanelsStyled>
      ),
    };
  }, []);

  return (
    <DashboardContainer>
      {isMobile ? (
        <>
          <Dropdown
            defaultValue={permittedTabs[0].value}
            options={permittedTabs}
            value={permittedTabs[currentLocation]?.value as string}
            onChange={handleOnTabChange}
          />
          {routes}
        </>
      ) : (
        <Tabs
          value={permittedTabs[currentLocation]?.value as string}
          tabList={permittedTabs}
          onChange={handleOnTabChange}
        >
          {routes}
        </Tabs>
      )}
    </DashboardContainer>
  );
}

export default withPermission(PERMISSION_ENUM.VIEW_ACCOUNT)(Accounts);
