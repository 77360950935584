import Loader from "components/atoms/Loader/Loader";
import CardLoader from "components/molecules/Card/CardLoader";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import HeaderBackNav from "components/molecules/HeaderBackNav";
import BottomNavigation from "components/organisms/BottomBar/BottomBar";
import { PERMISSION_ENUM } from "constants/permissions";
import { FORM_ERROR } from "final-form";
import getErrorMessage from "functions/get-error-message";
import { validateFormValues } from "functions/validate-schema";
import withPermission from "hocs/permission";
import { useGetAccount } from "hooks/query/bank-account/use-bank-account";
import { useUpdateAccount } from "hooks/query/bank-account/use-update-bank-account";
import { useIsMobile } from "hooks/use-is-mobile";
import { useToast } from "hooks/use-toast";
import React from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import spacing from "styles/spacing";
import { AppBankAccount } from "types/App/BankAccount";

import AccountFields from "../common/AccountFields";
import { BankAccountSchemaValidation } from "../common/schemaValidation";
import SubmitButton from "../common/SubmitButton";

function EditBankAccount() {
  const navigation = useNavigate();
  const isMobile = useIsMobile();
  const { accountId = "" } = useParams();

  const { successToast } = useToast();

  const { data: account, isLoading } = useGetAccount(accountId);
  const { mutateAsync: mutateAsyncUdateAccount } = useUpdateAccount();

  const handleOnSubmit = async (data: AppBankAccount) => {
    try {
      await mutateAsyncUdateAccount({ accountId, accountDetails: data });
      successToast({ message: "Bank account has been created successfully" });
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  if (isLoading) return <Loader size="100px" />;

  return (
    <>
      <HeaderBackNav title={account?.Name ?? ""} onBack={() => navigation("..")} />
      {isLoading ? (
        <CardLoader />
      ) : (
        <Form
          onSubmit={handleOnSubmit}
          validate={validateFormValues(BankAccountSchemaValidation)}
          initialValues={{ ...account }}
          keepDirtyOnReinitialize
        >
          {({ submitting, handleSubmit, submitError }) => {
            return (
              <form onSubmit={handleSubmit}>
                <AccountFields isEditForm />
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                <BottomNavigation
                  paddingHorizontal={isMobile ? "0" : spacing.l}
                  rightElement={
                    !isMobile ? <SubmitButton buttonText="Save Bank Account" loading={submitting} /> : undefined
                  }
                  leftElement={
                    isMobile ? <SubmitButton buttonText="Save Bank Account" loading={submitting} /> : undefined
                  }
                />
              </form>
            );
          }}
        </Form>
      )}
    </>
  );
}

export default withPermission(PERMISSION_ENUM.UPDATE_BANK_ACCOUNTS)(EditBankAccount);
