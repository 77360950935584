import { ReactComponent as KeyboardArrowRight } from "assets/icons/keyboard-arrow-right.svg";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

interface TableRowProps {
  children?: React.ReactElement[] | React.ReactElement;
  onRowClick?: () => void;
}

const TableRowStyled = styled.tr<{ isClickable: boolean }>`
  position: relative;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};

  &:not(:last-child) {
    & > td {
      border-bottom: 1px solid ${color.grey3};
    }
  }
`;

const ActionIconStyled = styled.td`
  padding: 0 ${spacing.xs};
`;

export default function TableRowComponent({ children, onRowClick }: TableRowProps) {
  const isMobile = useIsMobile();

  return (
    <TableRowStyled onClick={onRowClick} isClickable={!!onRowClick}>
      {children}
      {onRowClick && !isMobile && (
        <ActionIconStyled>
          <KeyboardArrowRight />
        </ActionIconStyled>
      )}
    </TableRowStyled>
  );
}
