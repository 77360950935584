import { ReactComponent as AddIcon } from "assets/icons/add-circle-outline.svg";
import Button from "components/atoms/Button/Button";
import RenderWithPermissions from "components/atoms/Permission";
import HeaderBackNav from "components/molecules/HeaderBackNav";
import { PERMISSION_ENUM } from "constants/permissions";
import composeTestID from "functions/compose-test-id";
import React from "react";
import styled from "styled-components";
import color from "styles/color";

interface ActionHeaderProps {
  title: string;
  btnText: string;
  onBtnClick: () => void;
  testId?: string;
  permission?: PERMISSION_ENUM;
}

const ActionHeaderStyled = styled.div`
  display: flex;
  align-items: center;
`;

ActionHeader.testID = "ActionHeader";

export default function ActionHeader({ title, btnText, onBtnClick, testId, permission }: ActionHeaderProps) {
  return (
    <ActionHeaderStyled data-testid={composeTestID(ActionHeader.testID, testId)}>
      <HeaderBackNav title={title} />
      <RenderWithPermissions permission={permission}>
        <Button
          varient="secondary"
          marginHorizontalSize="small"
          icon={<AddIcon />}
          iconSize={20}
          color={color.purple}
          onClick={onBtnClick}
        >
          {btnText}
        </Button>
      </RenderWithPermissions>
    </ActionHeaderStyled>
  );
}
