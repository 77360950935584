import { ReactComponent as ClearSVG } from "assets/icons/clear.svg";
import Icon from "components/atoms/Icon/Icon";
import Picture, { SupportedImageType } from "components/atoms/Picture/Picture";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import spacing from "styles/spacing";

export interface LogoImageType {
  label: string;
  src: string;
}

interface CollaborationElementProps {
  testID?: string;
  logoList: LogoImageType[];
  supportedTypes: SupportedImageType[];
}

const CollaborationElementStyled = styled.div`
  display: flex;
  align-items: center;
`;

const PictureStyled = styled(Picture)`
  height: 50px;

  & > img {
    height: 50px;
  }
`;

const IconStyled = styled(Icon)`
  margin: 0 ${spacing.s};
`;

CollaborationElementComponent.testID = "collaborationElement";

export default function CollaborationElementComponent({ testID, logoList, supportedTypes }: CollaborationElementProps) {
  return (
    <CollaborationElementStyled data-testid={composeTestID(CollaborationElementComponent.testID, testID)}>
      {logoList.map((logo, index) => (
        <React.Fragment key={logo.label}>
          {index !== 0 && (
            <IconStyled>
              <ClearSVG title="times" />
            </IconStyled>
          )}
          <PictureStyled alt={logo.label} src={logo.src} supportedTypes={supportedTypes}></PictureStyled>
        </React.Fragment>
      ))}
    </CollaborationElementStyled>
  );
}
