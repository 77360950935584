import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import AlertComponent from "components/atoms/Alert";
import CardError from "components/molecules/Card/CardError";
import CardLoader from "components/molecules/Card/CardLoader";
import HeaderBackNav from "components/molecules/HeaderBackNav";
import BottomNavigationComponent from "components/organisms/BottomBar/BottomBar";
import getErrorMessage from "functions/get-error-message";
import useCompanyInfo from "hooks/query/company-information/use-company";
import React, { memo } from "react";
import { Form } from "react-final-form";
import color from "styles/color";

import CompanyFields from "./CompanyFields";

function CompanyDetails() {
  const { data, isLoading, error, refetch } = useCompanyInfo();

  const handleOnSubmit = () => {
    // do nothing;
  };

  return (
    <>
      <HeaderBackNav title="Company Information" />
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching company information" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : (
        <Form onSubmit={handleOnSubmit} initialValues={data}>
          {() => (
            <>
              <CompanyFields isReadonly />
              <BottomNavigationComponent
                paddingHorizontal="0"
                leftElement={
                  <AlertComponent
                    message="The Company Details can not be edited"
                    iconElement={<InfoIcon />}
                    iconColor={color.blue}
                    iconSize={20}
                  />
                }
              />
            </>
          )}
        </Form>
      )}
    </>
  );
}

export default memo(CompanyDetails);
