import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";

const LARGE_MARGIN_VERTICAL = spacing.m;
const LARGE_MARGIN_HORIZONTAL = spacing.m;

const SMALL_MARGIN_VERTICAL = spacing.m;
const SMALL_MARGIN_HORIZONTAL = "0px";

const CardWrapperStyled = styled.div`
  min-height: 50px;
  border-radius: 10px;
  background-color: ${color.grey2};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  @media ${media.mobile} {
    margin: ${SMALL_MARGIN_VERTICAL} ${SMALL_MARGIN_HORIZONTAL};
    width: calc(100% - ${SMALL_MARGIN_HORIZONTAL} * 2);
  }
  @media ${media.desktop} {
    margin: ${LARGE_MARGIN_VERTICAL} ${LARGE_MARGIN_HORIZONTAL};
    width: calc(100% - ${LARGE_MARGIN_HORIZONTAL} * 2);
  }
`;

export default CardWrapperStyled;
