import TableBody from "components/atoms/Table/TableBody";
import TableData from "components/atoms/Table/TableData/TableData";
import TableHeader from "components/atoms/Table/TableHeader/TableHeader";
import TableRow from "components/atoms/Table/TableRow";
import formatCurrency from "functions/format-currency";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";
import { AppTransaction } from "types/App/Transaction";

import Constants from "../../../../../constants";

interface BankDetailsTableProps {
  transaction: AppTransaction;
}

const BankDetailsTableStyled = styled.table`
  width: 100%;
  padding: ${spacing.xs};

  & > tbody > tr:not(:last-child) > td {
    border-bottom: none;
  }

  & > tbody > tr > td {
    width: 60%;
  }

  & > tbody > tr > th {
    color: ${color.black};
  }

  & > tbody > tr > td,
  & > tbody > tr > th {
    padding: ${spacing.xs} ${spacing.s};
    font-weight: ${fontWeight.default};
  }
`;

export default function BankDetailsTableComponent({ transaction }: BankDetailsTableProps) {
  return (
    <BankDetailsTableStyled cellSpacing="0">
      <TableBody>
        <>
          <TableRow>
            <TableHeader>Amount:</TableHeader>
            <TableData>
              {formatCurrency({ Amount: transaction.FiatAmount.Amount, Denominator: Constants.fallbackDenominator })}
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>Payment reference:</TableHeader>
            <TableData>{transaction.Reference}</TableData>
          </TableRow>
        </>
      </TableBody>
    </BankDetailsTableStyled>
  );
}
