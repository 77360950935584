import { APIPostResponse } from "./Shared";

async function mockRequest<T>(_?: unknown, responseObject?: T) {
  console.log(`Requesting Mock: `, _);
  await timeout(2000);
  return responseObject as T;
}

const mockRequestWithError = async (_?: unknown, __?: any) => {
  await timeout(2000);
  throw new Error("This is a mocked error");
};

const mockPostResponse: APIPostResponse = {
  Entries: [
    {
      EventId: "7a51fce5-5514-48d3-1314-96b3332df112",
    },
  ],
  FailedEntryCount: 0,
};

export default {
  mockRequest,
  mockRequestWithError,
  mockPostResponse,
};

function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
