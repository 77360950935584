import Icon from "components/atoms/Icon/Icon";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";

interface FeatureIconProps {
  icon: React.ReactElement;
}

const CIRCLE_SIZE = "72px";

const FeatureIconStyled = styled.div`
  width: ${CIRCLE_SIZE};
  height: ${CIRCLE_SIZE};
  background-color: rgba(184, 152, 255, 0.15);
  border-radius: calc(${CIRCLE_SIZE} / 2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export default function FeatureIconComponent({ icon }: FeatureIconProps) {
  return (
    <Icon as={FeatureIconStyled} size={30} color={color.primary}>
      {icon}
    </Icon>
  );
}
