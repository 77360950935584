import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import getErrorMessage from "functions/get-error-message";
import { useToast } from "hooks/use-toast";

import { useBankAccounts } from "./use-bank-accounts";

export function useApproveAccount() {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useToast();

  return useMutation({
    mutationFn: async ({ accountId, accountDetails }: { accountId: string; accountDetails: object }) =>
      api.BankAccount.approveAccount(accountId, accountDetails),
    onSuccess: async () => {
      await queryClient?.invalidateQueries({ queryKey: [useBankAccounts.queryKey] });
      successToast({ message: "Account has been approved successfully" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
