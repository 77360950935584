import { Tab } from "@headlessui/react";
import React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

const TabStyled = styled.button`
  font-size: 18px;
  background-color: ${color.transparent};
  padding: ${spacing.m};
  border: 0;
  max-width: 300px;
  margin: 0 ${spacing.xs};
  cursor: pointer;

  :focus-visible {
    outline: none;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &[aria-selected="true"] {
    background-color: ${color.lightPurple};
    color: ${color.purple};
    border-bottom: 2px solid ${color.purple};
    margin-bottom: -1px;
    font-weight: 600;
  }
`;

const TabButtonComponent = ({ title, tabKey }: { title: string; tabKey: string }) => {
  return (
    <Tab as={TabStyled} data-testid={TabButtonComponent.testID} value={tabKey}>
      {title}
    </Tab>
  );
};

TabButtonComponent.testID = "tabButton";

export default TabButtonComponent;
