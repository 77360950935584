import { ReactComponent as ClearAllSVG } from "assets/icons/clear-all.svg";
import CardEmpty from "components/molecules/Card/CardEmpty";
import CardTitle from "components/molecules/Card/CardTitle";
import CardWrapper from "components/molecules/Card/CardWrapper";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";
import { AppTransactionListItem } from "types/App/Transaction";

import TransactionsTable from "./TransactionsTable";

const LARGE_DEFAULT_HEIGHT = "550px";
const SMALL_DEFAULT_HEIGHT = "550px";
interface RecentActivityCardProps {
  title: string;
  buttonElement?: React.ReactElement;
  data?: AppTransactionListItem[];
}

const RecentActivityCardStyled = styled(CardWrapper)`
  background: ${color.white};
  margin-top: ${spacing.l};
  padding-bottom: ${spacing.l};
  position: relative;
  display: flex;
  flex-direction: column;
  @media ${media.mobile} {
    height: ${SMALL_DEFAULT_HEIGHT};
  }
  @media ${media.desktop} {
    height: ${LARGE_DEFAULT_HEIGHT};
  }
`;

export default function RecentActivityCardComponent({ title, buttonElement, data }: RecentActivityCardProps) {
  return (
    <RecentActivityCardStyled>
      <CardTitle title={title} buttonElement={buttonElement} />
      {!data?.length ? (
        <>
          <CardEmpty
            title="No transactions found"
            body="There are no outbounding transactions found at the moment"
            iconElement={<ClearAllSVG />}
          />
        </>
      ) : (
        <TransactionsTable data={data} />
      )}
    </RecentActivityCardStyled>
  );
}
