import Icon from "components/atoms/Icon/Icon";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import spacing from "styles/spacing";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  testID?: string;
  icon?: React.ReactElement;
  appendElement?: React.ReactElement;
  onIconClick?: (event: unknown) => void;
  textAlign?: "left" | "right";
}

const INPUT_ICON_SIZE = 20;

const InputContainerStyled = styled.div`
  border: solid ${color.greyDark1} 1px;
  border-radius: 3px;
  width: 100%;
  display: flex;
  overflow: hidden;

  &.disabled {
    background-color: ${color.grey1};
  }
`;

const InputStyled = styled.input<{ $textAlign: "left" | "right" }>`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${color.black};
  padding: ${spacing.m};
  border: none;
  width: 100%;
  flex: 1;
  text-align: ${({ $textAlign }: { $textAlign: "left" | "right" }) => $textAlign};

  ::placeholder {
    color: ${color.greyDark1};
  }
`;

const IconStyled = styled.div`
  padding: ${spacing.m} 0 ${spacing.m} ${spacing.m};
  flex: 0 !important;
`;

export const InputTestID = "Input";

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(
  ({ icon, appendElement, onIconClick, disabled, textAlign = "left", ...props }, ref) => {
    return (
      <InputContainerStyled className={disabled ? "disabled" : ""}>
        {icon && (
          <IconStyled size={INPUT_ICON_SIZE} as={Icon} onClick={onIconClick}>
            {icon}
          </IconStyled>
        )}
        <InputStyled
          $textAlign={textAlign}
          disabled={disabled}
          data-testid={composeTestID(InputTestID, props.testID)}
          ref={ref}
          {...props}
        ></InputStyled>
        {appendElement}
      </InputContainerStyled>
    );
  }
);

InputComponent.displayName = "InputComponent";

export default InputComponent;
