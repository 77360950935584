import { ReactComponent as WalletIcon } from "assets/icons/account-balance-wallet.svg";
import ComboBoxInput from "components/molecules/Form/ComboBoxInput";
import CopyInputText from "components/molecules/Form/CopyInputText";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import React from "react";
import { Field } from "react-final-form";
import { WALLET_TYPE_ENUM, WALLET_TYPES } from "types/App/Wallet";

import { WALLET_SCHEMA } from "./schemaValidation";

function WalletFields({
  isEditForm,
  disabled,
  walletType,
}: {
  isEditForm?: boolean;
  disabled?: boolean;
  walletType?: string;
}) {
  return (
    <>
      <Field
        name={WALLET_SCHEMA.WALLET_TYPE}
        options={WALLET_TYPES}
        label="Type"
        component={ComboBoxInput}
        disabled={disabled || isEditForm}
      />
      <Field
        name={WALLET_SCHEMA.WALLET_NAME}
        label="Wallet Name"
        placeholder="Wallet Name"
        component={TextInput}
        icon={<WalletIcon />}
        compact
        disabled={disabled}
      />
      <Field
        name={WALLET_SCHEMA.WALLET_ADDRESS}
        label="Wallet Address"
        placeholder="Wallet Address"
        component={CopyInputText}
        compact
        disabled={disabled || isEditForm}
      />
      {walletType === WALLET_TYPE_ENUM.Qredo && (
        <Field
          name={WALLET_SCHEMA.WALLET_ID}
          label="Wallet ID"
          placeholder="Wallet ID"
          component={CopyInputText}
          compact
          disabled={disabled || isEditForm}
        />
      )}
    </>
  );
}

export default WalletFields;
