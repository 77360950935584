import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "hooks/use-toast";
import { AppWallet } from "types/App/Wallet";

import { useWallets } from "./use-wallets";

export function useUpdateWallet() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation({
    mutationFn: async ({ walletId, wallet }: { walletId: string; wallet: AppWallet }) =>
      api.Wallet.updateWallet(walletId, wallet),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [useWallets.queryKey] });
      successToast({ message: "Wallet has been updated successfully" });
    },
  });
}
