import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { BadgeVarients } from "components/atoms/Badge";
import Button from "components/atoms/Button/Button";
import ButtonContainer from "components/molecules/Container/ButtonContainer";
import CopyInputText from "components/molecules/Form/CopyInputText";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import Accordion from "components/organisms/Accordion";
import { useIsDesktop } from "hooks/use-is-desktop";
import React, { useMemo } from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import color from "styles/color";
import { WALLET_STATUS_ENUM } from "types/API/Wallet";
import { AppWallet, AppWalletListItem } from "types/App/Wallet";

import ApproveWallet from "../common/ApproveWallet";
import DeleteWallet from "../common/DeleteWallet";
import RejectWallet from "../common/RejectWallet";
import { WALLET_SCHEMA } from "../common/schemaValidation";

const STATUSES: { [x: string]: string } = {
  [WALLET_STATUS_ENUM.Verified]: "success",
  [WALLET_STATUS_ENUM.Pending_Approval]: "info",
  [WALLET_STATUS_ENUM.Pending_Delete]: "info",
};

interface WalletDetailProps extends AppWalletListItem {
  varient?: string;
}

export default function WalletDetail({ Id: walletId, Label, Status, ...wallet }: WalletDetailProps) {
  const navigation = useNavigate();
  const statusVarient = useMemo(() => STATUSES[Status.toString()], [Status]);
  const isDesktop = useIsDesktop();

  const handleSubmit = (data: AppWallet) => data;

  const onEdit = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    navigation(`./${walletId}/edit`);
  };

  return (
    <Accordion
      title={Label}
      status={{ text: Status.toString(), varient: statusVarient as BadgeVarients }}
      actions={
        <>
          {statusVarient === "success" && isDesktop && (
            <>
              <Button
                varient="secondary"
                color={color.blue}
                icon={<EditIcon />}
                iconSize={20}
                marginHorizontalSize="small"
                onClick={onEdit}
              >
                Edit
              </Button>
              <DeleteWallet title={Label} id={walletId} />
            </>
          )}
        </>
      }
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          ...wallet,
          [WALLET_SCHEMA.WALLET_ID]: walletId,
          [WALLET_SCHEMA.WALLET_NAME]: Label,
        }}
      >
        {() => {
          return (
            <>
              <Field
                name={WALLET_SCHEMA.WALLET_NAME}
                label="Wallet Address"
                placeholder="Wallet Address"
                component={CopyInputText}
                readOnly
              />
              <Field
                name={WALLET_SCHEMA.WALLET_TYPE}
                label="Wallet Type"
                placeholder="Wallet Type"
                component={TextInput}
                readOnly
                compact
              />
              <Field
                name={WALLET_SCHEMA.WALLET_ADDRESS}
                label="Wallet ID (optional)"
                placeholder="Wallet ID (optional)"
                component={CopyInputText}
                readOnly
              />
              <ButtonContainer>
                <>
                  {((!isDesktop && Status === WALLET_STATUS_ENUM.Verified) ||
                    Status === WALLET_STATUS_ENUM.Pending_Delete) && (
                    <DeleteWallet title={Label} id={walletId} isPending={statusVarient === "info"} />
                  )}
                  {Status === WALLET_STATUS_ENUM.Pending_Approval && <ApproveWallet title={Label} id={walletId} />}
                  {[WALLET_STATUS_ENUM.Pending_Approval, WALLET_STATUS_ENUM.Pending_Delete].includes(Status) && (
                    <RejectWallet walletId={walletId} title={Label} />
                  )}
                </>
              </ButtonContainer>
            </>
          );
        }}
      </Form>
    </Accordion>
  );
}
