import { Menu } from "@headlessui/react";
import { ReactComponent as ChevronDownSVG } from "assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUpSVG } from "assets/icons/chevron-up.svg";
import Icon from "components/atoms/Icon/Icon";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface MenuListProps {
  testID?: string;
  children?: React.ReactElement[] | React.ReactElement;
  buttonText?: string;
}

const MENU_PADDING_HORIZONTAL = "30px";
const MENU_PADDING_VERTICAL = "10px";

const MenuListStyled = styled.div`
  position: relative;
`;
const MenuButtonStyled = styled.button`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.button};
  border-radius: 5px;
  background-color: ${color.white};
  color: ${color.black};
  line-height: 27px;
  padding: ${MENU_PADDING_VERTICAL} ${MENU_PADDING_HORIZONTAL};
  border: 1px solid ${color.greyDark1};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const MenuItemsStyled = styled.div`
  position: absolute;
  top: calc(100% + ${spacing.xxs});
  right: 0;
  border-radius: 3px;
  background-color: ${color.primary};
  padding: ${spacing.xxs};
`;

const IconStyled = styled.div`
  padding-left: ${spacing.m};
  padding-right: 0;
  height: 14px;

  & svg {
    display: block;
  }

  & path {
    fill: ${color.black};
  }
`;

MenuListComponent.testID = "menuList";

export default function MenuListComponent({ buttonText, children, ...props }: MenuListProps) {
  return (
    <Menu as={MenuListStyled} data-testid={composeTestID(MenuListComponent.testID, props.testID)}>
      {({ open }) => (
        <>
          <Menu.Button as={MenuButtonStyled}>
            {buttonText}
            <IconStyled as={Icon}>{open ? <ChevronUpSVG /> : <ChevronDownSVG />}</IconStyled>
          </Menu.Button>
          <Menu.Items as={MenuItemsStyled}>{children}</Menu.Items>
        </>
      )}
    </Menu>
  );
}
