import { ReactComponent as AddCircleOutline } from "assets/icons/add-circle-outline.svg";
import Button from "components/atoms/Button/Button";
import ButtonContainer from "components/molecules/Container/ButtonContainer";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import HeaderBackNav from "components/molecules/HeaderBackNav";
import { PERMISSION_ENUM } from "constants/permissions";
import { FORM_ERROR } from "final-form";
import getErrorMessage from "functions/get-error-message";
import { validateFormValues } from "functions/validate-schema";
import withPermission from "hocs/permission";
import { useCreateUser } from "hooks/query/user/use-create-user";
import React from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import color from "styles/color";
import { AppCreateUser } from "types/App/User";

import { UserSchemaValidation } from "../common/schemaValidation";
import UserAddForm from "../common/UserAddForm";

function AddUser() {
  const navigation = useNavigate();

  const { mutateAsync: mutateCreateUser, isLoading } = useCreateUser();

  const handleOnSubmit = async (user: AppCreateUser) => {
    try {
      await mutateCreateUser(user);
      handleBack();
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  const handleBack = () => navigation("..");

  return (
    <>
      <HeaderBackNav title="Add User" onBack={handleBack} />

      <Form onSubmit={handleOnSubmit} validate={validateFormValues(UserSchemaValidation)} keepDirtyOnReinitialize>
        {({ handleSubmit, submitError, submitting }) => {
          const isDisabled = submitting || isLoading;

          return (
            <form onSubmit={handleSubmit}>
              <UserAddForm />
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

              <ButtonContainer justifyContent="flex-end">
                <Button varient="secondary" color={color.black} onClick={handleBack} disabled={isDisabled}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  varient="primary"
                  color={color.white}
                  icon={<AddCircleOutline />}
                  iconSize={20}
                  disabled={isDisabled}
                >
                  Add User
                </Button>
              </ButtonContainer>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default withPermission(PERMISSION_ENUM.ADD_USERS)(AddUser);
