import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "hooks/use-toast";
import { AppUpdateUser } from "types/App/User";

import { useUser } from "./use-user";
import useUsers from "./use-users";

export function useUpdateUser() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation({
    mutationFn: async ({ userId, user }: { userId: string; user: AppUpdateUser }) => {
      const updatedUser: AppUpdateUser = {
        Name: user.Name,
        Phone: user.Phone,
        UserGroups: user.UserGroups,
        DefaultCurrency: user.DefaultCurrency,
      };
      return api.User.updateUser(userId, updatedUser);
    },
    onSuccess: async (message: any, { userId }) => {
      await queryClient?.invalidateQueries({ queryKey: [useUsers.queryKey] });
      await queryClient?.invalidateQueries({ queryKey: [useUser.queryKey, userId] });
      successToast({ message });
    },
  });
}
