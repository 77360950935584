import { APICompanyInfo } from "types/API/Company";

import Connection from "./Connection";
// import { MOCK_COMPANY_INFO } from "./mocks/mock-company-information";

const getCompanyDetails = (organisationId: string): Promise<APICompanyInfo> =>
  Connection.get(`/organizations/${organisationId}`, undefined, "GetCompanyInfo");

// const getCompanyDetails = (_: string): Promise<APICompanyInfo> => api.Mock.mockRequest("/comapny", MOCK_COMPANY_INFO);

export default {
  getCompanyDetails,
};
