import { ReactComponent as AppRegistrationIcon } from "assets/icons/app-registration.svg";
import { ReactComponent as HouseIcon } from "assets/icons/house.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as LocationCityIcon } from "assets/icons/location-city.svg";
import { ReactComponent as CurrentLocationIcon } from "assets/icons/my-location.svg";
import { ReactComponent as WorkIcon } from "assets/icons/work.svg";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import React, { memo, ReactElement } from "react";
import { Field } from "react-final-form";

import { COMPANY_INFO_SCHEMA } from "./companySchema";

interface CompanyFormFields {
  name: string;
  label: string;
  placeholder: string;
  icon: ReactElement;
}

function CompanyFieldComponent({ isReadonly }: { isReadonly?: boolean }) {
  const CompanyFields: CompanyFormFields[] = [
    {
      name: COMPANY_INFO_SCHEMA.COMPANY_NAME,
      label: "Company Name",
      placeholder: "Company Name",
      icon: <WorkIcon />,
    },
    {
      name: COMPANY_INFO_SCHEMA.REGISTRATION_NO,
      label: "Company Registration Number",
      placeholder: "Company Registration Number",
      icon: <AppRegistrationIcon />,
    },
    {
      name: COMPANY_INFO_SCHEMA.ADDRESS,
      label: "Address",
      placeholder: "Address",
      icon: <HouseIcon />,
    },
    {
      name: COMPANY_INFO_SCHEMA.CITY,
      label: "City",
      placeholder: "City",
      icon: <LocationCityIcon />,
    },
    {
      name: COMPANY_INFO_SCHEMA.COUNTRY,
      label: "Country of Incoporation",
      placeholder: "Country of Incoporation",
      icon: <CurrentLocationIcon />,
    },
    {
      name: COMPANY_INFO_SCHEMA.STATUS,
      label: "Status",
      placeholder: "Status",
      icon: <InfoIcon />,
    },
  ];

  return (
    <form>
      {CompanyFields.map(({ name, label, placeholder, icon }) => (
        <Field
          key={name}
          name={name}
          label={label}
          placeholder={placeholder}
          component={TextInput}
          compact
          icon={icon}
          readOnly={isReadonly}
        />
      ))}
    </form>
  );
}

export default memo(CompanyFieldComponent);
