import { Disclosure } from "@headlessui/react";
import { ReactComponent as WizardStepComplete } from "assets/icons/check-circle.svg";
import { ReactComponent as ChevronDownSVG } from "assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUpSVG } from "assets/icons/chevron-up.svg";
import Heading from "components/atoms/Heading/Heading";
import Icon from "components/atoms/Icon/Icon";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";

interface SummaryListItemProps {
  title: string | React.ReactElement;
  children?: React.ReactElement;
  defaultOpen?: boolean;
}

const SummaryListItemStyled = styled.button<{ varient: string; $hasChildren: boolean }>`
  position: relative;
  list-style-type: none;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 ${spacing.s};
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: solid ${color.grey3} 1px;

  h2 {
    margin-left: ${spacing.m};
    flex: 1;
    text-align: left;
  }

  &[aria-expanded="true"] {
    ${({ $hasChildren }) => ($hasChildren ? "border: none;" : "")}
  }
`;

const SummaryListItemChildrenStyled = styled(Disclosure.Panel)`
  border-bottom: solid ${color.grey3} 1px;

  @media ${media.mobile} {
    padding: ${spacing.m} 0;
  }

  @media ${media.desktop} {
    padding: ${spacing.xs} ${spacing.xl} ${spacing.m} ${spacing.xl};
  }
`;

const IconStyled = styled.div`
  margin: 0 ${spacing.m};
`;

SummaryListItemComponent.testID = "summaryListItem";

export default function SummaryListItemComponent({ title, children, defaultOpen = false }: SummaryListItemProps) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button as={SummaryListItemStyled} $hasChildren={!!children}>
            <Icon size={28} color={color.purple}>
              <WizardStepComplete />
            </Icon>
            <Heading as="h2" varient="h4">
              {title}
            </Heading>
            {!!children && <IconStyled as={Icon}>{!open ? <ChevronDownSVG /> : <ChevronUpSVG />}</IconStyled>}
          </Disclosure.Button>
          {!!children && <SummaryListItemChildrenStyled>{children}</SummaryListItemChildrenStyled>}
        </>
      )}
    </Disclosure>
  );
}
