import Heading from "components/atoms/Heading/Heading";
import composeTestID from "functions/compose-test-id";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

interface HeaderNavigationProps {
  testID?: string;
  title?: string | React.ReactElement;
  children?: React.ReactElement;
}

export const DESKTOP_HEADER_HEIGHT = "92px";
export const MOBILE_HEADER_HEIGHT = "67px";

const HeaderNavigationStyled = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
  @media ${media.mobile} {
    height: ${MOBILE_HEADER_HEIGHT};
    padding-left: ${spacing.m};
    padding-right: ${spacing.xs};
  }
  @media ${media.desktop} {
    padding-left: ${spacing.xxl};
    padding-right: ${spacing.m};
    min-height: ${DESKTOP_HEADER_HEIGHT};
    display: flex;
    align-items: center;
  }
`;

const HeadingContainerStyled = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

HeaderNavigationComponent.testID = "headerNavigation";

export default function HeaderNavigationComponent({ children, title, ...props }: HeaderNavigationProps) {
  const isMobile = useIsMobile();
  return (
    <HeaderNavigationStyled data-testid={composeTestID(HeaderNavigationComponent.testID, props.testID)}>
      <HeadingContainerStyled>
        {!isMobile ? (
          <Heading as="h1" varient="h2">
            {title}
          </Heading>
        ) : (
          title
        )}
      </HeadingContainerStyled>
      {children}
    </HeaderNavigationStyled>
  );
}
