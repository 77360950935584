import { ReactComponent as IconBackIOS } from "assets/icons/icon-back-ios.svg";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Link from "components/atoms/Inline/Link";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import CollaborationElement from "components/molecules/CollaborationElement/CollaborationElement";
import Container from "components/molecules/Container/Container";
import BottomBar from "components/organisms/BottomBar/BottomBar";
import InstructionSteps from "components/organisms/InstructionSteps/InstructionSteps";
import * as React from "react";
import StepTemplate from "templates/StepTemplate/StepTemplate";

interface ExplainedProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function ExplainedComponent({ onBack, onContinue }: ExplainedProps) {
  return (
    <StepTemplate
      bottomBarElement={
        <BottomBar
          leftElement={
            <Button
              onClick={onBack}
              varient="secondary"
              layout="icon-left"
              mobileLayout="icon-only"
              icon={<IconBackIOS />}
            >
              Back To Sign In
            </Button>
          }
          rightElement={
            <Button testID="Continue" varient="primary" onClick={onContinue}>
              Continue
            </Button>
          }
        />
      }
    >
      <>
        <Container as="section" maxWidth={822}>
          <header>
            <Heading as="h1" varient="h2">
              Thank you for your interest in Stablr
            </Heading>
          </header>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur
          </Paragraph>
        </Container>
        <Container as="section" maxWidth={1200}>
          <CollaborationElement
            logoList={[
              {
                src: "/images/logos/logo-stablr",
                label: "Stablr logo",
              },
              {
                src: "/images/logos/logo-synaps",
                label: "Synaps logo",
              },
            ]}
            supportedTypes={["webp", "jpg"]}
          />
          <InstructionSteps
            steps={[
              {
                title: "Setup your user account",
                titleAs: "h2",
                titleVarient: "h3",
                body: "Fill in your user information, password and two factor authentication to complete the sign up.",
              },
              {
                title: (
                  <>
                    Verification of your Business and its Representatives via our partner{" "}
                    <Link external target="_blank" to="https://synaps.io/">
                      Synaps
                    </Link>
                  </>
                ),
                titleAs: "h2",
                titleVarient: "h3",
                body: "Deliver business and representatives documents with our partner Synaps for verification.",
              },
            ]}
          />
        </Container>
      </>
    </StepTemplate>
  );
}
