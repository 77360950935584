import { ReactComponent as CopyIcon } from "assets/icons/content-copy.svg";
import Button from "components/atoms/Button/Button";
import Spacer from "components/atoms/Spacer/Spacer";
import copy from "copy-to-clipboard";
import { useToast } from "hooks/use-toast";
import React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

const TableDataCurrencyContainerStyled = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 0 ${spacing.xs};
  border-radius: ${spacing.xxs};

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    background-color: ${color.white};
  }
`;

const TableCopyDataStyled = styled.span``;

export default function TableDataCopyableComponent({
  value,
  backgroundColor,
}: {
  value?: string | number;
  backgroundColor?: string;
}) {
  const { infoToast } = useToast();

  const handleClick = (event: React.MouseEvent) => {
    event?.preventDefault();
    copy(`${value}`);
    infoToast({ message: "Copied to clipboard", icon: <CopyIcon /> });
  };

  return (
    <TableDataCurrencyContainerStyled backgroundColor={backgroundColor}>
      <TableCopyDataStyled>{value}</TableCopyDataStyled>
      <Spacer width="16px" />
      <Button
        layout="icon-only"
        varient="secondary"
        marginHorizontalSize="small"
        size="small"
        icon={<CopyIcon />}
        iconColor={color.greyDark1}
        iconSize={20}
        backgroundColor={color.transparent}
        onClick={handleClick}
      ></Button>
    </TableDataCurrencyContainerStyled>
  );
}
