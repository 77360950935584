import { APIDashboard } from "types/API/Dashboard";
import { WALLET_STATUS_ENUM, WALLET_TYPE_ENUM } from "types/API/Wallet";

import { MOCK_TRANSACTIONS } from "./mock-transaction";

export const MOCK_DASHBOARD: APIDashboard = {
  Transactions: [...MOCK_TRANSACTIONS].splice(0, 5),
  Wallets: [
    {
      WalletType: WALLET_TYPE_ENUM.Qredo,
      Id: "1",
      Label: "ABollen",
      Status: WALLET_STATUS_ENUM.Verified,
      WalletAddress: "6146ccf6a66d994f7c363db875e31ca35581450a4bf6d3be6cc9ac79233a69d0",
      Balance: {
        Amount: 12445,
        Denominator: ".",
      },
    },
    {
      Id: "2",
      Label: "R NIJKAMP",
      Status: WALLET_STATUS_ENUM.Pending_Approval,
      WalletType: WALLET_TYPE_ENUM.Ethereum,
      WalletAddress: "8146ccf6a66d994f7c363db875e31ca35581450a4bf6d3be6cc9ac79233a69d0",
      Balance: {
        Amount: 57834,
        Denominator: ".",
      },
    },
    {
      Id: "4",
      Label: "LOT OF TEXT HERE",
      Status: WALLET_STATUS_ENUM.Pending_Delete,
      WalletType: WALLET_TYPE_ENUM.Ethereum,
      WalletAddress: "9146ccf6a66d994f7c363db875e31ca35581450a4bf6d3be6cc9ac79233a69d0",
      Balance: {
        Amount: 23567,
        Denominator: ".",
      },
    },
  ],
};

export const MOCK_EMPTY_DASHBOARD: APIDashboard = {
  Transactions: [],
  Wallets: [],
};
