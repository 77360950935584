import { ReactComponent as InputSVG } from "assets/icons/input.svg";
import { Auth } from "aws-amplify";
import Link from "components/atoms/Inline/Link";
import Loader from "components/atoms/Loader/Loader";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Picture from "components/atoms/Picture/Picture";
import MenuList from "components/molecules/Menu/MenuList/MenuList";
import MenuListItem from "components/molecules/Menu/MenuListItem/MenuListItem";
import WizardNavigation from "components/organisms/Wizard/WizardSideBarNavigation/WizardSideBarNavigation";
import useUserData from "hooks/context/use-user-data";
import useCurrentLocationFromPaths from "hooks/use-current-location-from-paths";
import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import color from "styles/color";
import SteppedGuideTemplate from "templates/SteppedGuideTemplate/SteppedGuideTemplate";

import CompanyVerification from "./screens/CompanyVerification";
import CreatePassword from "./screens/CreatePassword";
import EmailVerification from "./screens/EmailVerification";
import Explained from "./screens/Explained";
import SMSAuthentication from "./screens/SMSAuthentication";
import Success from "./screens/Success";
import SummaryAndSignUp from "./screens/SummaryAndSignUp";
import UserInformation, { UserInformationForm } from "./screens/UserInformation";

export interface UserInformation {
  fullName: string;
  email: string;
  mobile: string;
}

export default function SignUpComponent() {
  const navigate = useNavigate();

  const { name } = useUserData();

  const [userInformation, setUserInformation] = React.useState<UserInformation | undefined>();

  const currentLocation = useCurrentLocationFromPaths([
    "/sign-up/user-information",
    "/sign-up/create-password",
    "/sign-up/email-verification",
    "/sign-up/sms-authentication",
    "/sign-up/company-verification",
    "/sign-up/summary-and-sign-up",
    "/sign-up/success",
  ]);

  const isAuthenticatedLocation = currentLocation >= 4;

  const handleOnLogout = async () => {
    await Auth.signOut();
    navigate("../sign-in");
  };

  return isAuthenticatedLocation && name === undefined ? (
    <Loader />
  ) : (
    <SteppedGuideTemplate
      menuElement={
        isAuthenticatedLocation ? (
          <MenuList buttonText={name}>
            <MenuListItem onClick={handleOnLogout} icon={<InputSVG />}>
              Log out
            </MenuListItem>
          </MenuList>
        ) : undefined
      }
      navigationElement={
        <WizardNavigation
          currentLocation={currentLocation}
          iconElement={
            <Picture
              supportedTypes={["webp", "png"]}
              alt="stablr logo"
              src="/images/logos/logo-stablr-white-transparent"
            />
          }
          footerElement={
            <Paragraph color={color.white}>
              <>
                Already have an account?{" "}
                <Link color={color.white} to="/sign-in">
                  Sign in
                </Link>
              </>
            </Paragraph>
          }
          type={currentLocation === -1 ? "collapsed" : currentLocation === 5 ? "minimal" : "expanded"}
          steps={[
            {
              id: "user-information",
              label: "User Information",
              description: "Please provide us your user information",
            },
            {
              id: "create-password",
              label: "Create Password",
              description: "Choose a secure password",
            },
            {
              id: "email-verification",
              label: "Email Verification",
              description: "Verify your email address",
            },
            {
              id: "sms-authentication",
              label: "SMS Authentication",
              description: "Setup authentication for extra safety",
            },
            {
              id: "company-verification",
              label: "Company Verification",
              description: "Please provide us your company information",
            },
            {
              id: "summary-and-sign-up",
              label: "Summary & Sign Up",
              description: "Summary of the previous steps",
              accent: true,
            },
          ]}
        />
      }
    >
      <Routes>
        <Route
          path=""
          element={
            <Explained onBack={() => navigate("../sign-in")} onContinue={() => navigate("./user-information")} />
          }
        />
        <Route
          path="/user-information"
          element={
            <UserInformation
              userInformation={userInformation}
              onBack={() => navigate("../sign-up")}
              onContinue={values => {
                setUserInformation(values);
                navigate("./create-password");
              }}
            />
          }
        />
        <Route
          path="/create-password"
          element={
            <CreatePassword
              userInformation={userInformation as UserInformationForm}
              onBack={() => navigate("./user-information")}
              onContinue={() => navigate("./email-verification")}
            />
          }
        />
        <Route
          path="/email-verification"
          element={
            <EmailVerification
              userInformation={userInformation as UserInformationForm}
              onContinue={() => navigate("./sms-authentication")}
            />
          }
        />
        <Route
          path="/sms-authentication"
          element={
            <SMSAuthentication mobile={userInformation?.mobile} onContinue={() => navigate("./company-verification")} />
          }
        />
        <Route
          path="/company-verification"
          element={<CompanyVerification onContinue={() => navigate("./summary-and-sign-up")} />}
        />
        <Route
          path="/summary-and-sign-up"
          element={
            <SummaryAndSignUp
              onBack={() => navigate("./company-verification")}
              onContinue={() => navigate("./success")}
            />
          }
        />
        <Route path="/success" element={<Success onContinue={() => navigate("/dashboard")} />} />
      </Routes>
    </SteppedGuideTemplate>
  );
}
