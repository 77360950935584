import Button from "components/atoms/Button/Button";
import RenderWithPermissions from "components/atoms/Permission";
import Modal from "components/organisms/Modal";
import { PERMISSION_ENUM } from "constants/permissions";
import { useRejectAccount } from "hooks/query/bank-account/use-reject-bank-account";
import { title } from "process";
import React, { useEffect, useState } from "react";
import color from "styles/color";

export default function RejectBankAccount({ accountId }: { accountId: string }) {
  const [open, setOpen] = useState<boolean>(false);

  const { mutate: mutateRejectAccount, isLoading, isSuccess } = useRejectAccount();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const onReject = () => mutateRejectAccount({ accountId, accountDetails: {} });

  useEffect(() => {
    isSuccess && onCloseModal();
  }, [isSuccess]);

  return (
    <RenderWithPermissions permission={PERMISSION_ENUM.APPROVE_BANK_ACCOUNTS}>
      <Button varient="line" color={color.black} onClick={openModal}>
        Cancel
      </Button>
      <Modal
        open={open}
        onClose={onCloseModal}
        title={`Cancel Bank Account '${title}'`}
        btnText="Confirm"
        description={`Are you sure you want to cancel ${title}?`}
        onConfirm={onReject}
        btnColor={color.white}
        loading={isLoading}
      />
    </RenderWithPermissions>
  );
}
