import api from "api";
import { APIWallet, APIWalletListItem, WALLET_STATUS_ENUM } from "types/API/Wallet";

// import Connection from "./Connection";
import { MOCK_WALLET_LIST } from "./mocks/mock-wallet";
import { APIPostResponse } from "./Shared";

// const getWalletList = (): Promise<Array<APIWalletListItem>> => Connection.get("/wallets");

const getWalletList = (): Promise<Array<APIWalletListItem>> => api.Mock.mockRequest("/wallets", MOCK_WALLET_LIST);

// const getWalletById = (walletId: string): Promise<APIWalletListItem> => Connection.get(`/wallets/${walletId}`);

const getWalletById = (walletId: string): Promise<APIWalletListItem> => {
  const wallet = MOCK_WALLET_LIST.find(({ Id }) => Id === walletId);
  if (!wallet) throw new Error("Wallet not found");
  return api.Mock.mockRequest(`/wallets/${walletId}`, wallet);
};

// const createWallet = (data: APIWallet): Promise<APIPostResponse> => Connection.post("/wallets", data);

const createWallet = (data: APIWallet): Promise<APIPostResponse> => {
  const newWallet: APIWalletListItem = {
    ...data,
    Id: MOCK_WALLET_LIST.length.toString(),
    Status: WALLET_STATUS_ENUM.Verified,
    Balance: {
      Amount: 123456,
      Denominator: ".",
    },
  };
  MOCK_WALLET_LIST.push(newWallet);
  return api.Mock.mockRequest("/wallets", api.Mock.mockPostResponse);
};

// const updateWallet = (walletId: string, data: Partial<APIWallet>): Promise<APIWalletListItem> =>
//   Connection.put(`/wallets/${walletId}`, data);

const updateWallet = (walletId: string, data: Partial<APIWalletListItem>): Promise<APIWalletListItem> => {
  const idx = MOCK_WALLET_LIST.findIndex(({ Id }) => walletId === Id);
  if (idx === -1) throw new Error("Wallet not found");
  MOCK_WALLET_LIST[idx] = { ...MOCK_WALLET_LIST[idx], ...data };
  return api.Mock.mockRequest(`/wallets/${walletId}`, MOCK_WALLET_LIST[idx]);
};

// const deleteWallet = (walletId: string): Promise<boolean> => Connection.delete(`/wallets/${walletId}`);

const deleteWallet = (walletId: string): Promise<boolean> => {
  const idx = MOCK_WALLET_LIST.findIndex(({ Id }) => walletId === Id);
  if (idx === -1) throw new Error("Wallet not found");
  MOCK_WALLET_LIST.splice(idx, 1);
  return api.Mock.mockRequest(`/wallets/${walletId}`, true);
};

// const approveWallet = (walletId: string, data: object): Promise<boolean> =>
//   Connection.post(`/wallets/${walletId}/approve`, data);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const approveWallet = (walletId: string): Promise<boolean> => {
  const idx = MOCK_WALLET_LIST.findIndex(({ Id }) => walletId === Id);
  if (idx === -1) throw new Error("Wallet not found");
  MOCK_WALLET_LIST[idx] = { ...MOCK_WALLET_LIST[idx], Status: WALLET_STATUS_ENUM.Verified };
  return api.Mock.mockRequest(`/wallets/${walletId}/approve`, true);
};

// const rejectWallet = (walletId: string, data: object): Promise<boolean> =>
//   Connection.post(`/wallets/${walletId}/reject`, data);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rejectWallet = (walletId: string): Promise<boolean> => {
  const idx = MOCK_WALLET_LIST.findIndex(({ Id }) => walletId === Id);
  if (idx === -1) throw new Error("Wallet not found");
  MOCK_WALLET_LIST[idx] = { ...MOCK_WALLET_LIST[idx], Status: WALLET_STATUS_ENUM.Rejected_Delete };
  return api.Mock.mockRequest(`/wallets/${walletId}/reject`, true);
};

export default {
  getWalletList,
  getWalletById,
  createWallet,
  updateWallet,
  deleteWallet,
  approveWallet,
  rejectWallet,
};
