import Button from "components/atoms/Button/Button";
import Modal from "components/organisms/Modal";
import { useRejectWallet } from "hooks/query/wallet/use-reject-wallet";
import React, { memo, useEffect, useState } from "react";
import color from "styles/color";

function RejectWallet({ walletId, title }: { walletId: string; title: string }) {
  const [open, setOpen] = useState<boolean>(false);

  const { mutate: mutateRejectWallet, isLoading, isSuccess } = useRejectWallet();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const onReject = () => mutateRejectWallet({ walletId, title });

  useEffect(() => {
    isSuccess && onCloseModal();
  }, [isSuccess]);

  return (
    <>
      <Button varient="line" color={color.black} onClick={openModal}>
        Cancel
      </Button>
      <Modal
        open={open}
        onClose={onCloseModal}
        title={`Cancel Wallet ${title}`}
        btnText="Confirm"
        description={`Are you sure you want to cancel ${title}?`}
        onConfirm={onReject}
        btnColor={color.white}
        loading={isLoading}
      />
    </>
  );
}

export default memo(RejectWallet);
