import { useQuery } from "@tanstack/react-query";
import api from "api";

useTransactions.queryKey = "transactions";

export default function useTransactions() {
  return useQuery([useTransactions.queryKey], async () => api.Transaction.getTransactions(), {
    notifyOnChangeProps: "all",
  });
}
