import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Spacer from "components/atoms/Spacer/Spacer";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

interface TextWithButtonProps {
  testID?: string;
  title: string;
  body: string;
  buttonElement?: React.ReactElement;
}

const TextWithButtonStyled = styled.div``;

TextWithButtonComponent.testID = "textWithButton";

export default function TextWithButtonComponent({ title, body, buttonElement, ...props }: TextWithButtonProps) {
  return (
    <TextWithButtonStyled data-testid={composeTestID(TextWithButtonComponent.testID, props.testID)}>
      <Heading as="h1" varient="h2">
        {title}
      </Heading>
      <Paragraph color={color.black}>{body}</Paragraph>
      <Spacer height={spacing.m} />
      {buttonElement}
    </TextWithButtonStyled>
  );
}
