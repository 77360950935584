import { Menu } from "@headlessui/react";
import { ReactComponent as ChevronDownSVG } from "assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUpSVG } from "assets/icons/chevron-up.svg";
import Avatar from "components/atoms/Avatar/Avatar";
import Icon from "components/atoms/Icon/Icon";
import composeTestID from "functions/compose-test-id";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import media from "styles/media";
import spacing from "styles/spacing";

interface UserDropDownProps {
  testID?: string;
  children?: React.ReactElement[] | React.ReactElement;
  name?: string;
}

const MENU_LARGE_PADDING_HORIZONTAL = "20px";
const MENU_LARGE_PADDING_VERTICAL = "10px";
const MENU_SMALL_PADDING_HORIZONTAL = "10px";
const MENU_SMALL_PADDING_VERTICAL = "10px";

const UserDropDownStyled = styled.div`
  position: relative;
`;
const MenuButtonStyled = styled.button`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.button};
  border-radius: 5px;
  background-color: ${color.white};
  color: ${color.primary};
  line-height: 27px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media ${media.mobile} {
    padding: ${MENU_SMALL_PADDING_VERTICAL} ${MENU_SMALL_PADDING_HORIZONTAL};

    &[aria-expanded="true"] {
      background-color: ${color.grey1};
    }
  }

  @media ${media.desktop} {
    & *:first-child {
      margin-right: ${spacing.s};
    }

    padding: ${MENU_LARGE_PADDING_VERTICAL} ${MENU_LARGE_PADDING_HORIZONTAL};
  }
`;
const MenuItemsStyled = styled.div`
  position: absolute;
  top: calc(100% + ${spacing.xxs});
  right: 0;
  border-radius: 3px;
  background-color: ${color.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: ${spacing.xxs};
  min-width: 287px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconStyled = styled.div`
  padding-left: ${spacing.m};
  padding-right: 0;
  height: 14px;

  & svg {
    display: block;
  }
`;

UserDropDownComponent.testID = "userDropDown";

export default function UserDropDownComponent({ name, children, ...props }: UserDropDownProps) {
  const isMobile = useIsMobile();
  return (
    <Menu as={UserDropDownStyled} data-testid={composeTestID(UserDropDownComponent.testID, props.testID)}>
      {({ open }) => (
        <>
          <Menu.Button as={MenuButtonStyled}>
            <Avatar name={name} />
            {!isMobile ? (
              <>
                {name}
                <IconStyled color={color.primary} as={Icon}>
                  {open ? <ChevronUpSVG /> : <ChevronDownSVG />}
                </IconStyled>
              </>
            ) : (
              <></>
            )}
          </Menu.Button>
          <Menu.Items as={MenuItemsStyled}>{children}</Menu.Items>
        </>
      )}
    </Menu>
  );
}
