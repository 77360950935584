import React from "react";
import { Route, Routes } from "react-router-dom";

import AddUser from "./AddUser";
import EditUser from "./EditUser";
import UserOverview from "./UserOverview";

export default function UserManagement() {
  return (
    <Routes>
      <Route path="*" element={<UserOverview />} />
      <Route path="/add" element={<AddUser />} />
      <Route path="/:userId/edit" element={<EditUser />} />
    </Routes>
  );
}
