import { ReactComponent as IconBackIOS } from "assets/icons/icon-back-ios.svg";
import { ReactComponent as LockSVG } from "assets/icons/lock.svg";
import { ReactComponent as LockIconSVG } from "assets/icons/lock-solid.svg";
import { ReactComponent as CheckSVG } from "assets/svg/check-circle.svg";
import { Auth } from "aws-amplify";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import FeatureIcon from "components/molecules/FeatureIcon/FeatureIcon";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import PasswordInput from "components/molecules/Form/PasswordInput/PasswordInput";
import TextWithButton from "components/molecules/TextWithButton/TextWithButton";
import { FORM_ERROR } from "final-form";
import composeTestID from "functions/compose-test-id";
import getErrorMessage from "functions/get-error-message";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import HalfHalfResponsive from "templates/HalfHalfResponsive/HalfHalfResponsive";
import { AmplifyUser } from "types/amplify/AmplifyUser";

interface NewPasswordProps {
  testID?: string;
  user?: AmplifyUser;
  onComplete: (user: AmplifyUser) => void;
}

interface NewPasswordFormValues {
  newPassword: string;
  confirmNewPassword: string;
}

const NewPasswordStyled = styled.main`
  width: 100%;
  min-height: 100%;
`;

const ParagraphStyled = styled.p`
  text-align: left;
`;

NewPasswordComponent.testID = "NewPassword";

export default function NewPasswordComponent({ user, onComplete, ...props }: NewPasswordProps) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [success, setSuccess] = React.useState(false);

  const handleOnSubmit = async (values: NewPasswordFormValues) => {
    const code = searchParams.get("code");
    const email = searchParams.get("email");
    try {
      if (typeof code === "string" && typeof email === "string") {
        await Auth.forgotPasswordSubmit(email, code, values.newPassword);
        setSuccess(true);
      } else if (user) {
        const res = await Auth.completeNewPassword(
          user, // the Cognito User Object
          values.newPassword // the new password
        );
        if (res.challengeName === "SMS_MFA") navigate("/sign-in/sms-authentication");
        else navigate("/dashboard");
      } else {
        return { [FORM_ERROR]: "No valid code or session" };
      }
    } catch (err) {
      return { [FORM_ERROR]: getErrorMessage(err) };
    }
  };

  const handleOnContinue = () => {
    navigate("/sign-in");
  };

  return (
    <NewPasswordStyled data-testid={composeTestID(NewPasswordComponent.testID, props.testID)}>
      <HalfHalfResponsive>
        {success ? (
          <>
            <FeatureIcon icon={<CheckSVG />} />
            <TextWithButton
              title="Password changed"
              body="Sign in with your new credentials."
              buttonElement={
                <Button
                  size="small"
                  marginHorizontalSize="small"
                  varient="secondary"
                  onClick={handleOnContinue}
                  layout="icon-left"
                  icon={<IconBackIOS />}
                >
                  Back to Sign In
                </Button>
              }
            />
          </>
        ) : (
          <Form onSubmit={handleOnSubmit}>
            {({ handleSubmit, submitError, values }) => {
              const matchingPasswords =
                values.newPassword?.length > 0 &&
                values.confirmNewPassword?.length > 0 &&
                values.newPassword === values.confirmNewPassword;

              return (
                <>
                  <FeatureIcon icon={<LockIconSVG />} />
                  <Heading as="h1" varient="h2">
                    Create New Password
                  </Heading>
                  <ParagraphStyled as={Paragraph}>
                    Your passsword must be classified as at least strong. A good password consist of:
                    <br />
                    <br />
                    <li>8 or more characters</li>
                    <li>Mixture of letters and numbers</li>
                    <li>Mixture of upper and lowercase</li>
                    <li>Special characters</li>
                  </ParagraphStyled>
                  <Field
                    label="Password"
                    name="newPassword"
                    placeholder="Create password"
                    note="Create a strong password"
                    component={PasswordInput}
                    hasIndicator
                    icon={<LockSVG />}
                  />
                  <Field
                    label="Confirm Password"
                    icon={<LockSVG />}
                    note={!matchingPasswords ? "Both passwords must match" : undefined}
                    name="confirmNewPassword"
                    placeholder="Repeat Password"
                    component={PasswordInput}
                  />

                  <Button width="100%" onClick={handleSubmit}>
                    Reset Password
                  </Button>
                  <ErrorMessage>{submitError}</ErrorMessage>
                </>
              );
            }}
          </Form>
        )}
      </HalfHalfResponsive>
    </NewPasswordStyled>
  );
}
