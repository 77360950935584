import { APIBankAccountListItem } from "types/API/BankAccount";

export const MOCK_ACCOUNT_LIST: APIBankAccountListItem[] = [
  {
    Id: "1",
    Name: "ABollen",
    Status: "verified",
    Iban: "NL 00 RABO 0123 4567 89",
    Swift: "XX X XXX XX",
    Currency: "EUR",
    AccountNumber: "12345678",
    BankCode: "AAAA",
    RoutingNumber: "0023456789",
  },
  {
    Id: "2",
    Name: "R NIJKAMP",
    Status: "pending_add",
    Iban: "NL 00 RABO 0123 4567 89",
    Swift: "XX X XXX XX",
    Currency: "EUR",
    AccountNumber: "12345678",
    BankCode: "AAAA",
    RoutingNumber: "0023456789",
  },
  {
    Id: "3",
    Name: "R NIJKAMP",
    Status: "pending_delete",
    Iban: "NL 00 RABO 0123 4567 89",
    Swift: "XX X XXX XX",
    Currency: "EUR",
    AccountNumber: "12345678",
    BankCode: "AAAA",
    RoutingNumber: "0023456789",
  },
];
