import { Tab } from "@headlessui/react";
import TabTile from "components/atoms/Tab/TabButton";
import TabPannel from "components/atoms/Tab/TabPannels";
import React, { ReactElement, useMemo } from "react";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import spacing from "styles/spacing";

interface TabListItem {
  label: string;
  value: string;
}

const TabGroupStyled = styled.div`
  border-radius: ${spacing.s};
  font-family: ${fontFamily.primary};
  padding: 0;
`;

const TabListStyled = styled.div`
  border-bottom: 1px solid lightgrey;
  visibility: visible;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: ${color.white};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: ${fontFamily.secondary};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default function TabsComponent({
  tabList,
  value,
  onChange,
  tabPanels,
  children,
}: {
  tabList: TabListItem[];
  value: string;
  tabPanels?: ReactElement[];
  onChange: (value: string) => void;
  children?: React.ReactElement;
}) {
  const selectedIndex = React.useMemo(() => tabList.findIndex(tabItem => tabItem.value === value), [value]);

  const handleOnTabChange = (index: number) => {
    onChange(tabList[index].value);
  };

  const tabs = useMemo(() => {
    return tabList.map((tab, idx) => <TabTile key={`${tab.value}-${idx}`} title={tab.label} tabKey={tab.value} />);
  }, [tabList]);

  return (
    <Tab.Group
      as={TabGroupStyled}
      vertical
      selectedIndex={selectedIndex === -1 ? 0 : selectedIndex}
      onChange={handleOnTabChange}
    >
      <Tab.List as={TabListStyled}>{tabs}</Tab.List>
      {tabPanels ? <TabPannel panels={tabPanels} /> : children}
    </Tab.Group>
  );
}
