import Heading from "components/atoms/Heading/Heading";
import Icon from "components/atoms/Icon/Icon";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import * as React from "react";
import styled from "styled-components";
import spacing from "styles/spacing";

interface CardEmptyProps {
  iconElement: React.ReactElement;
  title: string;
  body: string;
}

const CIRCLE_SIZE = "180px";
const CIRCLE_OUTER_SIZE = "250px";

const CardEmptyStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.l};
  flex: 1;

  & h2 {
    margin-bottom: 0;
    font-weight: 600;
  }

  & p {
    margin-top: ${spacing.s};
    text-align: center;
  }
`;

const IconElementStyled = styled.div`
  width: ${CIRCLE_SIZE};
  height: ${CIRCLE_SIZE};
  background-color: rgba(132, 157, 255, 0.05);
  border-radius: calc(${CIRCLE_SIZE} / 2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const IconContainerStyled = styled.div`
  width: ${CIRCLE_OUTER_SIZE};
  height: ${CIRCLE_OUTER_SIZE};
  background-color: rgba(132, 157, 255, 0.05);
  border-radius: calc(${CIRCLE_OUTER_SIZE} / 2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export default function CardEmptyComponent({ title, body, iconElement }: CardEmptyProps) {
  return (
    <CardEmptyStyled>
      <IconContainerStyled>
        <Icon as={IconElementStyled} gradient size={120}>
          {iconElement}
        </Icon>
      </IconContainerStyled>
      <Heading as="h2" varient="h3">
        {title}
      </Heading>
      <Paragraph>{body}</Paragraph>
    </CardEmptyStyled>
  );
}
