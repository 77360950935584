import { DefaultTheme } from "styled-components";

import color from "./color";
import fontFamily from "./fontFamily";
import fontSize from "./fontSize";
import fontWeight from "./fontWeight";
import input from "./input";
import media from "./media";
import spacing from "./spacing";

type KeyValue = { [x: string]: string };
type KeyValueNum = { [x: string]: number };

declare module "styled-components" {
  export interface DefaultTheme {
    spacing: KeyValue;
    fontFamily: KeyValue;
    fontSize: KeyValue;
    fontWeight: KeyValueNum;
    color: KeyValue;
    media: KeyValue;
    input: KeyValue;
  }
}

const defaultTheme: DefaultTheme = {
  media,
  spacing,
  fontFamily,
  fontSize,
  fontWeight,
  color,
  input,
};

export default defaultTheme;
