import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import Badge from "components/atoms/Badge";
import TableBody from "components/atoms/Table/TableBody";
import TableData from "components/atoms/Table/TableData/TableData";
import TableDataCurrency from "components/atoms/Table/TableDataCurrency";
import TableHead from "components/atoms/Table/TableHead";
import TableHeader from "components/atoms/Table/TableHeader/TableHeader";
import TableRow from "components/atoms/Table/TableRow";
import TableRowBadge from "components/atoms/Table/TableRowBadge";
import differenceInMinutes from "date-fns/differenceInMinutes";
import capitalize from "functions/capitalize";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import { useIsMobile } from "hooks/use-is-mobile";
import parseTransactionBadgeText from "pages/Transaction/functions/parse-transaction-badge-text";
import parseBadgeVarient from "pages/Transaction/functions/parse-transaction-badge-varient";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import spacing from "styles/spacing";
import { AppTransactionListItem } from "types/App/Transaction";

interface TransactionsTableProps {
  data: AppTransactionListItem[];
}

const TransactionsTableStyled = styled.table`
  width: 100%;

  & .currency {
    & > p {
      margin-top: ${spacing.xxs};
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;

const TransactionsTableWrapperStyled = styled.div`
  width: 100%;
  padding: 0 ${spacing.m};
  overflow-x: auto;
`;

const columnHelper = createColumnHelper<AppTransactionListItem>();

const columns = [
  columnHelper.accessor("Type", {
    header: "Type",
    cell: info => <b>{capitalize(info.getValue())}</b>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("DigitalAmount", {
    header: "Amount",
    cell: info => <TableDataCurrency digitalAmount={info.getValue()} fiatAmount={info.row.original.FiatAmount} />,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("CreatedAt", {
    header: "Date",
    cell: info => new Date(info.getValue()).toLocaleDateString(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("OrderReference", {
    header: "Client Reference",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("WalletAddress", {
    header: "Wallet",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Id", {
    header: "ID",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Status", {
    header: "Status",
    cell: info =>
      info.getValue()?.trim() ? (
        <Badge varient={parseBadgeVarient(info?.getValue())} text={parseTransactionBadgeText(info.getValue())}></Badge>
      ) : undefined,
    footer: info => info.column.id,
  }),
];

const mobileColumns = [columns[0], columns[1], columns[2]];

export default function TransactionsTableComponent({ data }: TransactionsTableProps) {
  const isMobile = useIsMobile();
  const navigation = useNavigate();

  const table = useReactTable({
    data,
    columns: isMobile ? mobileColumns : columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onRowClick = React.useCallback((data: AppTransactionListItem) => {
    logEvent(EVENT_TYPE_ENUM.CLICK, "TransactionItem-TransactionOverview", {
      Type: data.Type,
      Status: data.Status,
      AgeInMinutes: differenceInMinutes(new Date(), new Date(data.CreatedAt)),
    });
    if (data.Type === "Buy") {
      navigation(`/buy/${data.Id}`);
    } else {
      navigation(`/sell/${data.Id}`);
    }
  }, []);

  return (
    <TransactionsTableWrapperStyled>
      <TransactionsTableStyled cellSpacing="0">
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader
                    paddingSize="small"
                    key={header.id}
                    textAlign={header.column.columnDef.header === "Amount" ? "right" : "left"}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => {
              return (
                <>
                  {isMobile && (
                    <TableRowBadge
                      colSpan={4}
                      onRowClick={() => onRowClick(row.original)}
                      badgeElement={
                        <Badge
                          varient={parseBadgeVarient(row.original?.Status)}
                          text={parseTransactionBadgeText(row.original?.Status)}
                        />
                      }
                    ></TableRowBadge>
                  )}
                  <TableRow key={row.id} onRowClick={() => onRowClick(row.original)}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <TableData paddingSize="small" key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableData>
                      );
                    })}
                  </TableRow>
                </>
              );
            })}
          </>
        </TableBody>
      </TransactionsTableStyled>
    </TransactionsTableWrapperStyled>
  );
}
