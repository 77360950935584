import * as React from "react";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactElement | React.ReactElement[];
  maxWidth?: number;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  as?: "article" | "section";
}

const ContainerStyled = styled.div(
  ({ maxWidth, marginTop, marginBottom, marginLeft, marginRight }: ContainerProps) => `
  @media ${media.mobile} {
    margin: ${spacing.l};
  }
  @media ${media.desktop} {
    margin: ${spacing.xxl};
  }
  ${marginBottom ? `margin-bottom: ${marginBottom} !important;` : ""}
  ${marginTop ? `margin-top: ${marginTop} !important;` : ""}
  ${marginLeft ? `margin-left: ${marginLeft} !important;` : ""}
  ${marginRight ? `margin-right: ${marginRight} !important;` : ""}
  ${maxWidth ? `max-width: ${maxWidth}px !important;` : ""}
`
);

export default ContainerStyled;
