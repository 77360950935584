import Spacer from "components/atoms/Spacer/Spacer";
import formatCurrency from "functions/format-currency";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";
import { AppCurrencyObject } from "types/App/Shared";

const TableDataCurrencyContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

const TableDataCurrencyStyled = styled.span`
  display: block;
`;

interface TableDataProps extends React.HTMLAttributes<HTMLTableCellElement> {
  digitalAmount: AppCurrencyObject;
  fiatAmount: AppCurrencyObject;
}

TableDataComponent.testID = "tableData";

export default function TableDataComponent({ digitalAmount, fiatAmount }: TableDataProps) {
  return (
    <TableDataCurrencyContainerStyled>
      <TableDataCurrencyStyled>{formatCurrency(digitalAmount)}</TableDataCurrencyStyled>
      <Spacer height={spacing.xxs} />
      <TableDataCurrencyStyled style={{ color: color.grey4 }}>€ {formatCurrency(fiatAmount)}</TableDataCurrencyStyled>
    </TableDataCurrencyContainerStyled>
  );
}
