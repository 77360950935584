import { ReactComponent as SwitchAccount } from "assets/icons/switch-account.svg";
import ActionHeader from "components/molecules/ActionHeader";
import CardEmpty from "components/molecules/Card/CardEmpty";
import CardError from "components/molecules/Card/CardError";
import CardLoader from "components/molecules/Card/CardLoader";
import SummaryList from "components/organisms/SummaryList/SummaryList/SummaryList";
import { PERMISSION_ENUM } from "constants/permissions";
import getErrorMessage from "functions/get-error-message";
import withPermission from "hocs/permission";
import { useBankAccounts } from "hooks/query/bank-account/use-bank-accounts";
import React from "react";
import { useNavigate } from "react-router-dom";

import BankAccountDetail from "./AccountDetail";

function AccountListComponent() {
  const navigation = useNavigate();

  const { data: accountList, isLoading, error, refetch } = useBankAccounts();

  return (
    <>
      <ActionHeader
        title="Bank Account"
        btnText="Add Bank Account"
        onBtnClick={() => navigation("/accounts/bank-accounts/add")}
        permission={PERMISSION_ENUM.ADD_BANK_ACCOUNTS}
      />
      {error || (!isLoading && accountList === undefined) ? (
        <CardError body={getErrorMessage(error)} title="Can not load Bank Account(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !accountList?.length ? (
        <CardEmpty
          iconElement={<SwitchAccount />}
          title="No Bank Accounts found"
          body="There is no Bank Account connected to your account at the moment"
        />
      ) : (
        <SummaryList>
          {accountList.map(({ Id, ...account }) => (
            <BankAccountDetail key={Id} Id={Id} {...account} onRefresh={refetch} />
          ))}
        </SummaryList>
      )}
    </>
  );
}

export default withPermission(PERMISSION_ENUM.VIEW_BANK_ACCOUNTS)(AccountListComponent);
