import WizardHorizontalNavigation from "components/organisms/Wizard/WizardHorizontalNavigation";
import useTransaction from "hooks/query/transaction/use-transaction";
import * as React from "react";
import { Route, Routes, useParams } from "react-router-dom";

const WIZARD_STEPS = [
  { id: "/sell", label: "Sell" },
  { id: "/pay", label: "Pay" },
  { id: "/success", label: "Success" },
];

export default function SellWizardComponent() {
  return (
    <Routes>
      <Route path="/:id" element={<DynamicSellWizard />}></Route>
      <Route path="" element={<WizardHorizontalNavigation currentLocation={0} steps={WIZARD_STEPS} />}></Route>
    </Routes>
  );
}

function DynamicSellWizard() {
  const { id } = useParams();
  const { data: transaction, isLoading, error } = useTransaction(id ?? "");
  return (
    <WizardHorizontalNavigation
      currentLocation={
        isLoading || error || transaction === undefined
          ? -1
          : transaction.Status === "PENDING" || transaction.Status === "AWAITING_CONFIRMATION"
          ? 1
          : transaction.Status === "CONFIRMED"
          ? 2
          : -1
      }
      steps={WIZARD_STEPS}
    />
  );
}
