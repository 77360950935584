import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import Badge from "components/atoms/Badge";
import TableBody from "components/atoms/Table/TableBody";
import TableData from "components/atoms/Table/TableData/TableData";
import TableHead from "components/atoms/Table/TableHead";
import TableHeader from "components/atoms/Table/TableHeader/TableHeader";
import TableRow from "components/atoms/Table/TableRow";
import capitalize from "functions/capitalize";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import fontWeight from "styles/fontWeight";

interface UserTableProps {
  data: UserData[];
}

export interface UserData {
  Name: string;
  Roles: string[];
  Email: string;
  Disabled?: boolean;
}

const UserTableStyled = styled.table`
  width: 100%;

  & th {
    font-weight: ${fontWeight.bold};
  }
`;

const UserTableWrapperStyled = styled.div`
  overflow-x: auto;

  & tr {
    & b {
      font-weight: ${fontWeight.semiBold};
    }

    & > td:last-child {
      text-align: right;
    }
  }
`;

const columnHelper = createColumnHelper<UserData>();

const columns = [
  columnHelper.accessor("Name", {
    header: "Name",
    cell: info => capitalize(info.getValue()),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Roles", {
    header: "Role",
    cell: info => <b>{info.getValue().join(", ")}</b>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Email", {
    header: "Email",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Disabled", {
    header: "Status",
    cell: info =>
      info.getValue() ? <Badge text="Disabled" varient="error" /> : <Badge text="Enabled" varient="success" />,
    footer: info => info.column.id,
  }),
];

const mobileColumns = [columns[0], columns[1], columns[2], columns[3]];

export default function UserTableComponent({ data }: UserTableProps) {
  const isMobile = useIsMobile();
  const navigation = useNavigate();

  const table = useReactTable({
    data,
    columns: isMobile ? mobileColumns : columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onRowClick = (row: any) => {
    navigation(`./${row.original?.Username}/edit`);
  };

  return (
    <UserTableWrapperStyled>
      <UserTableStyled cellSpacing="0">
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader key={header.id}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </tr>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id} onRowClick={() => onRowClick(row)}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>
                  );
                })}
              </TableRow>
            ))}
          </>
        </TableBody>
      </UserTableStyled>
    </UserTableWrapperStyled>
  );
}
