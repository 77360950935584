import { Auth } from "aws-amplify";
import Heading from "components/atoms/Heading/Heading";
// import Link from "components/atoms/Inline/Link";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Container from "components/molecules/Container/Container";
import MFAInput from "components/molecules/Form/MFAInput/MFAInput";
import FormLayout from "components/organisms/FormLayout/FormLayout";
import getErrorMessage from "functions/get-error-message";
import useUserData from "hooks/context/use-user-data";
import phone from "phone";
import * as React from "react";
import { Field, Form } from "react-final-form";
import StepTemplate from "templates/StepTemplate/StepTemplate";

interface SMSAuthenticationProps {
  onContinue: () => void;
  mobile?: string;
}

SMSAuthenticationComponent.testID = "SMSAuthentication";

export default function SMSAuthenticationComponent({ onContinue, mobile }: SMSAuthenticationProps) {
  const user = useUserData();

  const handleOnSubmit = async (values: { code: string }) => {
    try {
      await Auth.confirmSignIn(user.data, values.code, "SMS_MFA");

      onContinue();
    } catch (error) {
      return { code: getErrorMessage(error) };
    }
  };

  const phoneNumber = React.useMemo(() => {
    if (!mobile) return;
    const { countryCode } = phone(mobile);
    const number = mobile.replace(countryCode ?? "", "");
    return `${countryCode} ${number}`;
  }, [mobile]);

  return (
    <StepTemplate>
      <Container as="section" maxWidth={1000} id="title-container">
        <header>
          <Heading as="h1" varient="h2">
            4. Two Factor Authentication
          </Heading>
        </header>
        <Paragraph>Setup two factor authentication for extra safety</Paragraph>
        <br />
        <Paragraph>
          Please fill in the 6-digit code sent to <b>{user?.phone_number ?? phoneNumber}</b> to complete your
          authentication setup
        </Paragraph>
        <Form onSubmit={handleOnSubmit}>
          {({ submitError, handleSubmit }) => (
            <FormLayout>
              <>
                <Field
                  invalid={submitError !== undefined}
                  onComplete={handleSubmit}
                  name="code"
                  component={MFAInput}
                  ariaLabel="MFA"
                />
              </>
            </FormLayout>
          )}
        </Form>
        {/* <Paragraph>
          It may take a minute to receive your code.
          <br />
          Haven’t received it? <Link to="">Resend a new code</Link>.
        </Paragraph> */}
      </Container>
    </StepTemplate>
  );
}
