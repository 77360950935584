import * as yup from "yup";

export enum BANK_ACCOUNT_SCHEMA {
  ACCOUNT_NAME = "Name",
  IBAN = "Iban",
  ACCOUNT_NUMBER = "AccountNumber",
  BANK_CODE = "BankCode",
  SWIFT = "Swift",
  ROUTING_NUMBER = "RoutingNumber",
  CURRENCY = "Currency",
}

export const BankAccountSchemaValidation = yup.object({
  [BANK_ACCOUNT_SCHEMA.ACCOUNT_NUMBER]: yup.string().required("Account Number is required"),
  [BANK_ACCOUNT_SCHEMA.IBAN]: yup.string().required("IBAN is required"),
  [BANK_ACCOUNT_SCHEMA.BANK_CODE]: yup
    .string()
    .required("Bank Code is required")
    .matches(/^[a-zA-Z]{4}$/, "Bank Code must be 4 letters"),
  [BANK_ACCOUNT_SCHEMA.ACCOUNT_NAME]: yup
    .string()
    .required("Account Name is required")
    .matches(/^[a-zA-Z? ]+$/, "Name can only contain alpha charaters."),
  [BANK_ACCOUNT_SCHEMA.SWIFT]: yup.string().required("Swift/BIC code is required"),
  [BANK_ACCOUNT_SCHEMA.ROUTING_NUMBER]: yup.string().required("Routing Number is required"),
  [BANK_ACCOUNT_SCHEMA.CURRENCY]: yup.string().required("Currency is required"),
});
