import composeTestID from "functions/compose-test-id";
import getInitials from "functions/get-initials";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";

interface AvatarProps {
  testID?: string;
  name?: string;
  size?: "large" | "small";
}

const AvatarStyled = styled.div(
  ({ $size }: { $size: "large" | "small" }) => `
  background: ${color.gradient};
  width: ${$size === "large" ? SIZE_LARGE : SIZE_SMALL};
  height: ${$size === "large" ? SIZE_LARGE : SIZE_SMALL};
  font-size: ${$size === "large" ? FONT_SIZE_LARGE : FONT_SIZE_SMALL};
  border-radius: 100%;
  color: ${color.white};
  font-weight: ${fontWeight.semiBold};
  display:flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`
);

const SIZE_LARGE = "64px";
const SIZE_SMALL = "34px";
const FONT_SIZE_LARGE = fontSize.h3;
const FONT_SIZE_SMALL = fontSize.p;

AvatarComponent.testID = "avatar";

export default function AvatarComponent({ name, size = "small", ...props }: AvatarProps) {
  const initials = name && getInitials(name);
  return (
    <AvatarStyled $size={size} data-testid={composeTestID(AvatarComponent.testID, props.testID)}>
      {initials}
    </AvatarStyled>
  );
}
