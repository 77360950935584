import CheckBox from "components/atoms/Form/CheckBox";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";

interface CheckBoxProps extends FieldRenderProps<string, HTMLInputElement> {
  label?: string | React.ReactElement;
}

export default function CheckBoxComponent({
  input: { ...inputProps },
  label = "",
  meta: { error, touched },
}: CheckBoxProps) {
  const isError = error && touched;
  return (
    <React.Fragment>
      <CheckBox
        error={isError}
        value={inputProps.value}
        onChange={inputProps.onChange}
        label={label}
        id={inputProps.name}
      />
    </React.Fragment>
  );
}
