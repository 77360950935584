import api from "api";
import { ReactComponent as IconBackIOS } from "assets/icons/icon-back-ios.svg";
import { ReactComponent as IconForwardIOS } from "assets/icons/icon-forward-ios.svg";
import { ReactComponent as LockSVG } from "assets/icons/lock.svg";
import { Auth } from "aws-amplify";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Container from "components/molecules/Container/Container";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import PasswordInput from "components/molecules/Form/PasswordInput/PasswordInput";
import BottomBar from "components/organisms/BottomBar/BottomBar";
import FormLayout from "components/organisms/FormLayout/FormLayout";
import { FORM_ERROR } from "final-form";
import getErrorMessage from "functions/get-error-message";
import getPasswordSecurityLevel from "functions/get-password-security-level";
import * as React from "react";
import { Field, Form } from "react-final-form";
import StepTemplate from "templates/StepTemplate/StepTemplate";

import { UserInformationForm } from "./UserInformation";

interface CreatePaswordFormProps {
  password: string;
  confirmPassword: string;
}

interface CreatePasswordProps {
  onBack: () => void;
  onContinue: () => void;
  userInformation: UserInformationForm;
}

CreatePasswordComponent.testID = "createPassword";

export default function CreatePasswordComponent({ onBack, onContinue, userInformation }: CreatePasswordProps) {
  const getSessionID = async () => {
    const sessionID = await api.Synaps.postSession();
    return sessionID.session_id;
  };

  const handleOnSubmit = async (values: CreatePaswordFormProps) => {
    try {
      const sessionID = await getSessionID();
      await Auth.signUp({
        username: userInformation.email,
        password: values.password,
        attributes: {
          email: userInformation.email,
          phone_number: userInformation.mobile,
          name: userInformation.fullName,
          "custom:sessionId": sessionID,
        },
        autoSignIn: {
          // enables auto sign in after users email is confirmed
          enabled: true,
        },
      });
      onContinue();
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  const handleValidation = (values: CreatePaswordFormProps) => {
    const bothValuesAreValid = values.password?.length > 0 && values.confirmPassword?.length > 0;
    const passwordSecurtyLevel = getPasswordSecurityLevel(values.password);

    return {
      confirmPassword:
        bothValuesAreValid && values.password !== values.confirmPassword
          ? "Passwords do not match"
          : passwordSecurtyLevel < 4
          ? "Password must be more secure"
          : undefined,
    };
  };

  return (
    <Form initialValues={{ password: "", confirmPassword: "" }} validate={handleValidation} onSubmit={handleOnSubmit}>
      {({ handleSubmit, submitting, values, submitError }) => (
        <StepTemplate
          bottomBarElement={
            <BottomBar
              leftElement={
                <Button
                  varient="secondary"
                  onClick={onBack}
                  layout="icon-left"
                  mobileLayout="icon-only"
                  icon={<IconBackIOS />}
                >
                  Previous Step
                </Button>
              }
              rightElement={
                <Button
                  icon={<IconForwardIOS />}
                  varient="primary"
                  layout="icon-right"
                  onClick={handleSubmit}
                  loading={submitting}
                  testID="Next"
                >
                  Next
                </Button>
              }
            />
          }
        >
          <Container as="section" maxWidth={1000} id="title-container">
            <header>
              <Heading as="h1" varient="h2">
                <> 3. Create Password</>
              </Heading>
            </header>
            <Paragraph>
              Your passsword must be classified as at least strong. A good password consist of:
              <li>8 or more characters</li>
              <li>Mixture of letters and numbers</li>
              <li>Mixture of upper and lowercase</li>
              <li>Special characters</li>
            </Paragraph>

            <FormLayout>
              <>
                <Field
                  label="Password"
                  type="email"
                  name="password"
                  placeholder="Create password"
                  note="Create a strong password"
                  component={PasswordInput}
                  hasIndicator
                  icon={<LockSVG />}
                />
                <Field
                  label="Confirm Password"
                  icon={<LockSVG />}
                  note={
                    values.password !== values.confirmPassword || values.password === ""
                      ? "Both passwords must match"
                      : undefined
                  }
                  name="confirmPassword"
                  placeholder="Repeat Password"
                  component={PasswordInput}
                />
                <ErrorMessage>{submitError}</ErrorMessage>
              </>
            </FormLayout>
          </Container>
        </StepTemplate>
      )}
    </Form>
  );
}
