import ComboBox from "components/atoms/Form/ComboBox/ComboBox";
import Input from "components/atoms/Form/Input/Input";
import InputNote from "components/atoms/Form/InputNote/InputNote";
import Label from "components/atoms/Form/Label/Label";
import composeTestID from "functions/compose-test-id";
import { useWallets } from "hooks/query/wallet/use-wallets";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";
import { AppWalletListItem } from "types/App/Wallet";

interface WalletInputProps extends FieldRenderProps<AppWalletListItem | undefined, HTMLInputElement> {
  testID?: string;
  note?: string;
  compact?: boolean;
}

const MAX_INPUT_WIDTH = "500px";
const BORDER_MARGIN = spacing.xs;

const WalletInputStyled = styled.div`
  width: 100%;
  max-width: ${MAX_INPUT_WIDTH};
  box-sizing: border-box;
`;

const InputGroupStyled = styled.div`
  display: flex;

  & > div:first-child {
    width: 135px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;

    &::after {
      content: "";
      height: calc(100% - ${BORDER_MARGIN} * 2);
      box-sizing: border-box;
      margin: ${BORDER_MARGIN} 0;
      width: 0;
      border-left: solid ${color.greyDark1} 1px;
    }
  }

  & > div > input:disabled {
    background-color: ${color.white};
  }

  & > *:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    width: unset;
    flex: 1;
  }
`;

WalletInputComponent.testID = "WalletInput";

export default function WalletInputComponent({
  input: { type = "text", ...inputProps },
  note,
  compact = false,
  meta: { error, touched },
  ...props
}: WalletInputProps) {
  const { data: wallets, isLoading } = useWallets();

  const handleOnChange = (newId: string) => {
    const walletObject = wallets?.find(wallet => wallet.Id === newId);
    if (walletObject !== undefined) {
      inputProps.onChange({ target: { value: walletObject } });
    }
  };

  const walletOptions = React.useMemo(() => {
    return wallets !== undefined
      ? wallets.map(wallet => ({
          id: wallet.Id,
          label: wallet.Label,
          value: wallet.WalletAddress,
        }))
      : [];
  }, [wallets]);

  const valueObject = React.useMemo(() => {
    return wallets !== undefined ? walletOptions.find(item => item.id === inputProps?.value?.Id) : undefined;
  }, [inputProps.value, walletOptions]);

  const isError = touched && error;

  return (
    <WalletInputStyled data-testid={composeTestID(WalletInputComponent.testID, props.testID)}>
      <Label htmlFor={inputProps.name}>{props.label}</Label>
      <InputGroupStyled>
        <ComboBox
          loading={isLoading}
          placeholder={"Wallet"}
          name={`${inputProps.name}-extension`}
          onChange={handleOnChange}
          options={walletOptions}
          value={valueObject ? valueObject.label : ""}
        />
        <Input
          autoComplete="off"
          {...inputProps}
          {...props}
          type={type}
          disabled
          value={valueObject ? valueObject.value : ""}
        ></Input>
      </InputGroupStyled>
      {(!compact || note?.trim() || isError) && (
        <InputNote htmlFor={inputProps.name} color={isError ? color.red : undefined}>
          {isError ? error : note}
        </InputNote>
      )}
    </WalletInputStyled>
  );
}
