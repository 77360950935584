import TextInput from "components/molecules/Form/TextInput/TextInput";
import React, { memo } from "react";
import { Field } from "react-final-form";

import { BANK_ACCOUNT_SCHEMA } from "./schemaValidation";

interface AccountDetail {
  name: string;
  label: string;
  placeholder: string;
  isEditable: boolean;
}

function AccountFieldComponent({ isEditForm, disabled }: { isEditForm?: boolean; disabled?: boolean }) {
  const AccountFields: AccountDetail[] = [
    {
      name: BANK_ACCOUNT_SCHEMA.ACCOUNT_NAME,
      label: "Account Name",
      placeholder: "Account Name",
      isEditable: true,
    },
    {
      name: BANK_ACCOUNT_SCHEMA.IBAN,
      label: "IBAN",
      placeholder: "NL 00 RABO 0123 4567 89",
      isEditable: isEditForm ? false : true,
    },
    {
      name: BANK_ACCOUNT_SCHEMA.ACCOUNT_NUMBER,
      label: "Account Number",
      placeholder: "12345678",
      isEditable: isEditForm ? false : true,
    },
    {
      name: BANK_ACCOUNT_SCHEMA.BANK_CODE,
      label: "Bank Code",
      placeholder: "AAAA",
      isEditable: isEditForm ? false : true,
    },
    {
      name: BANK_ACCOUNT_SCHEMA.SWIFT,
      label: "SWIFT/BIC",
      placeholder: "XXX X XXX XX",
      isEditable: isEditForm ? false : true,
    },
    {
      name: BANK_ACCOUNT_SCHEMA.ROUTING_NUMBER,
      label: "Routing Number",
      placeholder: "0023456789",
      isEditable: isEditForm ? false : true,
    },
    {
      name: BANK_ACCOUNT_SCHEMA.CURRENCY,
      label: "Currency",
      placeholder: "EUR",
      isEditable: true,
    },
  ];

  return (
    <>
      {AccountFields.map(({ name, label, placeholder, isEditable }) => (
        <Field
          key={name}
          name={name}
          label={label}
          placeholder={placeholder}
          component={TextInput}
          compact
          disabled={!isEditable || disabled}
        />
      ))}
    </>
  );
}

export default memo(AccountFieldComponent);
