import { WALLET_TYPES } from "types/App/Wallet";
import * as yup from "yup";

export enum WALLET_SCHEMA {
  WALLET_TYPE = "WalletType",
  WALLET_ID = "Id",
  WALLET_ADDRESS = "WalletAddress",
  WALLET_NAME = "Label",
}

export const WalletSchemaValidation = yup.object({
  [WALLET_SCHEMA.WALLET_TYPE]: yup
    .string()
    .required("Wallet Type is required")
    .oneOf(WALLET_TYPES, "It should be a valid wallet type"),
  [WALLET_SCHEMA.WALLET_ADDRESS]: yup
    .string()
    .required("Wallet Address is required")
    .length(15, "Not a valid wallet address"),
  [WALLET_SCHEMA.WALLET_NAME]: yup
    .string()
    .required("Wallet Name is required")
    .test("maxLength", "Wallet name cannot be more than 50 characters", (value?: string) => (value?.length ?? 0) <= 50),
  [WALLET_SCHEMA.WALLET_ID]: yup
    .string()
    .test(
      "maxLength",
      "Not a valid Qredo wallet code",
      (value?: string) => !value || (value?.split(" ")?.length === 3 && value?.length === 25)
    ),
});
