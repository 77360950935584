import { ReactComponent as AccountBalanceWallet } from "assets/icons/account-balance-wallet.svg";
import ActionHeader from "components/molecules/ActionHeader";
import CardEmpty from "components/molecules/Card/CardEmpty";
import CardError from "components/molecules/Card/CardError";
import CardLoader from "components/molecules/Card/CardLoader";
import SummaryList from "components/organisms/SummaryList/SummaryList/SummaryList";
import getErrorMessage from "functions/get-error-message";
import { useWallets } from "hooks/query/wallet/use-wallets";
import React from "react";
import { useNavigate } from "react-router-dom";

import WalletDetail from "./WalletDetail";

export function WalletOverview() {
  const navigation = useNavigate();

  const { data, isLoading, error, refetch } = useWallets();

  return (
    <>
      <ActionHeader
        title="Wallet Overview"
        btnText="Add Wallet"
        onBtnClick={() => navigation("/accounts/wallet/add")}
      />
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching wallet(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !data?.length ? (
        <CardEmpty
          iconElement={<AccountBalanceWallet />}
          title="No Wallets found"
          body="There is no Wallet connected to your account at the moment"
        />
      ) : (
        <SummaryList>
          {data.map(({ Id, ...wallet }) => (
            <WalletDetail key={Id} Id={Id} {...wallet} />
          ))}
        </SummaryList>
      )}
    </>
  );
}
