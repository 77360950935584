import { ReactComponent as BuyIconSVG } from "assets/icons/buy.svg";
import { ReactComponent as DashboardIconSVG } from "assets/icons/dashboard.svg";
import { ReactComponent as SellIconSVG } from "assets/icons/sell.svg";
import { ReactComponent as SettingsIconSVG } from "assets/icons/settings.svg";
import { ReactComponent as TransactionsIconSVG } from "assets/icons/transactions.svg";
import { ReactComponent as StablRLogoSVG } from "assets/svg/logo-stablr.svg";
import { Auth } from "aws-amplify";
import Spacer from "components/atoms/Spacer/Spacer";
import Constants from "constants/index";
import { PERMISSION_ENUM } from "constants/permissions";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import useUserData from "hooks/context/use-user-data";
import { useIsMobile } from "hooks/use-is-mobile";
import ColoredDrawerLink from "pages/components/ColoredDrawerLink";
import DrawerLink from "pages/components/DrawerLink";
import HeaderNavigation from "pages/components/HeaderNavigation";
import NavigationDrawer from "pages/components/NavigationDrawer";
import UserDropDown from "pages/components/UserDropDown";
import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import color from "styles/color";
import spacing from "styles/spacing";
import DashboardTemplate from "templates/DashboardTemplate/DashboardTemplate";

import PackageJson from "../../package.json";
import MenuDropDown from "./components/MenuDropDown";
import MenuDropDownFeatureLink from "./components/MenuDropDownFeatureLink";
import MenuDropDownFeatureList from "./components/MenuDropDownFeatureList";
import MenuDropDownLink from "./components/MenuDropDownLink";
import MenuDropDownList from "./components/MenuDropDownList";
import UserDropDownActions from "./components/UserDropDownActions";
import UserDropDownDivider from "./components/UserDropDownDivider";
import UserDropDownFootLinks from "./components/UserDropDownFootLinks";
import UserDropDownInfo from "./components/UserDropDownInfo";

interface DashboardMenuProps {
  children: React.ReactElement;
}

export default function DashboardMenuComponent({ children }: DashboardMenuProps) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const userData = useUserData();

  const handleOnLogOut = async () => {
    await Auth.signOut();
    navigate("../sign-in");
  };

  return (
    <DashboardTemplate
      navigationBarElement={
        <NavigationDrawer>
          <ColoredDrawerLink
            color={color.primary}
            icon={<BuyIconSVG />}
            to="/buy"
            permission={PERMISSION_ENUM.BUY_EURR}
          >
            Buy
          </ColoredDrawerLink>
          <ColoredDrawerLink
            color={color.secondary}
            icon={<SellIconSVG />}
            to="/sell"
            permission={PERMISSION_ENUM.SELL_EURR}
          >
            Sell
          </ColoredDrawerLink>
          <Spacer height={spacing.s} />
          <DrawerLink icon={<DashboardIconSVG />} to="/dashboard" permission={PERMISSION_ENUM.VIEW_DASHBOARD}>
            Dashboard
          </DrawerLink>
          <DrawerLink icon={<TransactionsIconSVG />} to="/transactions" permission={PERMISSION_ENUM.VIEW_TRANSACTIONS}>
            Transactions
          </DrawerLink>
          <DrawerLink icon={<SettingsIconSVG />} to="/accounts" permission={PERMISSION_ENUM.VIEW_ACCOUNT}>
            Accounts
          </DrawerLink>
        </NavigationDrawer>
      }
      headerNavigationElement={
        <HeaderNavigation
          title={
            isMobile ? (
              <StablRLogoSVG />
            ) : (
              <Routes>
                <Route path="/dashboard/*" element={<>Dashboard</>} />
                <Route path="/transactions/*" element={<>Transactions</>} />
                <Route path="/accounts/*" element={<>Accounts</>} />
                <Route path="/buy/*" element={<>Buy</>} />
                <Route path="/sell/*" element={<>Sell</>} />
              </Routes>
            )
          }
        >
          <>
            <UserDropDown name={userData.name ?? "-"}>
              <UserDropDownInfo name={userData.name ?? "-"} email={userData.email ?? ""}></UserDropDownInfo>
              <UserDropDownDivider />
              <UserDropDownActions onLogout={handleOnLogOut} version={PackageJson.version} />
              <UserDropDownDivider />
              <UserDropDownFootLinks
                links={[
                  {
                    to: Constants.links.termsOfUse,
                    label: "Terms of Use",
                    onClick: () => {
                      logEvent(EVENT_TYPE_ENUM.LINK, "TermsAndConditions-DashboardMenu");
                    },
                  },
                  {
                    to: "https://stablr.com",
                    label: "StablR.com",
                    external: true,
                    onClick: () => {
                      logEvent(EVENT_TYPE_ENUM.LINK, "StablRCom-DashboardMenu");
                    },
                  },
                  {
                    to: Constants.links.privacyPolicy,
                    label: "Privacy Policy",
                    onClick: () => {
                      logEvent(EVENT_TYPE_ENUM.LINK, "PrivacyPolicy-DashboardMenu");
                    },
                  },
                ]}
              />
            </UserDropDown>

            {isMobile ? (
              <MenuDropDown>
                <>
                  <MenuDropDownFeatureList>
                    <MenuDropDownFeatureLink to="/buy" icon={<BuyIconSVG />} permission={PERMISSION_ENUM.BUY_EURR}>
                      Buy
                    </MenuDropDownFeatureLink>
                    <Spacer width={spacing.m} />
                    <MenuDropDownFeatureLink to="/sell" icon={<SellIconSVG />} permission={PERMISSION_ENUM.SELL_EURR}>
                      Sell
                    </MenuDropDownFeatureLink>
                  </MenuDropDownFeatureList>
                  <MenuDropDownList>
                    <MenuDropDownLink
                      icon={<DashboardIconSVG />}
                      to="/dashboard"
                      permission={PERMISSION_ENUM.VIEW_DASHBOARD}
                    >
                      Dashboard
                    </MenuDropDownLink>
                    <MenuDropDownLink
                      icon={<TransactionsIconSVG />}
                      to="/transactions"
                      permission={PERMISSION_ENUM.VIEW_TRANSACTIONS}
                    >
                      Transactions
                    </MenuDropDownLink>
                    <MenuDropDownLink
                      icon={<SettingsIconSVG />}
                      to="/accounts"
                      permission={PERMISSION_ENUM.VIEW_ACCOUNT}
                    >
                      Accounts
                    </MenuDropDownLink>
                  </MenuDropDownList>
                </>
              </MenuDropDown>
            ) : (
              <></>
            )}
          </>
        </HeaderNavigation>
      }
    >
      {children}
    </DashboardTemplate>
  );
}
