import { ReactComponent as AlternativeEmailSVG } from "assets/icons/alternate-email.svg";
import { ReactComponent as IconBackIOS } from "assets/icons/icon-back-ios.svg";
import { ReactComponent as IconForwardIOS } from "assets/icons/icon-forward-ios.svg";
import { ReactComponent as PersonSVG } from "assets/icons/person.svg";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Link from "components/atoms/Inline/Link";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Spacer from "components/atoms/Spacer/Spacer";
import Container from "components/molecules/Container/Container";
import CheckBoxInput from "components/molecules/Form/CheckBoxInput";
import PhoneNumberInput from "components/molecules/Form/PhoneNumberInput/PhoneNumberInput";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import BottomBar from "components/organisms/BottomBar/BottomBar";
import FormLayout from "components/organisms/FormLayout/FormLayout";
import Constants from "constants/index";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import { useIsMobile } from "hooks/use-is-mobile";
import { UserInformation } from "pages/SignUp";
import * as React from "react";
import { Field, Form } from "react-final-form";
import styled from "styled-components";
import spacing from "styles/spacing";
import StepTemplate from "templates/StepTemplate/StepTemplate";
import isAlpha from "validator/lib/isAlpha";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";

export interface UserInformationForm {
  email: string;
  fullName: string;
  mobile: string;
  accepted: boolean;
}

const ButtonContainerStyled = styled.div`
  display: flex;
  align-items: center;
`;

interface UserInformationProps {
  onBack: () => void;
  onContinue: (values: UserInformation) => void;
  userInformation?: UserInformation;
}

export default function UserInformationComponent({ onBack, onContinue, userInformation }: UserInformationProps) {
  const isMobile = useIsMobile();

  const handleValidation = (values: UserInformationForm) => ({
    accepted: values.accepted === true ? undefined : "Required",
    fullName:
      values?.fullName !== undefined && isAlpha(values.fullName, undefined, { ignore: " " })
        ? undefined
        : "Full name must only contain letters and spaces",
    email: values?.email !== undefined && isEmail(values.email) ? undefined : "Please enter a valid email",
    mobile: values?.mobile !== undefined && isMobilePhone(values.mobile) ? undefined : "Mobile number is not valid",
  });

  return (
    <Form onSubmit={onContinue} validate={handleValidation} initialValues={userInformation}>
      {({ handleSubmit }) => (
        <StepTemplate
          bottomBarElement={
            <BottomBar
              leftElement={
                <Button
                  varient="secondary"
                  onClick={onBack}
                  layout="icon-left"
                  mobileLayout="icon-only"
                  icon={<IconBackIOS />}
                >
                  Previous Step
                </Button>
              }
              rightElement={
                !isMobile ? (
                  <ButtonContainerStyled>
                    <Field
                      name="accepted"
                      component={CheckBoxInput}
                      label={
                        <Paragraph as="span">
                          I accept the{" "}
                          <Link
                            external
                            to={Constants.links.termsOfUse}
                            onClick={() => {
                              logEvent(EVENT_TYPE_ENUM.LINK, "TermsAndConditions-SignUp");
                            }}
                          >
                            Terms of Use
                          </Link>{" "}
                          and{" "}
                          <Link
                            external
                            to={Constants.links.privacyPolicy}
                            onClick={() => {
                              logEvent(EVENT_TYPE_ENUM.LINK, "PrivacyPolicy-SignUp");
                            }}
                          >
                            Privacy Policy
                          </Link>
                        </Paragraph>
                      }
                    />
                    <Spacer width={spacing.s} />
                    <Button
                      icon={<IconForwardIOS />}
                      varient="primary"
                      layout="icon-right"
                      onClick={handleSubmit}
                      testID="Next"
                    >
                      Next
                    </Button>
                  </ButtonContainerStyled>
                ) : (
                  <Button
                    icon={<IconForwardIOS />}
                    varient="primary"
                    layout="icon-right"
                    onClick={handleSubmit}
                    testID="Next"
                  >
                    Next
                  </Button>
                )
              }
            />
          }
        >
          <Container as="section" maxWidth={500} id="title-container">
            <header>
              <Heading as="h1" varient="h2">
                1. User Information
              </Heading>
            </header>
            <Paragraph>Please provide us your user information</Paragraph>
            <FormLayout>
              <>
                <Field
                  label="Company Email Address"
                  type="email"
                  name="email"
                  placeholder="email@stablr.com"
                  note="In the next step you will verify this email address"
                  component={TextInput}
                  icon={<AlternativeEmailSVG />}
                />
                <Field
                  label="Full Name"
                  icon={<PersonSVG />}
                  type=""
                  name="fullName"
                  placeholder="Full Name"
                  component={TextInput}
                />
                <Field label="Mobile Number" name="mobile" placeholder={"000000000"} component={PhoneNumberInput} />
                {isMobile && (
                  <Field
                    name="accepted"
                    component={CheckBoxInput}
                    label={
                      <Paragraph as="span">
                        I accept the{" "}
                        <Link external to={Constants.links.termsOfUse}>
                          Terms of Use
                        </Link>{" "}
                        and{" "}
                        <Link external to={Constants.links.privacyPolicy}>
                          Privacy Policy
                        </Link>
                      </Paragraph>
                    }
                  />
                )}
              </>
            </FormLayout>
          </Container>
        </StepTemplate>
      )}
    </Form>
  );
}
