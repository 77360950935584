import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import input from "styles/input";
import spacing from "styles/spacing";

interface FieldSetProps {
  children: React.ReactElement | React.ReactElement[];
}

const PADDING = spacing.m;
const MAX_WIDTH = input.maxWidth;

const FieldSetStyled = styled.fieldset`
  padding: ${PADDING};
  border: none;
  width: calc(100% + ${PADDING} * 2);
  max-width: min(${MAX_WIDTH}, 100%);
  background-color: ${color.primaryLight};
  margin: 0 -${PADDING};
  border-radius: 26px;
  box-sizing: content-box;
`;

export default function FieldSetComponent({ children }: FieldSetProps) {
  return <FieldSetStyled>{children}</FieldSetStyled>;
}
