import TableBody from "components/atoms/Table/TableBody";
import TableData from "components/atoms/Table/TableData/TableData";
import TableRow from "components/atoms/Table/TableRow";
import * as React from "react";
import styled from "styled-components";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";
import { AppTransaction } from "types/App/Transaction";

interface YourBankAccountTableProps {
  transaction: AppTransaction;
}

const YourBankAccountTableStyled = styled.table`
  width: 100%;
  padding: ${spacing.xs};

  & > tbody > tr:not(:last-child) > td {
    border-bottom: none;
  }

  & > tbody > tr > td {
    text-align: right;
  }

  & > tbody > tr > td,
  & > tbody > tr > th {
    padding: ${spacing.xs} ${spacing.s};
    font-weight: ${fontWeight.default};
  }
`;

export default function YourBankAccountTableComponent({ transaction }: YourBankAccountTableProps) {
  return (
    <YourBankAccountTableStyled cellSpacing="0">
      <TableBody>
        <>
          <TableRow>
            <TableData>{transaction.BankAccountAddress}</TableData>
          </TableRow>
        </>
      </TableBody>
    </YourBankAccountTableStyled>
  );
}
