import { ReactElement } from "react";
import toast from "react-hot-toast";

interface ToastProps {
  message: string;
  title?: string;
  icon?: ReactElement;
}

export function useToast() {
  const errorToast = ({ message, title = "Error", ...options }: ToastProps) => {
    const toastOptions: any = {
      ...options,
      ariaProps: {
        title,
      },
    };

    toast.error(message, toastOptions);
  };

  const successToast = ({ message, title = "Success", ...options }: ToastProps) => {
    const toastOptions: any = {
      ...options,
      ariaProps: {
        title,
      },
    };
    toast.success(message, toastOptions);
  };

  const infoToast = ({ message, title = "Info", ...options }: ToastProps) => {
    const toastOptions: any = {
      ...options,
      ariaProps: {
        title,
      },
    };
    toast(message, toastOptions);
  };

  return { errorToast, successToast, infoToast };
}
