import { Tab } from "@headlessui/react";
import React from "react";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

const TabPanelsStyled = styled(Tab.Panels)`
  @media (${media.mobile}) {
    padding: ${spacing.s} ${spacing.l};
  }
`;

TabPannels.testID = "tabPannels";

export default function TabPannels({ panels }: { panels: React.ReactElement[] }) {
  return (
    <Tab.Panels as={TabPanelsStyled} data-testid={TabPannels.testID}>
      {panels.map((panel, id) => (
        <Tab.Panel key={id}>{panel}</Tab.Panel>
      ))}
    </Tab.Panels>
  );
}
