import * as yup from "yup";

export enum SELL_ORDER_SCHEMA {
  WALLET = "wallet",
  CURRENT_BALANCE = "currentBalance",
  NEW_BALANCE = "newBalance",
  SELL = "sell",
  PAY = "pay",
  REFERENCE = "reference",
}

export const SellOrderSchemaValidation = yup.object({
  [SELL_ORDER_SCHEMA.WALLET]: yup.object().required("Wallet address is required"),
  [SELL_ORDER_SCHEMA.SELL]: yup
    .number()
    .moreThan(10)
    .lessThan(1000000, "You can not sell more than 1.000.000 EURR")
    .required("Sell amount is required")
    .max(yup.ref("currentBalance"), "You can not sell more than the available balance in your wallet."),
});
