import Synaps from "@synaps-io/react-verify";
import { ReactComponent as IconForwardIOS } from "assets/icons/icon-forward-ios.svg";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import Container from "components/molecules/Container/Container";
import BottomBar from "components/organisms/BottomBar/BottomBar";
import useUserData from "hooks/context/use-user-data";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import media from "styles/media";
import spacing from "styles/spacing";
import StepTemplate from "templates/StepTemplate/StepTemplate";

interface CompanyVerificationProps {
  onContinue: () => void;
}

const SynapsContainerStyled = styled.div`
  iframe {
    @media ${media.mobile} {
      width: 100%;
    }
  }
`;

export default function CompanyVerificationComponent({ onContinue }: CompanyVerificationProps) {
  const isMobile = useIsMobile();
  const userData = useUserData();

  return (
    <StepTemplate
      bottomBarElement={
        <BottomBar
          rightElement={
            <Button icon={<IconForwardIOS />} varient="primary" layout="icon-right" onClick={onContinue}>
              Next
            </Button>
          }
        />
      }
    >
      <>
        <Container as="section" maxWidth={822} id="title-container">
          <header>
            <Heading as="h1" varient="h2">
              1. Company Verification
            </Heading>
          </header>
          <Paragraph>Please provide us your company information with our partner Synaps</Paragraph>
        </Container>
        <Container
          as={SynapsContainerStyled}
          marginLeft={isMobile ? 0 : undefined}
          marginRight={isMobile ? 0 : undefined}
          marginBottom={spacing.m}
          id="synaps-container"
        >
          {userData["custom:sessionId"] !== undefined && (
            <Synaps
              sessionId={userData["custom:sessionId"]}
              service={"corporate"}
              lang={"en"}
              onReady={() => {
                // Ready
              }}
              onFinish={onContinue}
              color={{
                primary: color.primary,
                secondary: color.secondary,
              }}
            />
          )}
        </Container>
      </>
    </StepTemplate>
  );
}
