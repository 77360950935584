import { APICurrencyObject } from "./Shared";

export enum WALLET_TYPE_ENUM {
  Ethereum = "Ethereum",
  Qredo = "Qredo",
}

export enum WALLET_STATUS_ENUM {
  Verified = "Verified",
  Pending_Approval = "Pending Approval",
  Requesting_Approval = "Requesting Approval",
  Pending_Delete = "Pending Delete",
  Requesting_Delete = "Requesting Delete",
  Rejected_Approval = "Rejected Approval",
  Rejected_Delete = "Rejected Delete",
}

export interface APIWallet {
  WalletType: WALLET_TYPE_ENUM;
  WalletAddress: string;
  Id: string;
  Label: string;
  Status: WALLET_STATUS_ENUM;
  Balance: APICurrencyObject;
}

export type APIWalletListItem = APIWallet;
