import Heading, { HeadingElementTypes } from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";

export interface InstructionStepProps {
  testID?: string;
  stepNumber: number;
  title: string | React.ReactElement;
  titleAs?: HeadingElementTypes;
  titleVarient?: HeadingElementTypes;
  body: string;
}

const InstructionStepStyled = styled.li`
  list-style-type: none;
`;

InstructionStepComponent.testID = "instructionStep";

export default function InstructionStepComponent({
  testID,
  stepNumber,
  title,
  titleAs = "h2",
  titleVarient,
  body,
}: InstructionStepProps) {
  return (
    <InstructionStepStyled data-testid={composeTestID(InstructionStepComponent.testID, testID)}>
      <Heading as={titleAs} varient={titleVarient}>
        {stepNumber}. {title}
      </Heading>
      <Paragraph>{body}</Paragraph>
    </InstructionStepStyled>
  );
}
