import WizardHorizontalNavigationStep, {
  WIZARD_NAVIGATION_STEP_SVG_SIZE,
} from "components/molecules/Wizard/WizardHorizontalNavigationStep";
import * as React from "react";
import styled from "styled-components";
import color from "styles/color";
import spacing from "styles/spacing";

interface StepListEntry {
  id: string;
  label: string;
}

interface WizardHorizontalNavigationProps {
  steps: StepListEntry[];
  currentLocation: number;
}

const WizardHorizontalNavigationStyled = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: ${spacing.xs} 0;
`;

const LINE_HEIGHT = "2px";
const LINE_MARGIN = spacing.xs;

const WizardHorizontalNavigationLineStyled = styled.div`
  flex: 1;
  background-color: ${({ $active }: { $active: boolean }) => ($active ? color.primary : color.grey3)};
  height: 4px;
  border-radius: 2px;
  margin-top: calc(${WIZARD_NAVIGATION_STEP_SVG_SIZE} / 2 - (${LINE_HEIGHT} / 2));
  margin-left: ${LINE_MARGIN};
  margin-right: ${LINE_MARGIN};
`;

export default function WizardHorizontalNavigationComponent({
  steps,
  currentLocation,
}: WizardHorizontalNavigationProps) {
  return (
    <WizardHorizontalNavigationStyled>
      {steps.map((step, index) => {
        const stepType = currentLocation >= index ? "complete" : "incomplete";

        return (
          <React.Fragment key={step.id}>
            {index !== 0 ? <WizardHorizontalNavigationLineStyled $active={stepType === "complete"} /> : <></>}
            <WizardHorizontalNavigationStep type={stepType} title={`${index + 1}. ${step.label}`} />
          </React.Fragment>
        );
      })}
    </WizardHorizontalNavigationStyled>
  );
}
