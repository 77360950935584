import Alert from "components/atoms/Alert";
import CardLoader from "components/molecules/Card/CardLoader";
import ErrorMessageComponent from "components/molecules/Form/ErrorMessage/ErrorMessage";
import HeaderBackNav from "components/molecules/HeaderBackNav";
import BottomNavigationComponent from "components/organisms/BottomBar/BottomBar";
import { FORM_ERROR } from "final-form";
import getErrorMessage from "functions/get-error-message";
import { validateFormValues } from "functions/validate-schema";
import { useUpdateWallet } from "hooks/query/wallet/use-update-wallet";
import { useWallet } from "hooks/query/wallet/use-wallet";
import { useIsMobile } from "hooks/use-is-mobile";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import color from "styles/color";
import { AppWallet } from "types/App/Wallet";

import { WalletSchemaValidation } from "../common/schemaValidation";
import SubmitButton from "../common/SubmitButton";
import WalletFields from "../common/WalletFields";

export default function EditWallet() {
  const navigation = useNavigate();
  const isMobile = useIsMobile();
  const { walletId = "" } = useParams();

  const { mutateAsync: mutateAsyncPutWallet, isLoading: updatingWallet } = useUpdateWallet();
  const { data: wallet, isLoading } = useWallet(walletId);

  const handleOnSubmit = async (data: AppWallet) => {
    try {
      await mutateAsyncPutWallet({ walletId, wallet: data });
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  const AlertBox = (
    <Alert
      iconColor={color.yellow}
      message="Only add Wallet Addresses that support ERC-20 tokens, otherwise tokens will be lost."
    />
  );

  const isEditable = useMemo(() => wallet?.Status === "Verified", [wallet?.Status]);

  if (isLoading) return <CardLoader />;

  return (
    <>
      <HeaderBackNav title={wallet?.Label ?? ""} onBack={() => navigation("..")} />

      <Form
        onSubmit={handleOnSubmit}
        initialValues={wallet}
        validate={validateFormValues(WalletSchemaValidation)}
        keepDirtyOnReinitialize
      >
        {({ valid, submitError, handleSubmit, values, submitting }) => {
          const isDisabled = !isEditable || submitting || updatingWallet;
          return (
            <form onSubmit={handleSubmit}>
              <>{isMobile && AlertBox}</>
              <WalletFields isEditForm disabled={isDisabled} walletType={values?.WalletType} />
              {submitError && <ErrorMessageComponent>{submitError}</ErrorMessageComponent>}
              <BottomNavigationComponent
                paddingHorizontal="0"
                rightElement={
                  !isMobile ? <SubmitButton buttonText="Save Wallet" disabled={!valid || updatingWallet} /> : undefined
                }
                leftElement={
                  isMobile ? <SubmitButton buttonText="Save Wallet" disabled={!valid || updatingWallet} /> : AlertBox
                }
              />
            </form>
          );
        }}
      </Form>
    </>
  );
}
