import Button from "components/atoms/Button/Button";
import CardError from "components/molecules/Card/CardError";
import CardLoader from "components/molecules/Card/CardLoader";
import formatCurrency from "functions/format-currency";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import useDashboard from "hooks/query/dashboard/use-dashboard";
import { useIsMobile } from "hooks/use-is-mobile";
import DashboardContainer from "pages/components/DashboardContainer";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import Constants from "../../constants";
import BalanceCard from "./components/BalanceCard";
import NotAvailableBuySell from "./components/NotAvailableBuySell";
import RecentActivityCard from "./components/RecentActivityCard";

export default function DashboardComponent() {
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const { data: dashboardData, isLoading, error, refetch } = useDashboard();

  const handleOnBuyClick = () => {
    logEvent(EVENT_TYPE_ENUM.CLICK, "BuyCTA", { location: "Dashboard" });
    navigate("/buy");
  };

  const handleOnTransactionsClick = () => {
    logEvent(EVENT_TYPE_ENUM.CLICK, "TransactionsCTA", { location: "Dashboard" });
    navigate("/transactions");
  };

  return (
    <DashboardContainer>
      {isLoading ? (
        <CardLoader />
      ) : error ? (
        <CardError title="Error" body={"Error Loading Dashboard"} onRetry={refetch} />
      ) : dashboardData ? (
        <>
          {Constants.disableBuySell && <NotAvailableBuySell />}
          <BalanceCard
            currentBalance={formatCurrency({
              Amount:
                (dashboardData.Wallets.length > 0
                  ? dashboardData.Wallets.map(item => item.Balance.Amount)
                  : [0]
                ).reduce((a, b) => a + b) ?? 0,
              Denominator: dashboardData.Wallets?.[0]?.Balance?.Denominator ?? Constants.fallbackDenominator,
            })}
            onBuyClick={handleOnBuyClick}
          />
          <RecentActivityCard
            title="Recent Activity"
            buttonElement={
              isMobile ? undefined : (
                <Button varient="secondary" onClick={handleOnTransactionsClick}>
                  View All
                </Button>
              )
            }
            data={dashboardData.Transactions}
          />
        </>
      ) : (
        <></>
      )}
    </DashboardContainer>
  );
}
