import * as amplitude from "@amplitude/analytics-browser";
import { Auth } from "aws-amplify";
import Loader from "components/atoms/Loader/Loader";
import Constants from "constants/index";
import anonymiseId from "functions/anonymise-id";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import React from "react";
import { BrowserRouter, matchPath, matchRoutes, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Accounts from "./Account";
import BuySell from "./BuySell";
import Buy from "./BuySell/Buy";
import Sell from "./BuySell/Sell";
import Dashboard from "./Dashboard";
import DashboardMenu from "./DashboardMenu";
import Home from "./Home";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Transactions from "./Transaction";
import UserDataContextProvider from "./UserDataContextProvider";

const AUTHENTICATED_PATHS = [
  { path: "/dashboard/*" },
  { path: "/transactions/*" },
  { path: "/accounts/*" },
  { path: "/buy/*" },
  { path: "/sell/*" },
];

const LOGGABLE_ROUTES = [
  { path: "/dashboard" },
  { path: "/buy", exact: true },
  { path: "/buy/:id" },
  { path: "/sell", exact: true },
  { path: "/sell/:id" },
  { path: "/transactions", exact: true },
  { path: "/accounts", exact: true },
  { path: "/accounts/company-information", exact: true },
  { path: "/accounts/wallet", exact: true },
  { path: "/accounts/wallet/add", exact: true },
  { path: "/accounts/wallet/:id/edit", exact: true },
  { path: "/accounts/bank-accounts", exact: true },
  { path: "/accounts/bank-accounts/add", exact: true },
  { path: "/accounts/bank-accounts/:id/edit", exact: true },
  { path: "/accounts/payment-instructions", exact: true },
  { path: "/accounts/user-management", exact: true },
  { path: "/accounts/user-management/add", exact: true },
  { path: "/accounts/user-management/:id", exact: true },
  { path: "/sign-in", exact: true },
  { path: "/sign-in/sms-authentication", exact: true },
  { path: "/sign-in/reset-password", exact: true },
  { path: "/sign-in/new-password", exact: true },
  { path: "/sign-up/user-information", exact: true },
  { path: "/sign-up/create-password", exact: true },
  { path: "/sign-up/email-verification", exact: true },
  { path: "/sign-up/sms-authentication", exact: true },
  { path: "/sign-up/company-verification", exact: true },
  { path: "/sign-up/summary-and-sign-up", exact: true },
  { path: "/sign-up/success", exact: true },
];

const UNAUTHENTICATED_PATHS = [{ path: "/sign-up/*" }, { path: "/" }];

export default function PageRoutes() {
  return (
    <BrowserRouter>
      <UserDataContextProvider>
        <ActiveSessionWrapper>
          {isAuthenticatedPath =>
            isAuthenticatedPath ? (
              <DashboardMenu>
                <RoutesComponent />
              </DashboardMenu>
            ) : (
              <RoutesComponent />
            )
          }
        </ActiveSessionWrapper>
      </UserDataContextProvider>
    </BrowserRouter>
  );
}

function RoutesComponent() {
  const location = useLocation();
  const matchedRoutes = matchRoutes(LOGGABLE_ROUTES, location);

  React.useEffect(() => {
    if (matchedRoutes) logEvent(EVENT_TYPE_ENUM.NAVIGATE_SCREEN, matchedRoutes[0].route.path);
  }, [matchedRoutes ? matchedRoutes[0] : undefined]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sign-up/*" element={<SignUp />} />
      <Route path="/sign-in/*" element={<SignIn />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/accounts/*" element={<Accounts />} />
      <Route path="/transactions/*" element={<Transactions />} />
      <Route
        path={"/buy/*"}
        element={
          <BuySell>
            <Buy />
          </BuySell>
        }
      ></Route>
      <Route
        path={"/sell/*"}
        element={
          <BuySell>
            <Sell />
          </BuySell>
        }
      />
    </Routes>
  );
}
function ActiveSessionWrapper({ children }: { children: (isAuthenticatedPath: boolean) => React.ReactElement }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isAuthenticatedPath = React.useMemo(() => matchRoutes(AUTHENTICATED_PATHS, pathname) !== null, [pathname]);
  const isKnownPath = React.useMemo(
    () => matchRoutes([...AUTHENTICATED_PATHS, ...UNAUTHENTICATED_PATHS], pathname) !== null,
    [pathname]
  );

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const getCurrentSession = async () => {
      try {
        const { attributes: payload } = await Auth.currentUserInfo(); // Fetch without Cache

        const userSub = payload?.sub;
        const isPhoneVerified = payload?.phone_number_verified;
        const isKYBSubmitted =
          typeof payload?.["custom:kybStatus"] === "string" && payload?.["custom:kybStatus"] === "SUBMITTED";
        const isKYBVerified =
          typeof payload?.["custom:kybStatus"] === "string" && payload?.["custom:kybStatus"] === "VALIDATED";

        if (isPhoneVerified === false && !matchPath(pathname, "/sign-up/sms-authentication")) {
          navigate("../sign-up/sms-authentication");
        } else if (
          isKYBVerified === false &&
          isKYBSubmitted === false &&
          !matchPath(pathname, "/sign-up/company-verification")
        ) {
          navigate("../sign-up/company-verification");
        } else if (isKYBSubmitted === true && !matchPath(pathname, "/sign-up/summary-and-sign-up")) {
          navigate("../sign-up/summary-and-sign-up");
        } else if (!isAuthenticatedPath && isKnownPath && isPhoneVerified === true && isKYBVerified == true) {
          navigate("../dashboard");
        }

        /** Add user association to logging */
        amplitude.init(Constants.amplitude.apiKey, anonymiseId(userSub));

        setIsLoading(false);
      } catch (error: unknown) {
        /** Remove user association from logging */
        amplitude.init(Constants.amplitude.apiKey);

        if (!isAuthenticatedPath) {
          return;
        }
        // If there is no active user session
        if (error === "No current user") {
          navigate("../sign-in");
        } // If there is a different error with the session
        else {
          navigate("../sign-in");
        }
      }
    };
    getCurrentSession();
  }, [isAuthenticatedPath]);

  return isAuthenticatedPath && isLoading ? <Loader /> : children(isAuthenticatedPath);
}
