import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";

const ButtonContainer = styled.div<{ justifyContent?: "flex-start" | "center" | "flex-end" }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${media.mobile} {
    margin: ${spacing.m} 0;
    flex-direction: row;

    & button:first-child {
      margin-bottom: 0;
    }
  }

  @media ${media.desktop} {
    justify-content: ${({ justifyContent }) => justifyContent ?? "flex-start"};
    flex-direction: row;
    margin: ${spacing.m} 0;

    & button:first-child {
      margin-right: ${spacing.xs};
      margin-bottom: 0;
    }
  }

  @media ${media.smallMobile} {
    flex-direction: column;

    & button {
      min-width: 170px;

      &:first-child {
        margin: ${spacing.s} 0;
      }
    }
  }
`;

export default ButtonContainer;
