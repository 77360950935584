import Input from "components/atoms/Form/Input/Input";
import InputNote from "components/atoms/Form/InputNote/InputNote";
import Label from "components/atoms/Form/Label/Label";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import color from "styles/color";
import input from "styles/input";

interface TextInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testID?: string;
  icon?: React.ReactElement;
  note?: string;
  compact?: boolean;
}

const TextInputStyled = styled.div`
  width: 100%;
  max-width: ${input.maxWidth};
`;

TextInputComponent.testID = "TextInput";

export default function TextInputComponent({
  input: { type = "text", ...inputProps },
  label,
  note,
  compact = false,
  meta: { error, touched },
  ...props
}: TextInputProps) {
  const isError = error && touched;
  return (
    <TextInputStyled data-testid={composeTestID(TextInputComponent.testID, props.testID)}>
      <Label htmlFor={inputProps.name}>{label}</Label>
      <Input {...inputProps} {...props} type={type}></Input>
      {(!compact || note?.trim() || isError) && (
        <InputNote htmlFor={inputProps.name} color={isError ? color.red : undefined}>
          {isError ? error : note}
        </InputNote>
      )}
    </TextInputStyled>
  );
}
