import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import Button from "components/atoms/Button/Button";
import CardLoaderComponent from "components/atoms/Card/CardLoader";
import ButtonContainer from "components/molecules/Container/ButtonContainer";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import HeaderBackNav from "components/molecules/HeaderBackNav";
import { PERMISSION_ENUM } from "constants/permissions";
import { FORM_ERROR } from "final-form";
import getErrorMessage from "functions/get-error-message";
import { validateFormValues } from "functions/validate-schema";
import withPermission from "hocs/permission";
import { useUpdateUser } from "hooks/query/user/use-update-user";
import { useUser } from "hooks/query/user/use-user";
import React from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import color from "styles/color";
import { AppUpdateUser } from "types/App/User";

import DeleteUserButton from "../common/DeleteUserButton";
import { USER_SCHEMA, UserSchemaValidation } from "../common/schemaValidation";
import UserEditForm from "../common/UserEditForm";

function EditUser() {
  const navigation = useNavigate();
  const { userId = "" } = useParams();

  const { data: user, isLoading, isError } = useUser(userId);
  const { mutateAsync: mutateAsyncUpdateUser, isLoading: updatingUser } = useUpdateUser();

  const handleOnUserUpdate = async (user: AppUpdateUser) => {
    if (updatingUser) return;
    try {
      await mutateAsyncUpdateUser({ userId, user });
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  const handleOnBack = () => navigation("..");

  if (isLoading) return <CardLoaderComponent />;

  return (
    <>
      <HeaderBackNav title={user?.Name || ""} onBack={handleOnBack} />
      <Form
        onSubmit={handleOnUserUpdate}
        validate={validateFormValues(UserSchemaValidation)}
        initialValues={{ ...user, [USER_SCHEMA.ROLE]: user?.Roles ?? [] }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, submitError }) => {
          const isDisabled = submitting || updatingUser;
          return (
            <form onSubmit={handleSubmit}>
              <UserEditForm disabled={isDisabled || isError} />
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

              <ButtonContainer justifyContent="flex-end">
                {!user?.Disabled && <DeleteUserButton id={userId} name={user?.Name} />}

                <Button
                  type="submit"
                  varient="primary"
                  color={color.white}
                  icon={<SaveIcon />}
                  iconSize={20}
                  loading={isDisabled}
                >
                  Save Changes
                </Button>
              </ButtonContainer>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default withPermission(PERMISSION_ENUM.UPDATE_USERS)(EditUser);
