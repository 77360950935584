import InstructionStep, { InstructionStepProps } from "components/molecules/InstructionStep/InstructionStep";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import styled from "styled-components";
import spacing from "styles/spacing";

interface InstructionStepsProps {
  testID?: string;
  steps: Omit<InstructionStepProps, "stepNumber">[];
}

const InstructionStepsStyled = styled.ol`
  list-style-type: none;
  padding-left: 0;

  & > li {
    margin: ${spacing.xl} 0;
  }
`;

InstructionStepsComponent.testID = "instructionSteps";

export default function InstructionStepsComponent({ testID, steps }: InstructionStepsProps) {
  return (
    <InstructionStepsStyled type="1" data-testid={composeTestID(InstructionStepsComponent.testID, testID)}>
      {steps.map((step, index) => (
        <InstructionStep key={`${index + 1}-${step.title}`} stepNumber={index + 1} {...step} />
      ))}
    </InstructionStepsStyled>
  );
}
