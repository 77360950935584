import { useQuery } from "@tanstack/react-query";
import api from "api";
import { APIBankAccount } from "types/API/BankAccount";

useGetAccount.queryKey = "bankAccount";
export function useGetAccount(accountId: string) {
  return useQuery(
    [useGetAccount.queryKey, accountId],
    async () => api.BankAccount.getAccountById(accountId) as Promise<APIBankAccount>
  );
}
