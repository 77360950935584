import * as yup from "yup";

export enum USER_SCHEMA {
  NAME = "Name",
  COMPANY_EMAIL = "Email",
  PHONE = "Phone",
  ROLE = "UserGroups",
}

export const UserSchemaValidation = yup.object({
  [USER_SCHEMA.NAME]: yup.string().required("Name is required"),
  [USER_SCHEMA.COMPANY_EMAIL]: yup.string().email().required("Email is required"),
  [USER_SCHEMA.PHONE]: yup.string().required("Mobile Number is required"),
  [USER_SCHEMA.ROLE]: yup.array().of(yup.string()).required("Role is required").min(1, "At least one role is required"),
});
